import React from 'react';
import { func, string, bool } from 'prop-types';
import Menu, { Item as MenuItem, Divider } from 'rc-menu';
import Dropdown from 'rc-dropdown';

const NavbarMenu = ({ onSelect, name, isOpen, onVisibleChange }) => {
  const menu = (
    <Menu onSelect={onSelect}>
      <MenuItem key="logout">
        <p className="dropdown-text">Sign out</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Dropdown
      trigger={['click']}
      overlay={menu}
      animation="slide-up"
      onVisibleChange={onVisibleChange}
    >
      <div className="row dropdown-container" >
        <p className="name">{name}</p>
        <div className={`triangle ${isOpen ? 'up' : 'down'}`} />
      </div>
    </Dropdown>
  );
};

NavbarMenu.propTypes = {
  onSelect: func.isRequired,
  onVisibleChange: func.isRequired,
  name: string,
  isOpen: bool.isRequired
};

export default NavbarMenu;
