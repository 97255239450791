import React, { Component } from 'react';
import { string, object, func, bool, array } from 'prop-types';
import { connect } from 'react-redux';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import { routeWithProps } from 'utils/helpers';
import humps from 'humps';
import withHeader from 'components/common/WithHeader';
import ConfirmModal from 'components/common/ConfirmModal';
import Modal from 'components/common/Modal';
import routes from 'constants/routesPaths';
import VideosTab from 'components/dashboard/VideosTab';
import Button from 'components/common/Button';
import ShareModal from 'components/common/ShareModal';
import InfoModal from 'components/common/InfoModal';
import videoIcon from 'assets/icons/videoIcon.svg';
import filmIcon from 'assets/icons/filmIcon.svg';
import {
  fetchVideos,
  selectOrUnselectVideo,
  unselectVideos,
  deleteVideos,
  removeVideoError,
  toggleShareModal,
  fetchVideoActionSuccess,
  checkUploadingVideos,
  shareVideos
} from 'actions/videoActions';
import {
  CONFIRM_DELETE_TEXT,
  SOMETHING_WENT_WRONG,
  VIDEO_STATES,
  SUCCESS_SHARED_VIDEO,
  DESCRIPTION_SUCCESS_SHARED_VIDEO
} from 'constants/constants';

class DashboardPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDeleteModal: false,
      showSuccessShareVideoModal: false
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.deleteVideos = this.deleteVideos.bind(this);
  }

  componentDidMount() {
    const { fetchVideos } = this.props;
    fetchVideos().then(() => {
      this.intervalId = setInterval(this.refreshStates, 10000);
    });
  }

  componentWillUnmount() {
    this.props.unselectVideos();
    clearInterval(this.intervalId);
  }

  onShareVideos = (videos = null) => {
    const { toggleShareModal, shareVideos } = this.props;
    if (videos) {
      return shareVideos(videos).then(success => success && this.toggleSuccessModal(true));
    }
    return toggleShareModal();
  };

  refreshStates = () => {
    const { checkUploadingVideos } = this.props;
    let uploadingVideos = [];
    uploadingVideos = this.filterUploadingVideos();
    (!uploadingVideos) ? clearInterval(this.intervalId) : checkUploadingVideos(uploadingVideos);
  }

  filterUploadingVideos = () => {
    const { videos } = this.props;
    const unsavedVideos = videos.map(({ id, state }) => {
      if (state === VIDEO_STATES.uploading) {
        return id;
      }
    });
    return unsavedVideos.filter(video => video !== undefined).join();
  }

  toggleModal(open) {
    this.setState({ showDeleteModal: open });
  }

  toggleSuccessModal = (open) => {
    this.setState({ showSuccessShareVideoModal: open });
  }

  deleteVideos() {
    const { selectedVideos, deleteVideos } = this.props;
    const deleteVideoIds = selectedVideos.map(video => video.id);
    deleteVideos({ postIds: deleteVideoIds });
    this.setState({ showDeleteModal: false });
  }

  playVideo = (video) => {
    const { history, fetchVideoActionSuccess } = this.props;
    fetchVideoActionSuccess(humps.camelizeKeys(video));
    history.push(routeWithProps(routes.playVideo, { postId: video.id }));
  }

  render() {
    const {
      name,
      loading,
      history,
      videos,
      selectOrUnselectVideo,
      unselectVideos,
      selectedVideos,
      removeVideoError,
      error,
      shareModal,
      toggleShareModal
    } = this.props;
    const { showDeleteModal, showSuccessShareVideoModal } = this.state;

    return (
      <div className="dashboard-page-box container">
        <div className="row dashboard-header">
          <div className="col-md-6 user-name-box">
            <h1 className="user-name">{name}</h1>
          </div>
          <div className="col-md-6 create-button-box">
            <Button
              className="btn-create-video float-right"
              iconClassName="video-icon"
              text="Create Video"
              icon={videoIcon}
              onPress={() => history.push({ pathname: routes.questionMenu, state: { setCategoryByDefault: true } })}
            />
          </div>
        </div>

        <Tabs>
          <TabList>
            <Tab>
              <img className="film-icon" src={filmIcon} alt="film" />
              Videos
            </Tab>
          </TabList>

          <TabPanel>
            <VideosTab
              loading={loading}
              videos={videos}
              selectedVideos={selectedVideos}
              onClickCheckbox={selectOrUnselectVideo}
              onPlayVideo={this.playVideo}
              onCancel={unselectVideos}
              onDelete={() => this.toggleModal(true)}
              toggleShareModal={toggleShareModal}
            />
          </TabPanel>
        </Tabs>
        {/* <ConfirmModal
          isOpen={showDeleteModal}
          title={CONFIRM_DELETE_TEXT}
          onConfirm={this.deleteVideos}
          onReject={() => this.toggleModal(false)}
        />
        {(error || showSuccessShareVideoModal) &&
          <Modal
            isError={!!error}
            title={error ? SOMETHING_WENT_WRONG : SUCCESS_SHARED_VIDEO}
            description={error || DESCRIPTION_SUCCESS_SHARED_VIDEO}
            onDismiss={() => { error ? removeVideoError() : this.toggleSuccessModal(false); }}
          />
        }
        <InfoModal/> */}
        {shareModal &&
          <ShareModal
            loading={loading}
            shareModal={shareModal}
            handleShare={this.onShareVideos}
            toggleShareModal={toggleShareModal}
            shareableVideos={selectedVideos}
          />
        }
      </div>
    );
  }
}

DashboardPage.propTypes = {
  history: object.isRequired,
  fetchVideos: func.isRequired,
  fetchVideoActionSuccess: func.isRequired,
  selectOrUnselectVideo: func.isRequired,
  unselectVideos: func.isRequired,
  deleteVideos: func.isRequired,
  removeVideoError: func.isRequired,
  checkUploadingVideos: func,
  name: string,
  loading: bool,
  videos: array,
  selectedVideos: array,
  error: string,
  shareModal: bool,
  toggleShareModal: func.isRequired,
  shareVideos: func.isRequired
};

const mapState = state => ({
  name: state.getIn(['session', 'user', 'firstName']),
  loading: state.getIn(['video', 'loading']),
  videos: state.getIn(['video', 'videosList']).toJS(),
  selectedVideos: state.getIn(['video', 'selectedVideos']).toJS(),
  error: state.getIn(['video', 'error']),
  shareModal: state.getIn(['video', 'shareModal'])
});

const mapDispatch = ({
  fetchVideos,
  selectOrUnselectVideo,
  deleteVideos,
  removeVideoError,
  toggleShareModal,
  checkUploadingVideos,
  unselectVideos,
  fetchVideoActionSuccess,
  shareVideos
});

export default connect(mapState, mapDispatch)(withHeader(DashboardPage));
