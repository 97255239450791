import React, { PureComponent } from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import ForgotPasswordForm from 'components/user/ForgotPasswordForm';
import { sendResetLink } from 'actions/userActions';
import withHeader from 'components/common/WithHeader';
import Modal from 'components/common/Modal';
import routes from 'constants/routesPaths';

class ForgotPasswordPage extends PureComponent {
  state = {
    openConfirmModal: false
  };

  handleBackToLogin = () => {
    const { history } = this.props;

    history.push(routes.login);
  };

  handleSubmit = (data) => {
    this.props.sendResetLink(data);
    this.setState({ openConfirmModal: true });
  };

  handleCloseConfirmModal = () => {
    const { history } = this.props;
    history.push(routes.login);
  };

  render() {
    const { openConfirmModal } = this.state;

    return (
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8">
            <ForgotPasswordForm onSubmit={this.handleSubmit} onClose={this.handleBackToLogin} />
            {openConfirmModal &&
              <Modal
                title="Reset password link has been sent. Please check your email inbox."
                onDismiss={this.handleCloseConfirmModal}
              />
            }
          </div>
        </div>
      </div>
    );
  }
}

ForgotPasswordPage.propTypes = {
  sendResetLink: func.isRequired,
};

const mapDispatch = dispatch => ({
  sendResetLink: email => dispatch(sendResetLink(email.toJS()))
});

export default connect(null, mapDispatch)(withHeader(ForgotPasswordPage));
