import React, { Component } from 'react';
import { connect } from 'react-redux';
import { array, func, number, bool, string } from 'prop-types';
import classNames from 'classnames';
import ReactGA from 'react-ga';
import history from 'utils/history';
import { isSafari, isMobileDevice } from 'utils/helpers';
import routes from 'constants/routesPaths';
import Loading from 'components/common/Loading';
import QuestionShareModal from 'components/common/QuestionShareModal';
import tickImg from 'assets/icons/added.svg';
import clockImg from 'assets/icons/clock.svg';
import { fetchCategory } from 'actions/categoriesActions';
import { toggleUseChromeModal } from 'actions/videoActions';
import { pickOneQuestion, getUserQuestions, addUserQuestion, updateUserQuestion, removeQuestionsError } from 'actions/questionsActions';
import { USER_QUESTION_STATES, REPLACE_QUESTION_TEXT, SOMETHING_WENT_WRONG } from 'constants/constants';
import Button from '../common/Button';
import ConfirmModal from '../common/ConfirmModal';
import Modal from '../common/Modal';
import QuestionsPagination from './QuestionsPagination';

class QuestionsToAnswer extends Component {
  state = {
    selectedQuestion: null,
    selectedQuestionName: '',
    openModal: false,
    currentPage: 1,
    openShareModal: false,
  };

  componentDidMount() {
    const { category, fetchCategory } = this.props;
    fetchCategory(category);
  }

  componentWillReceiveProps(newProps) {
    const { category, fetchCategory, authenticated, getUserQuestions } = this.props;
    const { category: newCategory } = newProps;
    if (category && newCategory !== category) {
      fetchCategory(newCategory);
      this.setState({ currentPage: 1 });
      authenticated && getUserQuestions();
    }
  }

  getUserQuestion = (id) => {
    const { userQuestionsList } = this.props;
    return userQuestionsList.find(({ questionId }) => questionId === id);
  }

  getMoreQuestions = (page) => {
    const { fetchCategory, category } = this.props;
    this.setState({ currentPage: page });
    fetchCategory(category, page);
  }

  addUserQuestion = (id, state) => {
    const { addUserQuestion, authenticated, updateUserQuestion } = this.props;

    if (!authenticated) {
      history.push(routes.login);
    }

    let userQuestion = this.getUserQuestion(id);

    if (userQuestion) {
      if (userQuestion.state !== state) {
        userQuestion = { ...userQuestion, state };
        updateUserQuestion(userQuestion);
      } else {
        addUserQuestion(userQuestion, true);
      }
    } else {
      userQuestion = { questionId: id, state, postId: null };
      if (state === USER_QUESTION_STATES.selected) {
        addUserQuestion(userQuestion, false);
      } else {
        addUserQuestion(userQuestion, false);
      }
    }
  }

  selectQuestion = (id, state) => {
    const { pickOneQuestion, authenticated, toggleUseChromeModal } = this.props;

    if (!authenticated) {
      return history.push(routes.login);
    }

    if (isSafari() && !isMobileDevice()) {
      toggleUseChromeModal();
      return;
    }

    if (state === USER_QUESTION_STATES.selected) {
      pickOneQuestion(id, 'origin');
      history.push(routes.answerQuestions);
    } else {
      pickOneQuestion(id, 'origin');
      history.push(routes.answerQuestions);
    }
  }

  replaceQuestion = () => {
    const { pickOneQuestion } = this.props;
    const { openModal, selectedId } = this.state;
    openModal && this.setState({ openModal: false });
    pickOneQuestion(selectedId, 'origin');
    history.push(routes.answerQuestions);
  }

  closeModal = () => {
    this.setState({ openModal: false });
  }

  isPendingOrAdded = (id, questionState) => {
    const { userQuestionsList } = this.props;
    return userQuestionsList.some(({ questionId, state }) => questionId === id && state === questionState);
  }

  maxQuestionsAdded = (state) => {
    const { userQuestionsList } = this.props;
    return userQuestionsList.filter(q => q.state === state).length >= 10;
  }

  handleToggle = (id, name) => {
    const { selectedQuestion } = this.state;
    this.setState({
      selectedQuestion: (selectedQuestion === id) ? null : id,
      selectedQuestionName: name,
    });
  }

  isSelected = (id) => {
    const { selectedQuestion } = this.state;
    return selectedQuestion && selectedQuestion === id;
  }

  handleSelectQuestion = () => {
    const { authenticated } = this.props;

    if (!authenticated) {
      return history.push(routes.login);
    }

    this.setState({ openShareModal: true });
  }

  handleSendQuestion = (e) => {
    const { selectedQuestionName } = this.props;

    e.preventDefault();

    ReactGA.event({
      category: 'Question',
      action: 'Sent a Question',
      value: selectedQuestionName
    });

    this.setState({ openShareModal: false });
  }

  toggleShareModal = status => () => {
    this.setState({ openShareModal: status });
  }

  render() {
    const { questions, loading, error, removeQuestionsError, totalPages } = this.props;
    const { openModal, currentPage, openShareModal, selectedQuestion, selectedQuestionName } = this.state;
    const maxSelected = this.maxQuestionsAdded(USER_QUESTION_STATES.selected);
    const maxPending = this.maxQuestionsAdded(USER_QUESTION_STATES.pending);
    if (loading) return <Loading className="mx-auto my-4" />;
    return (
      <div className="questions-answer-container">
        {questions.map(({ id, name }, index) => {
          const isQuestionSelect = this.isSelected(id);
          const isQuestionAdded = this.isPendingOrAdded(id, USER_QUESTION_STATES.selected);
          const isPending = this.isPendingOrAdded(id, USER_QUESTION_STATES.pending);
          return (
            <div className={classNames({ selected: isQuestionSelect })} key={index}>
              <div className="questions" onClick={() => this.handleToggle(id, name)}>
                <div className="question-wrapper">
                  <a className={classNames({ added: isQuestionAdded, pending: isPending })}>
                    {name}
                  </a>
                </div>
                <div className="icon-wrapper">
                  {isQuestionAdded && !isPending && <img src={tickImg} alt="status" />}
                  {!isQuestionAdded && isPending && <img className="pending-img" src={clockImg} alt="pending" />}
                </div>
              </div>
              {isQuestionSelect &&
              <div className="buttons-wrapper">
                <Button
                  disabled={!isQuestionAdded && maxSelected}
                  onPress={() => this.selectQuestion(id, USER_QUESTION_STATES.selected)}
                  text={isQuestionAdded ? 'Undo' : 'Record Now'}
                  className={`btn-modal btn-no ${classNames({ added: isQuestionAdded })}`}
                />
                <Button
                  text={isPending ? 'Undo' : 'Send to Someone'}
                  className="btn-modal btn-no"
                  onPress={this.handleSelectQuestion}
                />
              </div>}
            </div>
          );
        })}
        <ConfirmModal
          isOpen={openModal}
          title={REPLACE_QUESTION_TEXT}
          onConfirm={this.replaceQuestion}
          onReject={this.closeModal}
        />
        {error &&
          <Modal
            isError
            title={SOMETHING_WENT_WRONG}
            description={error}
            onDismiss={removeQuestionsError}
          />
        }
        {selectedQuestion &&
          <QuestionShareModal
            isOpen={openShareModal}
            question={selectedQuestionName}
            onConfirm={this.handleSendQuestion}
            onClose={this.toggleShareModal(false)}
          />
        }
        <QuestionsPagination total={totalPages} getQuestions={this.getMoreQuestions} current={currentPage} />
      </div>
    );
  }
}

QuestionsToAnswer.propTypes = {
  error: string,
  questions: array,
  fetchCategory: func,
  category: number,
  loading: bool,
  authenticated: bool,
  getUserQuestions: func,
  userQuestionsList: array,
  addUserQuestion: func,
  updateUserQuestion: func,
  removeQuestionsError: func.isRequired,
  totalPages: number
};

const mapState = state => ({
  error: state.getIn(['questions', 'error']),
  questions: state.getIn(['categories', 'categoryQuestionsList']).toJS(),
  loading: state.getIn(['questions', 'loading']),
  userQuestionsList: state.getIn(['questions', 'userQuestionsList']).toJS(),
  authenticated: state.getIn(['session', 'authenticated']),
  totalPages: state.getIn(['categories', 'totalPages'])
});

const mapDispatch = dispatch => ({
  fetchCategory: (categoryId, page) => dispatch(fetchCategory(categoryId, page)),
  addUserQuestion: (question, added) => dispatch(addUserQuestion(question, added)),
  getUserQuestions: () => dispatch(getUserQuestions()),
  updateUserQuestion: question => dispatch(updateUserQuestion(question)),
  removeQuestionsError: () => dispatch(removeQuestionsError()),
  pickOneQuestion: (question, type) => dispatch(pickOneQuestion(question, type)),
  toggleUseChromeModal: () => dispatch(toggleUseChromeModal())
});

export default connect(mapState, mapDispatch)(QuestionsToAnswer);
