import { List, Map, fromJS } from 'immutable';

import * as types from 'actions/actionTypes';
import { FIRST_LEVEL_CATEGORY, SECOND_LEVEL_CATEGORY } from 'constants/constants';

export const initialState = Map({
  loading: false,
  error: null,
  categoriesList: List([]),
  selectedFirstLevelCategory: Map({}),
  selectedSecondLevelCategory: Map({}),
  selectedThirdLevelCategory: Map({}),
  categoryQuestionsList: List([]),
  totalPages: 0
});

const categoriesReducer = (state = initialState, action) => {
  const { type, level } = action;
  switch (type) {
    case types.FETCH_CATEGORIES: {
      return state.set('loading', true);
    }
    case types.FETCH_CATEGORY_ERROR:
    case types.FETCH_CATEGORIES_ERROR: {
      const { error } = action;
      const newState = state.set('error', error);
      return newState.set('loading', false);
    }
    case types.FETCH_CATEGORIES_SUCCESS: {
      const { categories } = action;
      const newState = state.set('loading', false);
      return newState.set('categoriesList', fromJS(categories));
    }
    case types[`SELECT_${level}_CATEGORY`]: {
      const { categoryId, categoryName, hasSubcategories } = action;
      const capitalizeLevel = level.charAt(0) + level.slice(1).toLowerCase();
      const alreadySelected = state.getIn([`selected${capitalizeLevel}LevelCategory`, 'categoryId']) === categoryId;
      const categoryData = alreadySelected ? Map({}) : fromJS({ categoryId, categoryName, hasSubcategories });
      switch (level) {
        case FIRST_LEVEL_CATEGORY: {
          state = state.set('selectedFirstLevelCategory', fromJS({ categoryId, categoryName, hasSubcategories }));
          state = state.set('selectedSecondLevelCategory', Map({}));
          return state.set('selectedThirdLevelCategory', Map({}));
        }
        case SECOND_LEVEL_CATEGORY: {
          state = state.set('selectedSecondLevelCategory', categoryData);
          return state.set('selectedThirdLevelCategory', Map({}));
        }
        default: {
          return state.set('selectedThirdLevelCategory', categoryData);
        }
      }
    }
    case types.FETCH_CATEGORY_SUCCESS: {
      const { category: { questions, totalPages } } = action;
      const newState = state.set('totalPages', totalPages);
      return newState.set('categoryQuestionsList', fromJS(questions));
    }
    case types.REMOVE_SELECTED_CATEGORIES: {
      const newState = state.set('categoryQuestionList', List([]));
      return newState.merge({
        selectedFirstLevelCategory: Map({}),
        selectedSecondLevelCategory: Map({}),
        selectedThirdLevelCategory: Map({})
      });
    }
    case types.LOGOUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default categoriesReducer;
