import React from 'react';
import { string, func, bool } from 'prop-types';

const Button = ({
  text = '',
  icon,
  onPress,
  onlyIcon = false,
  className = '',
  iconClassName = '',
  type = 'button',
  disabled = false,
  iconFirst = true,
  iconStyle = {}
}) => {
  let btnClassName = `button ${className}`;

  if (onlyIcon) {
    btnClassName = `icon ${className}`;
  } else if (disabled) {
    btnClassName = `${btnClassName} btn-disabled`;
  }

  return (
    <button
      className={btnClassName}
      onClick={onPress}
      type={type}
      disabled={disabled}
    >
      {iconFirst && icon &&
        <img className={iconClassName} src={icon} style={iconStyle} alt="button" />
      }
      {!onlyIcon && text}
      {!iconFirst && icon &&
        <img className={iconClassName} src={icon} alt="button" />
      }
    </button>
  );
};

Button.propTypes = {
  text: string,
  icon: string,
  onlyIcon: bool,
  className: string,
  iconClassName: string,
  onPress: func,
  type: string,
  disabled: bool,
  iconFirst: bool
};

export default Button;
