import React, { Fragment } from 'react';
import { array, bool, func } from 'prop-types';
import Button from 'components/common/Button';
import Loading from 'components/common/Loading';
import { NO_VIDEO_TEXT } from 'constants/constants';
import trashIconDisabled from 'assets/icons/trashIconGrey.svg';
import trashIcon from 'assets/icons/trash-icon.svg';
import shareIconDisabled from 'assets/icons/shareIconGrey.svg';
import shareIcon from 'assets/icons/share-icon.svg';
import VideoCard from './VideoCard';

const VideosTab = ({
  videos,
  loading,
  onClickCheckbox,
  onPlayVideo,
  onDownloadVideo,
  onCancel,
  selectedVideos,
  onDelete,
  toggleShareModal
}) => {
  const hasSelectedVideos = !!selectedVideos.length;
  const handleSelectVideo = (video) => {
    onCancel();
    onClickCheckbox(video);
    toggleShareModal();
  };

  return (
    <Fragment>
      <div className="row justify-content-end videos-tab-btn">
        {hasSelectedVideos &&
          <Button
            text="Cancel"
            className="dashboard btn-cancel"
            onPress={onCancel}
          />
        }
        <Button
          text="Share"
          className="dashboard btn-share-dash"
          iconClassName="share-icon"
          icon={hasSelectedVideos ? shareIcon : shareIconDisabled}
          disabled={!hasSelectedVideos}
          onPress={toggleShareModal}
        />
      </div>

      {!loading &&
        (videos.length ?
          <div className="video-card-wrapper">
            {videos.map((video, index) => {
              const { id: videoId } = video;
              const isSelected = !!selectedVideos.find(({ id }) => id === video.id);

              return (
                <VideoCard
                  index={index}
                  key={`${videoId}${index}`}
                  video={video}
                  onClickCheckbox={onClickCheckbox}
                  onPlayVideo={onPlayVideo}
                  onDownloadVideo={onDownloadVideo}
                  onShare={handleSelectVideo}
                  isSelected={isSelected}
                />
              );
            })
            }
          </div>
          :
          <div className="col-md-6 video-container">
            <h1 className="no-video-text title">{NO_VIDEO_TEXT}</h1>
            <p className="no-video-text description">
              Click on the
              <span className="font-weight-bold"> &quot;Create Video&quot; </span>
              button at the
              <span className="font-weight-bold"> top right </span>
              to start
            </p>
          </div>
        )}
      {loading && <Loading className="justify-content-center" />}
    </Fragment>
  );
};

VideosTab.propTypes = {
  onClickCheckbox: func.isRequired,
  onPlayVideo: func.isRequired,
  onDelete: func.isRequired,
  onCancel: func.isRequired,
  toggleShareModal: func.isRequired,
  videos: array,
  selectedVideos: array,
  loading: bool
};

export default VideosTab;
