import { SubmissionError } from 'redux-form/immutable';
import ReactGA from 'react-ga';
import { sessionService } from 'redux-react-session';
import sessionApi from 'api/sessionApi';
import history from 'utils/history';
import { getGuestToken, removeGuestToken } from 'utils/helpers';
import routes from 'constants/routesPaths';
import * as types from './actionTypes';
import { matchVideoToUser } from './videoActions';

export const logoutSuccess = () => ({
  type: types.LOGOUT
});

export const login = user =>
  async (dispatch) => {
    try {
      const guestToken = await getGuestToken();
      const userData = guestToken ? { guestToken, user } : { user };
      const response = await sessionApi.login(userData);
      sessionService.saveUser(response.user);
      guestToken && removeGuestToken();
      ReactGA.set({ userId: user.email })
      window.fbq('trackCustom', 'login');
      dispatch(matchVideoToUser());
    } catch (err) {
      throw new SubmissionError({
        _error: err.error
      });
    }
  };

export const logout = () =>
  async (dispatch) => {
    try {
      await sessionApi.logout();
      history.push(routes.index);
      sessionService.deleteSession();
      sessionService.deleteUser();
      dispatch(logoutSuccess());
      const guestToken = await getGuestToken();
      guestToken && removeGuestToken();
    } catch (err) {
      throw (err);
    }
  };
