import React, { PureComponent } from 'react';
import { bool, func, object } from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { signUp } from 'actions/userActions';
import SignUpForm from 'components/user/SignUpForm';
import routes from 'constants/routesPaths';
import withHeader from 'components/common/WithHeader';
import { REGISTER_TEXT, SIGN_UP_TEXT } from '../constants/constants';

class SignUpPage extends PureComponent {
  render() {
    const { signUp, authenticated, isMatchedVideo, selectedFirstLevelCategory } = this.props;
    const buttonText = SIGN_UP_TEXT;
    const titleId = isMatchedVideo ? 'signup.title' : 'signup.title.continue';

    if (authenticated) {
      const route = isEmpty(selectedFirstLevelCategory) ? routes.myvideolist : routes.questionMenu;
      return <Redirect to={route} />;
    }

    return (
      <div className="container">
        <div className="row justify-content-center flex-column">
          <div className="col-lg-9 col-md-9 mh-auto mt-5">
            <p className="text-center">For a limited time, we are offering a FREE 6-month membership to Megilla ($36 value) – <strong>No credit card required.</strong> We want everyone to experience the power of sharing life’s stories and connecting with friends and family. Complete the form below to join our growing community of storytellers! </p>
          </div>

          <div className="col-lg-6 col-md-8 m-auto">
            <SignUpForm onSubmit={signUp} titleId={titleId} buttonText={buttonText} />
          </div>
          <div className="col-lg-6 col-md-8 m-auto services">
              What's included:
              <ul>
                  <li>Unlimited access to the Megilla platform for 6 months</li>
                  <li>Record and share videos</li>
                  <li>Send and create questions</li>
                  <li>Download videos</li>
                  <li>Unlimited storage</li>
                  <li>No hidden fees</li>
                  <li>Free customer support</li>
                  <li>Free shipping (Kidding, we're an internet company)</li>
              </ul>
          </div>
        </div>
      </div>
    );
  }
}

SignUpPage.propTypes = {
  signUp: func.isRequired,
  authenticated: bool.isRequired,
  isMatchedVideo: bool,
  selectedFirstLevelCategory: object
};

const mapState = state => ({
  authenticated: state.getIn(['session', 'authenticated']),
  isMatchedVideo: state.getIn(['video', 'isMatchedVideo']),
  selectedFirstLevelCategory: state.getIn(['categories', 'selectedFirstLevelCategory']).toJS()
});

const mapDispatch = dispatch => ({
  signUp: user => dispatch(signUp(user.toJS()))
});

export default connect(mapState, mapDispatch)(withHeader(SignUpPage));
