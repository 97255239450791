import React, { createRef, Component } from 'react';
import { func, string } from 'prop-types';
import classNames from 'classnames';
import {
  injectIntl,
  intlShape,
  defineMessages,
} from 'react-intl';
import Button from './../common/Button';

const message = defineMessages({
  emailPlaceholder: { id: 'share.email.input.placeholder' },
});

class ShareVideoForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabledButton: true
    };

    this.inputRef = createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onChange = () => {
    const { onChangeForm } = this.props;
    const { current: { value } } = this.inputRef;
    const { disabledButton } = this.state;

    disabledButton !== !value && this.setState({ disabledButton: !value });
    onChangeForm();
  }

  handleSubmit(event) {
    event.preventDefault();
    const { handleSubmitCallback, error } = this.props;
    const { current: { value } } = this.inputRef;
    handleSubmitCallback(value);
    !error && this.setState({ disabledButton: true });
  }

  clearInput() {
    this.inputRef.current.value = '';
  }

  render() {
    const { disabledButton } = this.state;
    const { intl, error } = this.props;

    return (
      <form onSubmit={this.handleSubmit} className="share-modal-form">
        <input
          type="email"
          ref={this.inputRef}
          placeholder={intl.formatMessage(message.emailPlaceholder)}
          onChange={this.onChange}
          autoComplete="email"
          className={classNames({ error }, 'share-modal-input')}
        />
        <Button
          text="Add"
          type="submit"
          className={classNames('dashboard btn-outline btn-add-modal', { 'btn-disabled error': error })}
          disabled={disabledButton || !!error}
        />
      </form>
    );
  }
}

ShareVideoForm.propTypes = {
  intl: intlShape.isRequired,
  handleSubmitCallback: func.isRequired,
  onChangeForm: func.isRequired,
  error: string
};

export default injectIntl(ShareVideoForm, { withRef: true });
