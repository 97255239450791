import React, { PureComponent } from 'react';
import { bool, func, object } from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import LoginForm from 'components/user/LoginForm';
import { login } from 'actions/sessionActions';
import routes from 'constants/routesPaths';
import withHeader from 'components/common/WithHeader';

class LoginPage extends PureComponent {
  render() {
    const { history, login, authenticated, selectedFirstLevelCategory } = this.props;

    if (authenticated) {
      const { location: { search } } = history;
      const redirect = new URLSearchParams(search).get('redirect');
      if (redirect) {
        return <Redirect to={`/${redirect}`} />;
      }

      const route = isEmpty(selectedFirstLevelCategory) ? routes.myvideolist : routes.questionMenu;
      return <Redirect to={route} />;
    }

    return (
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8">
            <LoginForm onSubmit={login} />
          </div>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  login: func.isRequired,
  authenticated: bool.isRequired,
  selectedFirstLevelCategory: object
};

const mapState = state => ({
  authenticated: state.getIn(['session', 'authenticated']),
  selectedFirstLevelCategory: state.getIn(['categories', 'selectedFirstLevelCategory']).toJS()
});

const mapDispatch = dispatch => ({
  login: user => dispatch(login(user.toJS()))
});

export default connect(mapState, mapDispatch)(withHeader(LoginPage));
