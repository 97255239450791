import React, { PureComponent } from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import ResetPasswordForm from 'components/user/ResetPasswordForm';
import { resetPassword } from 'actions/userActions';
import withHeader from 'components/common/WithHeader';
import { saveResetPasswordToken } from 'utils/helpers';
import routes from 'constants/routesPaths';
import Modal from 'components/common/Modal';

class ResetPasswordPage extends PureComponent {
  state = {
    token: '',
    openConfirmModal: false
  };

  componentDidMount() {
    const { history } = this.props;

      const { location: { search } } = history;
      const token = new URLSearchParams(search).get('token');
      if (token) {
        saveResetPasswordToken(token);
      }
  }

  handleSubmit = (data) => {
    this.props.resetPassword(data);
    this.setState({ openConfirmModal: true });
  };

  handleCloseConfirmModal = () => {
    const { history } = this.props;
    history.push(routes.login);
  };

  render() {
    const { token, openConfirmModal } = this.state;

    return (
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8">
            <ResetPasswordForm onSubmit={this.handleSubmit} token={token} />
            {openConfirmModal &&
              <Modal
                title="Password has been reset successfully."
                onDismiss={this.handleCloseConfirmModal}
              />
            }
          </div>
        </div>
      </div>
    );
  }
}

ResetPasswordPage.propTypes = {
  resetPassword: func.isRequired,
};

const mapDispatch = dispatch => ({
  resetPassword: user => dispatch(resetPassword(user.toJS()))
});

export default connect(null, mapDispatch)(withHeader(ResetPasswordPage));
