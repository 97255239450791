import React from 'react';
import { number } from 'prop-types';
import { MAX_MIN_DURATION_VIDEO } from 'constants/constants';

const ElapsedTimeBar = ({ seconds, minutes }) => {
  const formatTime = () => {
    const formattedMinutes = `0${minutes}`;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    const maxDurationTime = (MAX_MIN_DURATION_VIDEO < 10) ? `0${MAX_MIN_DURATION_VIDEO}:00` : `${MAX_MIN_DURATION_VIDEO}:00`;

    return `${formattedMinutes}:${formattedSeconds} / ${maxDurationTime}`;
  };

  return (
    <div className="timer-container">
      <span className="elapsed-time">
        {formatTime()}
      </span>
    </div>
  );
};

ElapsedTimeBar.propTypes = {
  seconds: number.isRequired,
  minutes: number.isRequired
};

export default ElapsedTimeBar;
