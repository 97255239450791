import React, { Fragment } from 'react';
import { number, func, bool } from 'prop-types';
import videoButton from 'assets/icons/video-button.svg';
import Button from '../common/Button';

const QuestionsFooter = ({
  quantity,
  startRecording,
  showIconFirst = true
}) => (
  <div className="fixed-bottom questions-footer">
    {quantity ?
      <Fragment>
        <Button className="btn-blue-lighter" text="Start Recording" icon={videoButton} onPress={startRecording} iconFirst={showIconFirst} />
      </Fragment>
      :
      <span className="quantity">Select question to start recording</span>
    }
  </div>
);

QuestionsFooter.propTypes = {
  quantity: number,
  startRecording: func,
  showIconFirst: bool
};

export default QuestionsFooter;
