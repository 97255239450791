import React, { Fragment } from 'react';
import { array, func, string, bool } from 'prop-types';
import cn from 'classnames';
import { QUESTION_MENU } from 'constants/constants';
import Loading from 'components/common/Loading';

const formatCategoryName = (name) => {
  const splitName = name.split('&');
  const splitNameComma = name.split(',');

  if (splitName.length > 1) {
    return (<Fragment>{splitName[0]} <br /> & <br /> {splitName[1]}</Fragment>);
  } else if (splitNameComma.length > 1) {
    let formatted = '';
    splitNameComma.map((name, index) => {
      formatted = <Fragment>{formatted} {name}{index === splitNameComma.length - 1 ? '' : ','} <br /></Fragment>;
    });
    return formatted;
  }
  return name;
};

const QuestionMenuCards = ({ categoriesList, level, onSelectedLevelCategory, secondLevelName, loading }) => (
  <div className={cn('question-menu-cards-container', { '': secondLevelName })}>
    <div className="container">
      <h2 className={cn('title', { 'text-uppercase': !secondLevelName })}>
        {secondLevelName || QUESTION_MENU }
      </h2>
      {loading ?
        <Loading />
        :
        <div className="row">
          {categoriesList.map(({ id, name, image, subtitle, subcategories }) => {
            const severalNames = name.indexOf('&') > -1 || name.indexOf(',') > -1;
            const nameWithSpace = !severalNames && name.indexOf(' ') > -1 && name.length > 10;
            return (
              <div
                key={id}
                className={cn('col-lg-4 col-md-6', { 'min-col-card': secondLevelName })}
                onClick={() => onSelectedLevelCategory(id, name, level, !!subcategories.length)}
              >
                <div className="card" style={{ background: `url(${image}) no-repeat` }}>
                  <p className={cn('category-name', { 'several-names': severalNames, 'spaced-name': nameWithSpace })} >
                    {formatCategoryName(name)}
                  </p>
                  {subtitle && <p className="category-subtitle">{subtitle}</p>}
                </div>
              </div>
            );
          })}
        </div>
      }
    </div>
  </div>
);

QuestionMenuCards.propTypes = {
  categoriesList: array,
  onSelectedLevelCategory: func.isRequired,
  level: string.isRequired,
  secondLevelName: string,
  loading: bool
};

export default QuestionMenuCards;
