import React from 'react';
import { func, number } from 'prop-types';
import cn from 'classnames';
import { range } from 'lodash';

const QuestionsPagination = ({ getQuestions, total, current }) => {
  const pages = range(1, total + 1);
  return (
    <nav>
      <ul className="pagination justify-content-center">
        <li className={cn('page-item', { disabled: current === 1 })}>
          <a className="page-link" href="#" onClick={() => getQuestions(current - 1)}>
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        {pages.map((item, index) => (
          <li className={cn('page-item', { active: item === current })} key={index}>
            <a className="page-link" href="#" onClick={() => getQuestions(item)}>{item}</a>
          </li>))
        }
        <li className={cn('page-item', { disabled: current === pages.length })}>
          <a className="page-link" href="#" onClick={() => getQuestions(current + 1)}>
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

QuestionsPagination.propTypes = {
  getQuestions: func,
  total: number,
  current: number,
};

export default QuestionsPagination;
