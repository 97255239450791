import React, { Fragment } from 'react';
import { object, func, bool, number } from 'prop-types';
import cn from 'classnames';
import { VIDEO_STATES } from 'constants/constants';
import Button from 'components/common/Button';
import shareVideoIcon from 'assets/icons/share-video.svg';
import downloadVideoIcon from 'assets/icons/download-video.png';
import playIcon from 'assets/icons/play-icon-dashboard.svg';
import NoThumbnail from 'assets/images/no-thumbnail.jpg';
import { formatDate, truncateText } from 'utils/helpers';
import Loading from '../common/Loading';
import Checkbox from '../common/Checkbox';

const VideoCard = ({ onClickCheckbox, onPlayVideo, onDownloadVideo, onShare, video, isSelected, index }) => {
  const { question, thumbnail, updatedAt, duration, rootCategory, color, category } = video;
  const formattedDate = formatDate(updatedAt);
  const noMargin = (index % 3 === 2);
  const isUploading = video.state === VIDEO_STATES.uploading;
  const questionFormatted = truncateText(question);

  const handleShare = (e) => {
    e.stopPropagation();
    onShare(video);
  };

  const handleDownload = (e) => {
    e.stopPropagation();
    onDownloadVideo(video);
  };

  return (
    <div
      className={cn('video-cards-container', { 'no-margin': noMargin })}
      onClick={() => video.state === VIDEO_STATES.saved && onPlayVideo(video)}
    >
      <div className={`card ${cn({ selected: isSelected })}`}>
        <div className="card-body p-0 flex-grow-0">
          {!isUploading ?
            <Fragment>
              <Checkbox
                onChecked={() => onClickCheckbox(video)}
                selected={isSelected}
              />
              <div className="thumbnail-img">
                <img src={thumbnail || NoThumbnail} alt="video" />
              </div>
              <span className="video-length d-flex align-items-center">{duration}</span>
              <img className="play-icon" src={playIcon} alt="play" />
            </Fragment>
            :
            <Loading text="Uploading video" />
          }
        </div>
        <div className="card-footer d-flex flex-column justify-content-between flex-grow-1 p-4">
          <div>
            <div className="date">
              <span className={`category ${category === 'custom_video' && 'custom-video'}`} style={{ background: color}}>
                {(rootCategory !== 'custom_video' && rootCategory) || 'Custom Question' }
              </span>
              <span>{formattedDate}</span>
            </div>
            <span className="question-title">{questionFormatted}</span>
          </div>
          <div className="d-flex justify-content-around">
            <Button
              className="btn-share-video mx-auto"
              iconClassName="video-icon"
              text="Download"
              iconStyle={{ width: 25, height: 19 }}
              icon={downloadVideoIcon}
              onPress={handleDownload}
            />
            <Button
              className="btn-share-video mx-auto"
              iconClassName="video-icon"
              text="Share Video"
              icon={shareVideoIcon}
              onPress={handleShare}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

VideoCard.propTypes = {
  onClickCheckbox: func.isRequired,
  onPlayVideo: func.isRequired,
  video: object.isRequired,
  isSelected: bool,
  index: number
};

export default VideoCard;
