import React from 'react';
import { func, bool } from 'prop-types';

const Checkbox = ({ onChecked, selected }) => (
  <div className="checkbox-container">
    <input type="checkbox" value={selected} onClick={(event) => { event.stopPropagation(); onChecked(); }} />
    <div className={selected ? 'checked' : 'not-checked'}>
      <span className={`tick ${selected ? 'visible' : 'invisible'}`} />
    </div>
  </div>
);

Checkbox.propTypes = {
  onChecked: func.isRequired,
  selected: bool
};

export default Checkbox;
