import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withHeader from 'components/common/WithHeader';
import routes from 'constants/routesPaths';
import Button from 'components/common/Button';
import videoIcon from 'assets/icons/videoIcon.svg';
import { fetchCustomQuestions } from 'actions/questionsActions';
import { toggleUseChromeModal } from 'actions/videoActions';
import { isMobileDevice, isSafari } from 'utils/helpers';

const MyPage = ({ history, pathname, children, title, fetchCustomQuestions, toggleUseChromeModal }) => {
  useEffect(() => {
    fetchCustomQuestions();
  }, [pathname]);

  const handleCreateVideo = () => {
    if (isSafari() && !isMobileDevice()) {
      toggleUseChromeModal(1);
    } else {
      history.push(routes.questionMenu);
    }
  };

  return (
    <div className="dashboard-page-box container">
      <div className="row dashboard-header">
        <div className="col-md-6 user-name-box">
          <h1 className="user-name">{title}</h1>
        </div>
        <div className="col-md-6 create-button-box">
          <Button
            className="btn-create-question float-right"
            text="+ Send a Question"
            onPress={() => history.push(routes.sendQuestion)}
          />
          <Button
            className="btn-create-video float-right"
            iconClassName="video-icon"
            text="Create Video"
            icon={videoIcon}
            onPress={handleCreateVideo}
          />
        </div>
      </div>
      {children}
    </div>
  );
};

const mapState = state => ({
  pathname: state.getIn(['router', 'location', 'pathname']),
});

const mapDispatchToProps = dispatch => ({
  fetchCustomQuestions: () => dispatch(fetchCustomQuestions()),
  toggleUseChromeModal: () => dispatch(toggleUseChromeModal()),
});

export default withRouter(withHeader(connect(mapState, mapDispatchToProps)(MyPage)));
