import React from 'react';
import Loader from 'react-loaders';
import { string } from 'prop-types';
import variables from 'styles/_settings.scss';

const Loading = ({ color = variables.primaryColor, className = '', text = '' }) => (
  <div className={`loading-wrapper ${className}`}>
    <Loader type="ball-pulse" active color={color} />
    {text && <p>{text}</p>}
  </div>
);

Loading.propTypes = {
  color: string,
  className: string,
  text: string
};

export default Loading;
