import React, { Fragment } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { sessionService } from 'redux-react-session';
import { AppContainer, setConfig } from 'react-hot-loader';
import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import { ToastContainer } from 'react-toastify';
import ReactGA from 'react-ga';

import configureStore from 'store/configureStore';
import App from 'components/App';
import locales from 'locales';
import { DEFAULT_LANGUAGE } from 'constants/constants';
import 'styles/styles.scss';
import { blue } from 'ansi-colors';

require('./favicon.ico'); // Tell webpack to load favicon.ico

// Load service worker
if (process.env.ENABLE_PWA) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/main-sw.js');
  });
}

// Fix for browsers that don't implement Intl by default e.g.: Safari)
if (!window.Intl) {
  require.ensure([
    'intl',
    'intl/locale-data/jsonp/en.js'
  ], (require) => {
    require('intl');
    require('intl/locale-data/jsonp/en.js');
  });
}

addLocaleData([...en]);
const locale = DEFAULT_LANGUAGE;
const messages = locales[locale];
const store = configureStore();
sessionService.initSessionService(store);

ReactGA.initialize("UA-138660581-1");

const renderApp = (Component) => {
  render(
    <IntlProvider
      locale={locale}
      messages={messages}
      defaultLocale="en"
    >
      <Provider store={store}>
        <AppContainer>
          <Fragment>
            <Component />
            <ToastContainer
              className="toastr-custom-style"
              newestOnTop
              rtl={false}
              pauseOnVisibilityChange
            />
          </Fragment>
        </AppContainer>
      </Provider>
    </IntlProvider>,
    document.getElementById('app')
  );
};

renderApp(App);

setConfig({ logLevel: 'no-errors-please' });

if (module.hot) {
  module.hot.accept('./components/App', () => {
    renderApp(App);
  });
}
