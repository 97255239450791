import React from 'react';

import ElapsedTimeBar from 'components/video/ElapsedTimeBar';
import Button from 'components/common/Button';
import finishIcon from 'assets/icons/finish-icon.svg';

class RecordActions extends React.Component {
  componentDidUpdate(prevProps) {
    const { isStarted, onStartRecording } = this.props;

    if (isStarted !== prevProps.isStarted && isStarted) {
      onStartRecording();
    }
  }

  render() {
    const {
      secondsElapsed,
      minutesElapsed,
      onStopRecording
    } = this.props;

    return (
      <div className="controls-wrapper">
        <ElapsedTimeBar seconds={secondsElapsed} minutes={minutesElapsed} />
        <Button
          className="video-buttons finish"
          icon={finishIcon}
          onPress={onStopRecording}
          text="Finish"
        />
      </div>
    );
  }
}

export default RecordActions;
