import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import CheeseburgerMenu from 'cheeseburger-menu';
import { toggleUseChromeModal } from 'actions/videoActions';
import Navbar from './Navbar';
import SideBar from './SideBar';
import Footer from './Footer';
import UseChromeModal from './UseChromeModal';

const noFooterPages = ['/question-menu', '/answer-recording'];

const withHeader = (WrappedComponent) => {
  class Header extends Component {
    constructor(props) {
      super(props);
      this.state = {
        open: false,
        isHomePageScreen: WrappedComponent.name === 'HomePage',
        right: true
      };
    }

    handleHideClick = () => this.setState({ open: false });
    handleShowClick = () => this.setState({ open: true });
    handleUseChromeModalClose = () => this.props.toggleUseChromeModal(0);

    render() {
      const { props } = this;
      const { open, isHomePageScreen, right } = this.state;
      const { pathname, openUseChromeModal } = props;

      return (
        <div className={classNames({ 'other-screens': !isHomePageScreen })}>
          <CheeseburgerMenu
            isOpen={open}
            closeCallback={this.handleHideClick}
            right={right}
          >
            <SideBar />
          </CheeseburgerMenu>

          <Navbar
            {...props}
            sideOpen={open}
            openSideBar={this.handleShowClick}
            closeSideBar={this.handleHideClick}
          />
          <WrappedComponent {...props} />
          <UseChromeModal isOpen={openUseChromeModal} onClose={this.handleUseChromeModalClose} />
          {!noFooterPages.includes(pathname) && <Footer />}
        </div>
      );
    }
  }

  const mapState = state => ({
    pathname: state.getIn(['router', 'location', 'pathname']),
    openUseChromeModal: state.getIn(['video', 'openUseChromeModal']),
  });

  const mapDispatchToProps = { toggleUseChromeModal };

  return connect(mapState, mapDispatchToProps)(Header);
};

export default withHeader;
