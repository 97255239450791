import React, { Component } from 'react';
import { string, object, func, bool, array } from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { routeWithProps, downloadVideo, isSafari } from 'utils/helpers';
import humps from 'humps';
import routes from 'constants/routesPaths';
import VideosTab from 'components/dashboard/VideosTab';
import ShareModal from 'components/common/ShareModal';
import MyPage from 'components/dashboard/MyPage';
import {
  fetchVideos,
  selectOrUnselectVideo,
  unselectVideos,
  deleteVideos,
  removeVideoError,
  toggleShareModal,
  fetchVideoActionSuccess,
  checkUploadingVideos,
  checkUploadingCustomVideos,
  shareVideos,
  toggleUseChromeModal
} from 'actions/videoActions';
import {
  VIDEO_STATES,
} from 'constants/constants';

class MyVideos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDeleteModal: false,
      showSuccessShareVideoModal: false
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.deleteVideos = this.deleteVideos.bind(this);
  }

  componentDidMount() {
    const { fetchVideos } = this.props;
    window.fbq('track', 'ViewContent', { content_name: 'My Videos Page' });
    fetchVideos().then(() => {
      this.intervalId = setInterval(this.refreshStates, 10000);
    });
  }

  componentWillUnmount() {
    this.props.unselectVideos();
    clearInterval(this.intervalId);
  }

  onShareVideos = (videos = null) => {
    const { toggleShareModal, shareVideos } = this.props;
    if (videos) {
      return shareVideos(videos).then(success => success && this.toggleSuccessModal(true));
    }
    return toggleShareModal();
  };

  refreshStates = () => {
    const { checkUploadingVideos, checkUploadingCustomVideos } = this.props;
    let uploadingVideos = [];
    uploadingVideos = this.filterUploadingVideos();
    if (uploadingVideos && uploadingVideos.length > 0) {
      checkUploadingVideos(uploadingVideos.filter(({ category }) => category !== 'custom_video').map(({id}) => id).join());
      checkUploadingCustomVideos(uploadingVideos.filter(({ category }) => category === 'custom_video').map(({id}) => id).join());
    } else {
      clearInterval(this.intervalId);
    }
  }

  filterUploadingVideos = () => {
    const { videos, customVideos } = this.props;
    const unsavedVideos = [...videos, ...customVideos].map(({ id, state, category }) => {
      if (state === VIDEO_STATES.uploading) {
        return {id, category};
      }
    });
    return unsavedVideos.filter(video => video && video.id !== undefined);
  }

  toggleModal(open) {
    this.setState({ showDeleteModal: open });
  }

  toggleSuccessModal = (open) => {
    this.setState({ showSuccessShareVideoModal: open });
  }

  deleteVideos() {
    const { selectedVideos, deleteVideos } = this.props;
    const deleteVideoIds = selectedVideos.map((video) => {
      ReactGA.event({
        category: 'Video',
        action: 'Deleted a Video',
        value: video.question
      });

      return video.id
    });
    deleteVideos({ postIds: deleteVideoIds });
    this.setState({ showDeleteModal: false });
  }

  playVideo = (video) => {
    const { history, fetchVideoActionSuccess } = this.props;
    const { category, id } = video;
    fetchVideoActionSuccess(humps.camelizeKeys(video));
    history.push(routeWithProps(category !== 'custom_video' ? routes.playVideo : routes.playCustomVideo, { postId: id }));
  }

  handleVideoDownload = (video) => {
    if (isSafari()) {
      this.props.toggleUseChromeModal(2);
    } else {
      const { videoMp4, video: orgVideo, question, userName } = video;
      downloadVideo(videoMp4 || orgVideo, `${question} (${userName}).mp4`);
    }
  }

  render() {
    const {
      name,
      loading,
      history,
      videos,
      customVideos,
      selectOrUnselectVideo,
      unselectVideos,
      selectedVideos,
      removeVideoError,
      error,
      shareModal,
      toggleShareModal
    } = this.props;
    const { showDeleteModal, showSuccessShareVideoModal } = this.state;
    const allVideos = [...videos, ...customVideos];
    allVideos.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));

    return (
      <MyPage title="My Videos">
        <div className="separator" />
        <VideosTab
          loading={loading}
          videos={allVideos}
          selectedVideos={selectedVideos}
          onClickCheckbox={selectOrUnselectVideo}
          onPlayVideo={this.playVideo}
          onDownloadVideo={this.handleVideoDownload}
          onCancel={unselectVideos}
          onDelete={() => this.toggleModal(true)}
          toggleShareModal={toggleShareModal}
        />
        {shareModal &&
          <ShareModal
            loading={loading}
            shareModal={shareModal}
            handleShare={this.onShareVideos}
            toggleShareModal={toggleShareModal}
            shareableVideos={selectedVideos}
          />
        }
      </MyPage>
    );
  }
}

MyVideos.propTypes = {
  history: object.isRequired,
  fetchVideos: func.isRequired,
  fetchVideoActionSuccess: func.isRequired,
  selectOrUnselectVideo: func.isRequired,
  unselectVideos: func.isRequired,
  deleteVideos: func.isRequired,
  removeVideoError: func.isRequired,
  checkUploadingVideos: func,
  checkUploadingCustomVideos: func,
  name: string,
  loading: bool,
  videos: array,
  selectedVideos: array,
  error: string,
  shareModal: bool,
  toggleShareModal: func.isRequired,
  shareVideos: func.isRequired
};

const mapState = state => ({
  name: state.getIn(['session', 'user', 'firstName']),
  loading: state.getIn(['video', 'loading']),
  videos: state.getIn(['video', 'videosList']).toJS(),
  customVideos: state.getIn(['video', 'customVideosList']).toJS(),
  selectedVideos: state.getIn(['video', 'selectedVideos']).toJS(),
  error: state.getIn(['video', 'error']),
  shareModal: state.getIn(['video', 'shareModal'])
});

const mapDispatch = ({
  fetchVideos,
  selectOrUnselectVideo,
  deleteVideos,
  removeVideoError,
  toggleShareModal,
  checkUploadingVideos,
  checkUploadingCustomVideos,
  unselectVideos,
  fetchVideoActionSuccess,
  shareVideos,
  toggleUseChromeModal
});

export default connect(mapState, mapDispatch)(MyVideos);
