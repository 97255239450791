import React, { Component, Fragment } from 'react';
import WithHeader from "../components/common/WithHeader";

class TermsAndConditions extends Component {
  componentDidMount = () => window.scrollTo(0, 0);

  render() {
    return (
      <Fragment>
        <div className="question-menu-cards-container">
          <div className="tands-container">
            <h3 className="terms-title">TERMS OF USE</h3>
            <div style={{ marginTop: 20, marginLeft: '5%', marginRight: '8%' }}>
              <h2 style={{ fontSize: 15, marginTop: 0 }}>
                <span style={{ fontWeight: "normal" }}>Welcome to www.megilla.com, operated by Megilla Co., a Delaware corporation (</span><span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>"Megilla," "we," or "our"</span>).
             </h2><h2 style={{ fontSize: 15, marginTop: 0, marginTop: 18 }}>
                <span style={{ fontWeight: "normal" }}>By using or accessing the Megilla website located at www.megilla.com and mobile application (the</span> <span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>“Websites”</span><span style={{ fontWeight: "normal" }}>), you agree to be bound by these Terms of Use (</span><span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>“Terms of Use” or “Agreement”</span><span style={{ fontWeight: "normal" }}>), whether or not you register as a member of Megilla (</span><span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>“Member”</span><span style={{ fontWeight: "normal" }}>). If you wish to become a Member and/or make use of the Websites or any services provided through the Websites (collectively, the </span><span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>“Services”</span><span style={{ fontWeight: "normal" }}>), please read this Agreement. If you object to anything in this Agreement, any other agreements on the Websites, or the Megilla Privacy Policy, do not use the Service or access the Websites.</span></h2>
              <h2 style={{ fontSize: 15, marginTop: 0, color: '#71716f', marginTop: 18 }}>PLEASE READ THIS AGREEMENT CAREFULLY AS IT CONTAINS IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS, INCLUDING VARIOUS LIMITATIONS AND EXCLUSIONS, AND A DISPUTE RESOLUTION CLAUSE THAT GOVERNS HOW DISPUTES WILL BE RESOLVED.  BY USING THE WEBSITE AND/OR ACCESSING THE SERVICES, YOU AGREE TO BE BOUND BY THIS AGREEMENT.</h2>

              <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>1.	ELECTRONIC AGREEMENT.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>These Terms of Use are an electronic contract containing legally binding terms of your use of the Service.  These Terms of Use are subject to change by Megilla at any time, effective upon posting on the Megilla Website. By accessing and/or using the Websites or Services or becoming a Member, you accept this Agreement and agree to the terms, conditions and notices contained or referenced herein. Your continued use of any Services following Megilla’s posting of revised terms of any section of the Terms of Use will constitute your express and binding acceptance of and consent to the revised Terms of Use.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, marginTop: 18 }}><span style={{ fontWeight: "normal" }}>By using the Services, you represent and warrant that you have the right, authority and capacity to enter into this Terms of Use and you agree to abide by all the terms and conditions of this Terms of Use. If you are using and/or accessing the Service on behalf of a company, entity, or organization (collectively, a </span><span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>“Subscribing Entity”</span><span style={{ fontWeight: "normal" }}>), you represent and warrant that:</span></h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>a.	You are an authorized representative of the Subscribing Entity, and that you have the authority to bind the Subscribing Entity to this Terms of Use;</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>b.	You have read and understand this Terms of Use; and</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>c.	You agree to this Terms of Use on behalf of the Subscribing Entity.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>Illegal and/or unauthorized use of the Service may be investigated, and appropriate legal action may be taken, including without limitation, civil, criminal, and injunctive redress. Use of the Service is with the permission of Megilla, which may be revoked at any time, for any reason, in Megilla’s sole discretion.</h2>
              <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>2.	ACCOUNT SECURITY.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>You are responsible for maintaining the confidentiality of the username and password that you designate during the registration process, and you are fully responsible for all activities that occur under your username and password. You agree to (a) immediately notify Megilla of any unauthorized use of your username or password or any other breach of security, and (b) ensure that you exit from your account at the end of each session. Megilla will not be liable for any loss or damage arising from your failure to comply with this provision. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information. If you share your computer with others, you may wish to consider disabling your auto-sign in feature if you have it linked to your Megilla account.</h2>


              <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>3.	YOUR CONTENT.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, marginTop: 18 }}><span style={{ fontSize: 15, marginTop: 0, color: 'black', marginTop: 18 }}>	1.	Ownership. </span><span style={{ fontWeight: "normal" }}>	You own the Content that you create. You may only submit, post, display, create, upload (collectively, </span><span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>“Submit”</span><span style={{ fontWeight: "normal" }}>) or download Content or information (</span><span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>“Your Content”</span><span style={{ fontWeight: "normal" }}>) to or from the Website in accordance with these terms and the License Agreement (the</span> <span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>“License Agreement”</span><span style={{ fontWeight: "normal" }}>). You may not use, reproduce, distribute, display or create derivative works based on any Content that appears on the Website unless you enter into a License Agreement, and you may do so only to the extent expressly permitted under the License Agreement.</span></h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>No ownership of any Content is transferred, and no sale of any Content is effectuated on or through the Website. Only the rights expressly licensed in the License Agreement are granted on or through the Website. We and our licensors retain all title and ownership rights in and to the Content. Such Content are covered and protected by our or our licensors’ copyright, trademark and other intellectual property rights.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, marginTop: 18 }}><span style={{ fontSize: 15, marginTop: 0, color: 'black', marginTop: 18 }}>	2.	Submitting Content.  </span><span style={{ fontWeight: "normal" }}>Megilla, in its sole discretion, may determine what content is suitable for posting on the Website or other means of direct or indirect distribution, and only such content as it deems suitable in its sole discretion will be considered </span><span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>“Accepted Content”</span><span style={{ fontWeight: "normal" }}> for the purposes of applicable provisions of this Agreement.</span></h2>
              <h2 style={{ fontSize: 15, marginTop: 0, marginTop: 18 }}><span style={{ fontSize: 15, marginTop: 0, color: 'black', marginTop: 18 }}>	3.	License and Permission to Use Your Content.  </span><span style={{ fontWeight: "normal" }}>We will never share or sell Your Content without your express consent. By Submitting Your Content on Megilla, you grant Megilla and its affiliated companies a nonexclusive, worldwide, royalty free, fully paid up, transferable, sublicensable (through multiple tiers), license to use, copy, reproduce, process, adapt, modify, create derivative works from, publish, transmit, store, display and distribute, translate, communicate and make available to the public, and otherwise use Your Content in connection with the operation or use of the Service, in accordance with these Terms and Megilla’s Privacy Policy.</span></h2>
              <h2 style={{ fontSize: 15, marginTop: 0, marginTop: 18 }}><span style={{ fontSize: 15, marginTop: 0, color: 'black', marginTop: 18 }}>4.</span>	<span style={{ fontWeight: "normal" }}>You acknowledge and agree that Megilla may preserve Your Content and may also disclose Your Content and related information if required to do so by law or in the good faith belief that such preservation or disclosure is reasonably necessary to: (a) comply with legal process, applicable laws or government requests; (b) enforce these Terms of Service; (c) respond to claims that any of Your Content violates the rights of third parties; (d) detect, prevent, or otherwise address fraud, security or technical issues; or (e) protect the rights, property, or personal safety of Megilla, its users, or the public.</span></h2>
              <h2 style={{ fontSize: 15, marginTop: 0, marginTop: 18 }}><span style={{ fontSize: 15, marginTop: 0, color: 'black', marginTop: 18 }}>5.</span>	<span style={{ fontWeight: "normal" }}>You understand that we may modify, adapt, or create derivative works from Your Content in order to transmit, display or distribute it over computer networks, devices, service providers, and in various media. We also may remove or refuse to publish Your Content, in whole or part, at any time.</span></h2>
              <h2 style={{ fontSize: 15, marginTop: 0, marginTop: 18 }}><span style={{ fontSize: 15, marginTop: 0, color: 'black', marginTop: 18 }}>6.</span>	<span style={{ fontWeight: "normal" }}>You further give us the permission and authority to act as your nonexclusive agent to take enforcement action against any unauthorized use by third-parties of any of Your Content outside of the Megilla Website or in violation of our Terms of Service.</span></h2>

              <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>4.	REPRESENTATION OF OWNERSHIP.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, marginTop: 18 }}><span style={{ fontWeight: "normal" }}>By Submitting Your Content to Megilla, you represent and warrant to Megilla that you own all rights, title and interest in and to such Content, including all copyright, trademarks, patents, rights of privacy, rights of publicity, moral rights, and other intellectual property rights (collectively, </span><span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>“IP Rights”</span><span style={{ fontWeight: "normal" }}>) and/or have all necessary rights and license to grant us the rights in and to such Content that you grant to under this Agreement. To the fullest extent permitted by applicable law, you specifically waive any moral rights with respect to the Content, and to the extent such waiver is not permitted, you covenant and agree that you shall not to enforce such rights against us, our affiliates and our End Users. </span></h2>
              <h2 style={{ fontSize: 15, marginTop: 0, marginTop: 18, fontWeight: "normal" }}>You further represent and warrant that 1) the Content will not infringe the IP Rights of others, contain misleading or false information, or contain any illegal or defamatory content; 2) you will not upload any Content that infringes or violates the IP Rights of any person or entity or contains any libel, slander or other defamation upon any person. To the extent that any Content requires third-party licenses or permissions, including subject or property releases, you are responsible for obtaining and documenting such licenses or permissions; additionally, you shall keep a copy of such third-party licenses or permissions so long as the Content is available on Megilla and for a period of five (5) years after removal of such Content from Megilla. We may remove Content or terminate your account in our sole discretion without prior notice.</h2>
              <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>5.	SUBLICENSING TO END USERS. </h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>You also grant us a license to further sublicense our right to use, reproduce, publicly display, distribute, modify, publicly perform, and translate the Content on a non-exclusive, worldwide, and perpetual basis in any media or embodiment in accordance with Megilla’s license agreements with End Users, as the same may be in effect from time to time, in Megilla’s sole discretion.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>The license to our End Users may change from time to time and may include the right to modify and create derivative Contents based upon the Your Content, provided that such End Users’ use of the modified Content is limited solely to the same uses permitted in the End User License Agreement with respect to the original Content.</h2>
              <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>6.	YOUR USE OF THE SERVICE.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>You must not misuse the Services or the Website. The following conduct is expressly prohibited:</h2>

              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>a).	You may not copy, republish, adapt, make available or otherwise communicate to the public, display, perform, transfer, share, distribute or otherwise use or exploit any Content on or from the Websites and/or Service, except pursuant to a License Agreement and in accordance with these Terms of Use.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>b).	You may not use any Content in any way that is designed to create a separate service or that replicates any part of the offering of the Service.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>c).	You may not employ scraping or similar techniques to aggregate, repurpose, republish or otherwise make use of any Content.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>d).	You may not employ any techniques or make use of any services, automated or otherwise, designed to misrepresent your activity on the Service, including without limitation by the use of bots, botnets, scripts, apps, plugins, extensions or other automated means to register accounts, log in, send messages, post comments, or otherwise to act on your behalf, particularly where such activity occurs in a multiple or repetitive fashion. You may not offer or promote the availability of any such techniques or services to any other users of the Service.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>e).	You may not alter or remove, or attempt to alter or remove, any trademark, copyright or other proprietary or legal notices contained in, or appearing on, the Websites or any Content appearing on the Websites.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>f).	You may not, and may not permit any third party to, copy or adapt the object code of the Websites, or reverse engineer, reverse assemble, decompile, modify or attempt to discover any source or object code of any part of the Websites, or circumvent or attempt to circumvent or copy any copy protection mechanism or access any rights management information pertaining to content on the Websites.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>g).	You may not use the Websites to upload, post, store, transmit, display, copy, distribute, promote, make available or otherwise communicate to the public:</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>i.any content that is offensive, abusive, libelous, defamatory, obscene, racist, sexually explicit, ethnically or culturally offensive, indecent, that promotes violence, terrorism, or illegal acts, incites hatred on grounds of race, gender, religion or sexual orientation, or is otherwise objectionable in Megilla’s sole and absolute discretion;</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>ii.any information, content or other material that violates, plagiarizes, misappropriates or infringes the rights of third parties including, without limitation, copyright, trademark rights, rights of privacy or publicity, confidential information or any other right; or</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>iii.any content that violates, breaches or is contrary to any law, rule, regulation, court order or is otherwise is illegal or unlawful in Megilla’s sole and absolute discretion;</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>iv.any material of any kind that contains any virus, Trojan horse, spyware, adware, malware, bot, time bomb, worm, or other harmful or malicious component, which will or might overburden, impair or disrupt the Service or servers or networks forming part of, or connected to, the Service, or which does or might restrict or inhibit any other user's use and enjoyment of the Service; or</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>v.any unsolicited or unauthorized advertising, promotional messages, spam or any other form of solicitation.</h2>

              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>h).	You may not commit or engage in, or encourage, induce, solicit or promote, any conduct that would constitute a criminal offense, give rise to civil liability or otherwise violate any law or regulation.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>i).	You may not rent, sell or lease access to the Services, or any content on the Websites.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>j).	You may not deliberately impersonate any person or entity or otherwise misrepresent your affiliation with a person or entity, for example, by registering an account in the name of another person or company, or sending messages or making comments using the name of another person.</h2>


              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>k).	You may not stalk, exploit, threaten, abuse or otherwise harass another user, or any Megilla employee. If Megilla determines that any user has threatened, stalked, harassed, or verbally abused any Megilla employee or another Megilla Member, Megilla reserves the right to immediately terminate that user’s membership and suspend access to the Service.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>l).	You may not sell or transfer, or offer to sell or transfer, any Megilla account to any third party without the prior written approval of Megilla.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>m).	You may not collect or attempt to collect personal data, or any other kind of information about other users, including without limitation, through spidering or any form of scraping.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>n).	You may not violate, circumvent or attempt to violate or circumvent any data security measures employed by Megilla; access or attempt to access data or materials which are not intended for your use; log into, or attempt to log into, a server or account which you are not authorized to access; attempt to scan or test the vulnerability of Megilla’s servers, system or network or attempt to breach Megilla’s data security or authentication procedures; attempt to interfere with the Websites or the Services by any means including, without limitation, hacking Megilla’s servers or systems, submitting a virus, overloading, mail-bombing or crashing. Without limitation to any other rights or remedies of Megilla under these Terms of Use, Megilla reserves the right to investigate any situation that appears to involve any of the above, and may report such matters to, and cooperate with, appropriate law enforcement authorities in prosecuting any users who have participated in any such violations.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>o).	You may not allow any other person access to your account;</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>p).	You may not sell or transfer your account to another person or entity;</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>q).	You may not use the Website or Services in any manner that is not permitted by this Agreement or that violates any applicable law;</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>r).	You may not attempt to gain unauthorized access to any hardware or software systems or networks associated with the Website, or obtain any services or information not intentionally made available to you on or through the Website;</h2>

              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>s).	You may not attempt to gain unauthorized access to the account of any other person or entity, or otherwise interfere with any other person's or entity's use, of the Website; or</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>t).	You may not use any false or misleading information (e.g., false or misleading names, email addresses or URLs) when using the Website, including, without limitation, with respect to the origin or source of any Work or other material or content that you Submit or any identifying information for your account.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>u).	You may not use the Website in a fraudulent manner, or otherwise in a manner for which the Website is not reasonably intended to be used. By way of example, you may not download works for the primary purpose of artificially inflating the ranking of a given contributor or for the primary purpose of artificially triggering payments to contributors. A contributor, or someone else on her behalf, may not download Content that such contributor has Submitted.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>You agree to comply with the above conditions, and acknowledge and agree that Megilla has the right, in its sole discretion, to terminate your account or take such other action as we deem appropriate if you breach any of the above conditions or any of the other terms of these Terms of Use. This may include taking court action and/or reporting offending users to the relevant authorities.</h2>

              <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>7.	YOU BEAR RISKS OF SUBMITTING CONTENT.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, marginTop: 18 }}><span style={{ fontWeight: "normal" }}>Megilla cares deeply about the security and rights of our users and Content. However, Megilla cannot guarantee that there will be no unauthorized copying or distribution of your Content, nor will Megilla be liable for any copying or usage of Your Content not authorized by Megilla. You hereby release and forever waive any claims you may have against Megilla for any such unauthorized copying or usage of Your Content, under any theory of liability.</span><span style={{ color: '#71716f' }}> THE SECURITY MEASURES TO PROTECT YOUR CONTENT USED BY MEGILLA HEREIN ARE PROVIDED AND USED "AS-IS" AND WITH NO WARRANTIES OR ASSURANCES THAT SUCH SECURITY MEASURES WILL WITHSTAND ATTEMPTS TO EVADE SECURITY MECHANISMS OR THAT THERE WILL BE NO CRACKS, DISABLEMENTS OR OTHER CIRCUMVENTION  </span></h2>

              <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>8.	OUR CONTENT.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, marginTop: 18 }}><span style={{ fontSize: 15, marginTop: 0, color: 'black', marginTop: 18 }}>	1.  </span>	<span style={{ fontWeight: "normal" }}>All intellectual property in or related to Megilla (specifically including, but not limited to our software, the Megilla marks, the Megilla logo, but excluding Your Content) is the property of Megilla Co., or its subsidiaries and affiliates, or content posted by other Megilla users licensed to us (collectively </span><span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>“Our Content and Materials”</span><span style={{ fontWeight: "normal" }}>).</span></h2>
              <h2 style={{ fontSize: 15, marginTop: 0, marginTop: 18 }}><span style={{ fontSize: 15, marginTop: 0, color: 'black', marginTop: 18 }}>	2.	Data.  </span><span style={{ fontWeight: "normal" }}>	All data Megilla collects (</span><span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>“Data”</span><span style={{ fontWeight: "normal" }}>) about use of the Service by you or others is the property of Megilla Co., its subsidiaries, and affiliates. For clarity, Data does not include Your Content and is separate from Our Content and Materials.</span></h2>
              <h2 style={{ fontSize: 15, marginTop: 0, marginTop: 18 }}><span style={{ fontSize: 15, marginTop: 0, color: 'black', marginTop: 18 }}>	3.  </span><span style={{ fontWeight: "normal" }}>	All intellectual property in or related to Megilla (specifically including, but not limited to our software, the Megilla marks, the Megilla logo, but excluding Your Content) is the property of Megilla Co., or its subsidiaries and affiliates, or content posted by other Megilla users licensed to us (collectively “Our Content and Materials”).</span></h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>a).	We grant you a limited, non-exclusive license to use and access Our Content and Materials and Data as made available to you on the Service in connection with your use of the Service, subject to the terms and conditions of this Agreement.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>b).	Megilla gives you a worldwide, royalty-free, revocable, non-assignable and non-exclusive license to re-post Our Content and Materials anywhere on the web provided that: (a) you do not modify the content; (b) you attribute Megilla by name in readable text and with a human and machine-followable link (an HTML <span>'a'</span>anchor tag) linking back to the page displaying the original source of the content on http://megilla.com on every page that contains Our Content and Materials; (c) upon request by Megilla, you make a reasonable effort to update a particular piece of content to the latest version on the Megilla Website; (d) upon request by Megilla, you make a reasonable attempt to delete content that has been deleted or marked as not for reproduction on the Megilla Website; (e) you don’t republish more than a small portion of Our Content and Materials. In exercising these rights, you may not implicitly or explicitly assert any connection with, sponsorship or endorsement by Megilla, or any Megilla user, without separate, express prior written permission from us.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>c).	We may terminate our license to you at any time for any reason. We have the right but not the obligation to refuse to distribute any content on the Megilla Website or to remove content. Except for the rights and license granted in these terms, we reserve all other rights and grant no other rights or licenses, implied or otherwise.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}><span style={{ fontSize: 15, marginTop: 0, color: 'black', marginTop: 18 }}>4.	Permitted uses.  </span>	If you operate a search engine, web crawler, bot, scraping tool, data mining tool, bulk downloading tool, wget utility, or similar data gathering or extraction tool, you may access the Megilla Website, subject to the following additional rules: i) you must use a descriptive user agent header; ii) you must follow robots.txt at all times; iii) your access must not adversely affect any aspect of the Megilla Website’s functioning; and iv) you must make it clear how to contact you, either in your user agent string, or on your website if you have one.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}><span style={{ fontSize: 15, marginTop: 0, color: 'black', marginTop: 18 }}>5.	Ownership.  </span>	You acknowledge and agree that Our Content and Materials remain the property of Megilla's users or Megilla. The content, information and services made available on the Service are protected by U.S. and international copyright, trademark, and other laws, and you acknowledge that these rights are valid and enforceable.</h2>
              <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>9.	MEMBERSHIP & PRICING INFORMATION.	</h2>

              <h2 style={{ fontSize: 15, marginTop: 0, marginTop: 18 }}><span style={{ fontSize: 15, marginTop: 0, color: 'black', marginTop: 18 }}>1.	Membership.   </span>	<span style={{ fontWeight: "normal" }}>Your Megilla membership will continue and automatically renew until terminated. To use the Service you must have Internet access and a Megilla-ready device and provide us with one or more Payment Methods. </span><span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>"Payment Method"</span><span style={{ fontWeight: "normal" }}> means a current, valid, accepted method of payment, as may be updated from time to time and which may include payment through your account with a third party. You must cancel your membership before it renews in order to avoid billing of the membership fees for the next billing cycle to your Payment Method (see </span><span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>"Cancellation"</span> <span style={{ fontWeight: "normal" }}>below).</span> </h2>
              <h2 style={{ fontSize: 15, marginTop: 0, marginTop: 18 }}><span style={{ fontSize: 15, marginTop: 0, color: 'black', marginTop: 18 }}>2.	Free Trials. </span>	<span style={{ fontWeight: "normal" }}> Your Megilla membership may start with a free trial. The duration of the free trial period of your membership lasts will be specified during sign-up and is intended to allow new and certain former members to try the service. </span></h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>Free trial eligibility is determined by Megilla at its sole discretion and we may limit eligibility or duration to prevent free trial abuse. We reserve the right to revoke the free trial and put your account on hold in the event that we determine that you are not eligible. Members of households with an existing or recent Megilla membership are not eligible. We may use information such as device ID, method of payment or an account email address used with an existing or recent Megilla membership to determine eligibility. For combinations with other offers, restrictions may apply. </h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>We will charge the membership fee for your next billing cycle to your Payment Method at the end of the free trial period and your membership will automatically renew unless you cancel your membership prior to the end of the free trial period. To view the applicable membership price and end date of your free trial period, visit our website.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, marginTop: 18 }}><span style={{ fontSize: 15, marginTop: 0, color: 'black', marginTop: 18 }}>3.	Billing and Cancellation.   </span>	<span style={{ fontWeight: "normal" }}>	The membership fee for the Service any other charges you may incur in connection with your use of the Service, such as taxes and possible transaction fees, will be charged to your Payment Method on your specific billing date. The length of your billing cycle will depend on the type of subscription that you choose when you sign-up for the Service. Membership fees are fully earned upon payment. In some cases your payment date may change, for example if your Payment Method has not successfully settled or if your paid membership began on a day not contained in a given month. We may authorize your Payment Method in anticipation of membership or service-related charges through various methods, including authorizing it up to approximately one month of service as soon as you register. In some instances, your available balance or credit limit may be reduced to reflect the authorization during your free trial period.</span> </h2>


              <h2 style={{ fontSize: 15, marginTop: 0, marginTop: 18 }}><span style={{ fontSize: 15, marginTop: 0, color: 'black', marginTop: 18 }}>4.	Payment Methods.    </span>	<span style={{ fontWeight: "normal" }}>	To use the Service you must provide one or more Payment Methods. You authorize us to charge any Payment Method associated to your account in case your primary Payment Method is declined or no longer available to us for payment of your subscription fee. You remain responsible for any uncollected amounts. If a payment is not successfully settled, due to expiration, insufficient funds, or otherwise, and you do not cancel your account, we may suspend your access to the service until we have successfully charged a valid Payment Method. For some Payment Methods, the issuer may charge you certain fees, such as foreign transaction fees or other fees relating to the processing of your Payment Method. Check with your Payment Method service provider for details.  </span></h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>The terms of your payment will be based on your Payment Method and may be determined by agreements between you and the financial institution, credit card issuer or other provider of your chosen payment method.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>You must provide current, complete and accurate information for your user account. You must promptly update all information to keep your user account current, complete and accurate (such as a change in billing address, credit card number, or credit card expiration date). Changes to such information can be made at account settings. We may also update your Payment Methods using information provided by the payment service providers. Following any update, you authorize us to continue to charge the applicable Payment Method(s).</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, marginTop: 18 }}><span style={{ fontSize: 15, marginTop: 0, color: 'black', marginTop: 18 }}>5.	Cancellation.    </span><span style={{ fontWeight: "normal" }}>	You can cancel your Megilla membership at any time, and you will continue to have access to the Service through the end of your billing period. To cancel, go to the "Account" page on our website and follow the instructions for cancellation. If you cancel your membership, your account will automatically close at the end of your current billing period. To see when your account will close, click "Billing details" on the "Account" page. If you signed up for Megilla using your account with a third party as a Payment Method and wish to cancel your Megilla membership, you may need to do so through that third party, for example by visiting your account with the applicable third party and turning off auto-renew, or unsubscribing from the Service through that third party. You may also find billing information about your Megilla membership by visiting your account with the applicable third party.  </span></h2>
              <h2 style={{ fontSize: 15, marginTop: 0, marginTop: 18 }}><span style={{ fontSize: 15, marginTop: 0, color: 'black', marginTop: 18 }}>6.	Changes to the Price and Subscription Plans.    </span><span style={{ fontWeight: "normal" }}>	We reserve the right to change our subscription plans or adjust pricing for our service or any components thereof in any manner and at any time as we may determine in our sole and absolute discretion. Except as otherwise expressly provided for in these Terms of Use, any price changes or changes to your subscription plan will take effect following notice to you. </span></h2>
              <h2 style={{ fontSize: 15, marginTop: 0, marginTop: 18 }}><span style={{ fontSize: 15, marginTop: 0, color: 'black', marginTop: 18 }}>7.	No Refunds.   </span><span style={{ fontWeight: "normal" }}>	Payments are nonrefundable and there are no refunds or credits for partially used periods. Following any cancellation, however, you will continue to have access to the service through the end of your current billing period. At any time, and for any reason, we may provide a refund, discount, or other consideration to some or all of our members ("credits"). The amount and form of such credits, and the decision to provide them, are at our sole and absolute discretion. The provision of credits in one instance does not entitle you to credits in the future for similar instances, nor does it obligate us to provide credits in the future, under any circumstance. </span></h2>

              <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>10.	MODIFICATIONS TO SERVICE.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>Megilla reserves the right at any time to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice. You agree that Megilla shall not be liable to you or to any third party for any modification, suspension or discontinuance of the Service.
<h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>11.	BLOCKING OF IP ADDRESSES.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>In order to protect the integrity of the Services, Megilla reserves the right at any time in its sole discretion to block certain IP addresses from accessing the Websites and/or Service.</h2>


                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>12.	TERM. </h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>This Terms of Use will remain in full force and effect while you access or use the Websites and/or Service. </h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>You may terminate your membership by contacting us at help@megilla.com, or by visiting the Website. If you resign or cancel your membership to Megilla, to help Megilla analyze and improve the Service, you may be asked to provide a reason for your resignation/cancellation. </h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>Megilla may terminate your membership for any reason by sending notice to you at the email address you provide in your application for membership, or such other email address as you may later provide to Megilla. All decisions regarding the termination of accounts shall be made in the sole discretion of Megilla. Megilla is not required to provide you notice prior to terminating your membership. Megilla is not required, and may be prohibited, from disclosing a reason for the termination of your account. Even after your membership is terminated, this Terms of Use, along with all other agreements on Megilla including the License Agreement will remain in effect. All terms that by their nature may survive termination of this Terms of Use shall be deemed to survive such termination. </h2>
                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>13.	INTEGRATED SERVICE PROVIDER.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>You may enable another online service provider, such as a social networking service (“Integrated Service Provider”), to be directly integrated into your account on Megilla. By enabling an integrated service, you are allowing us to pass to, and receive from, the Integrated Service Provider your log-in information and other user data. For more information about Megilla’s use, storage, and disclosure of information related to you and your use of integrated services within Megilla, please see our Privacy Policy. Note that your use of any Integrated Service Provider and its own handling of your data and information is governed solely by their terms of use, privacy policies, and other policies.</h2>
                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>14.	THIRD-PARTY CONTENT.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>Megilla may provide third-party content on the Websites and/or Service and may provide links to webpages and content of third parties (collectively, the <span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>“Third-Party Content”</span>) as a service to those interested in this information. Megilla does not control, endorse or adopt any Third-Party Content and makes no representation or warranties of any kind regarding the Third-Party Content, including without limitation regarding its accuracy or completeness. Please be aware that Megilla does not create Third-Party Content, nor does Megilla update or monitor it. Megilla is therefore not responsible for any Third-Party Content on the Service. Users use such Third-Party Content at their own risk.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>The Service may include links or references to other web sites or services solely as a convenience to Megilla users (collectively, the <span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>“Reference Sites”</span>). Megilla does not endorse any Reference Sites or the information, materials, products, or services contained on or accessible through Reference Sites. In addition, your correspondence or business dealings with, or participation in promotions of, advertisers found on or through the Service are solely between you and the relevant advertiser. Access and use of Reference Sites, including the information, materials, products, and services on or available through Reference Sites is solely at your own risk.</h2>

                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>15.	INTELLECTUAL PROPERTY.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>Megilla, the Megilla logos and any other product or service name or slogan contained in the Service are trademarks of Megilla or our suppliers or licensors and may not be copied, imitated or used, in whole or in part, without the prior written permission of Megilla or the applicable trademark holder. Any authorized use of these trademarks must be in accordance with any guidelines that Megilla may provide you from time to time.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>Megilla retains all proprietary rights in the Websites and the Services, except where otherwise noted. The Websites contain the copyrighted material, trademarks, and other proprietary information of Megilla and its users. All content on Megilla is proprietary. Except where we have given you express written permission, you may not copy, modify, publish, transmit, distribute, perform, display, or sell any such proprietary information. Except where otherwise specified in this Agreement, all Content is copyrighted material of Megilla or its users and is for the use of Megilla users only. You agree that Megilla would be irreparably harmed by any violation or threatened violation of this section and that, therefore, Megilla shall be entitled to an injunction prohibiting you from any violation or threatened violation of this section, without posting bond, in addition to any other right or remedy it may have.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>You may not use any metatags or any other hidden text utilizing " Megilla " or any other name, trademark or product or service name of Megilla without our prior written permission. In addition, the look and feel of the Megilla Service, including all page headers, custom graphics, button icons and scripts, is the service mark, trademark and/or trade dress of Megilla and may not be copied, imitated or used, in whole or in part, without our prior written permission. All other trademarks, registered trademarks, product names and company names or logos mentioned in the Service are the property of their respective owners. Reference to any products, services, processes or other information, by trade name, trademark, manufacturer, supplier or otherwise does not constitute or imply endorsement, sponsorship or recommendation thereof by us or any other affiliation.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>The Service is owned and operated by Megilla Co. Unless otherwise indicated, all content and other materials on the Service, including, without limitation, Megilla’s logos, the visual interfaces, graphics, design, compilation, information, software, computer code (including source code or object code), services, text, pictures, information, data, sound files, other files and the selection and arrangement thereof (collectively, the <span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>“Materials”</span>) are protected by United States copyright, trade dress, patent, and trademark laws, international conventions, and all other relevant intellectual property and proprietary rights, and applicable laws (including in your country of residence).</h2>

                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>16.	COPYRIGHT POLICY; NOTICE OF INFRINGEMENT.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>Megilla prohibits the submission or posting of any Content or information that infringes or violates the copyright rights and/or other intellectual property rights (including rights of privacy and publicity) of any person or entity.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>Pursuant to Title 17, United States Code, Section 512(c)(2) or for any other claim of copyright infringement, you hereby agree that notifications of claimed copyright infringement be sent by certified mail to:</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>Megilla Co.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>16192 Coastal Highway </h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>Lewes, DE 19958</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>Attention: Copyright Agent</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, marginTop: 18 }}><span style={{ fontWeight: "normal" }}>For copyright infringement notices, your notice must meet all requirements of the Digital Millennium Copyright Act (</span><span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>“DMCA”</span><span style={{ fontWeight: "normal" }}>) (described below). Megilla will only remove content we host in response to DMCA notices that contain all of the following:</span></h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>a).	a statement that you have identified Content on Megilla that infringes your copyright or the copyright of a third party on whose behalf you are entitled to act;</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>b).	a description of the copyright work(s) that you claim have been infringed;</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>c).	a description of the Content that you claim is infringing and the Megilla URL(s) where such Content can be located;</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>d).	your full name, address and telephone number, a valid email address on which you can be contacted, and your Megilla user name if you have one;</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>e).	a statement by you that you have a good faith belief that the disputed use of the material is not authorized by the copyright owner, its agent, or the law; and</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>f).	a statement by you that the information in your notice is accurate and that you are authorized to act on behalf of the owner of the exclusive right that is allegedly infringed.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>In addition, if you wish for your notice to be considered as a notice of alleged copyright infringement pursuant to the United States Digital Millennium Copyright Act 17 U.S.C. s. 512(c), please also include the following:</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>g).	with respect to your statement that you are authorized to act on behalf of the owner of the exclusive right that is allegedly infringed, confirmation that such statement is made under penalty of perjury; and</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>h).	your electronic or physical signature (which may be a scanned copy).</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>Megilla will process any notice of alleged infringement which it receives and will take appropriate action as required by the Digital Millennium Copyright Act, 17 U.S.C. 512(c)(3) or other applicable copyright law. U.S. law provides significant penalties for submitting such a statement falsely. Under appropriate circumstances, persons who repeatedly submit infringing or unlawful material will be prohibited from posting further submissions.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>The foregoing process applies to copyright only. If you discover any Content that you believe to be in violation of your trademark or other intellectual property rights, please report this to us by mail or email at the address above or help@megilla.com. In all other cases, if you discover Content that infringes any or violates any of your other rights, which you believe is defamatory, pornographic, obscene, racist or otherwise liable to cause widespread offence, or which constitutes impersonation, harassment, spam or otherwise violates these Terms of Use or applicable law, please report this to us at the address above or help@megilla.com.</h2>





                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>17.	REPEAT INFRINGER POLICY.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>In accordance with the DMCA and other applicable laws around the world, Megilla has adopted a policy that it will promptly terminate without notice any user's access to the Service if that user is determined by Megilla to be a "repeat infringer." A repeat infringer includes, without limitation a user who has been notified by Megilla of infringing activity violations more than twice and/or who has had any user-submitted content removed from the Service more than twice. Megilla may also at our sole discretion limit access to the Service and/or terminate the accounts of any users who infringe any intellectual property rights of others, whether or not there is any repeat infringement. In addition, Megilla accommodates and does not interfere with standard technical measures used by intellectual property rights owners to protect their materials.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>Please note that we do not offer refunds to Members whose accounts are terminated as a result of repeated infringement of these Terms of Use.</h2>
                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>18.	LIMITATION OF LIABILITY.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, color: '#71716f', marginTop: 18 }}>IN NO EVENT SHALL MEGILLA BE LIABLE FOR ANY DAMAGES WHATSOEVER, WHETHER DIRECT, INDIRECT, GENERAL, SPECIAL, COMPENSATORY, CONSEQUENTIAL, AND/OR INCIDENTAL, ARISING OUT OF OR RELATING TO THE WEBSITES OR SERVICE, OR USE THEREOF. NOTHING CONTAINED IN THE WEBSITES OR SERVICE OR IN ANY WRITTEN OR ORAL COMMUNICATIONS FROM MEGILLA OR ITS EMPLOYEES OR AGENTS SHALL BE CONSTRUED TO MAKE ANY PROMISE, COVENANT, WARRANTY, OR GUARANTY, ALL OF WHICH ARE EXPLICITLY DISCLAIMED HEREBY, CONTRARY TO THE STATEMENTS AND DISCLAIMERS CONTAINED IN THIS PARAGRAPH.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, color: '#71716f', marginTop: 18 }}>THE CONTENT AND FUNCTIONALITY ON THE WEBSITES AND SERVICE, ALONG WITH THE SERVICES PROVIDED BY EMPLOYEES OF THE WEBSITES AND SERVICE, ARE OFFERED "AS-IS" WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. MEGILLA MAKES NO WARRANTIES, EXPRESS OR IMPLIED, AS TO THE OWNERSHIP, ACCURACY, COMPLETENESS OR ADEQUACY OF THE WEBSITES’ CONTENT OR THAT THE FUNCTIONALITY OF THE WEBSITES OR SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE OR FREE FROM VIRUS OR THIRD-PARTY ATTACK. YOU HEREBY ACKNOWLEDGE THAT YOUR USE OF THE WEBSITES AND THE SERVICE IS AT YOUR SOLE RISK. UNDER NO CIRCUMSTANCES SHALL MEGILLA, ITS OFFICERS, OWNERS, EMPLOYEES OR AGENTS AND THEIR RESPECTIVE HEIRS, SUCCESSORS AND ASSIGNS BE LIABLE FOR ANY DAMAGES, INCLUDING DIRECT, INCIDENTAL, PUNITIVE, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES THAT DIRECTLY OR INDIRECTLY RESULT FROM USE OF, OR INABILITY TO USE, THE WEBSITES OR SERVICE OR THE INFORMATION CONTAINED THEREIN, INCLUDING WITHOUT LIMITATION FOR VIRUSES ALLEGED TO HAVE BEEN OBTAINED FROM THE WEBSITES OR SERVICE, EVEN IF MEGILLA HAS BEEN NOTIFIED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES AND REGARDLESS OF THE THEORY OF LIABILITY.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, color: '#71716f', marginTop: 18 }}>TO THE GREATEST EXTENT PERMITTED BY LAW, YOU AGREE THAT IN NO EVENT WILL MEGILLA HAVE ANY LIABILITY, CONTINGENT OR OTHERWISE, FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES IN ANY WAY ARISING OUT OF OR RELATING TO THE AGREEMENT, WEBSITES OR THE SERVICE, INCLUDING, BUT NOT LIMITED TO LOST PROFITS, LOST DATA, LOSS OF GOODWILL, COPYRIGHT INFRINGEMENT, WORK STOPPAGE, EQUIPMENT FAILURE OR MALFUNCTION, PERSONAL INJURY, PROPERTY DAMAGE, OR ANY OTHER DAMAGES OR LOSSES, EVEN IF MEGILLA HAS BEEN ADVISED OF THE POSSIBILITY THEREOF, AND REGARDLESS OF THE LEGAL OR EQUITABLE THEORY (CONTRACT, TORT, STATUTE, INDEMNITY OR OTHERWISE) UPON WHICH SUCH LIABILITY IS BASED.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>In the event that any of the foregoing limitations are deemed to be unenforceable, to the greatest extent permitted by law, you agree that the entire aggregate liability of Megilla and sole remedy available to any Member in any case in any way arising out of or relating to the Agreement, Websites or the Service shall be limited to monetary damages that in the aggregate may not exceed the greater of $500.00 or the sum of any amount paid by the Member or user to Megilla during the six months prior to notice to Megilla of the dispute for which the remedy is sought.</h2>

                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>19.	INDEMNITY BY YOU. </h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>You agree to indemnify and hold Megilla, its subsidiaries, affiliates, officers, agents, and other partners and employees, harmless from any loss, liability, claim, or demand, including reasonable attorneys' fees, arising out of or related to:</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>a).	Your use of the Service and/or Websites in violation of this Terms of Use and/or arising from a breach of this Terms of Use, including, without limitation, your covenants, representations and warranties set forth in these Terms of Use;</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>b).	Any third-party claim of infringement of copyright or other intellectual property rights or invasion of privacy arising from or related to Your Content on the Websites; and/or your making available thereof to other users of the Websites; and/or the actual use of Your Content by other users of the Websites or related services in accordance with these Terms of Use; your use of the Content of others in violation of the License Agreement, these Terms of Service or other applicable laws; and the parameters set by you with respect to the distribution and sharing of Your Content;</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>c).	Any activity related to your account, be it by you or by any other person accessing your account with or without your consent unless such activity was caused by the act or default of Megilla.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, color: 'black', marginTop: 18 }}>20.	DISCLAIMER OF WARRANTIES.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>The Services, Content, and Website are provided “AS-IS.” To the maximum extent permitted by law, we disclaim all warranties express or implied, including the implied warranties of non-infringement, merchantability, and fitness for a particular purpose. We further disclaim any warranty that (a) the Services or Content will meet your requirements or will be constantly available, uninterrupted, timely, secure, or error-free; (b) the results that may be obtained from the use of the Services will be effective, accurate, or reliable; (c) the quality of the Services or Content will meet your expectations; or that (d) any errors or defects in the Services will be corrected.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>We specifically disclaim any liability for any actions resulting from your use of any Services or Content. You may use and access the Services or Content at your own discretion and risk, and you are solely responsible for any damage to your computer system or loss of data that results from the use and access of any Service or Content.</h2>
                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>21.	PARENTAL OR GUARDIAN PERMISSION.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, color: '#71716f', marginTop: 18 }}>CHILDREN UNDER THE AGE OF 13 ARE NOT PERMITTED TO USE THE WEBSITES AND/OR SERVICE UNLESS A SUPERVISING PARENT OR GUARDIAN IS PRESENT. INDIVIDUALS UNDER THE AGE OF 13 ARE NOT PERMITTED TO SUBSCRIBE TO OUR FEE-BASED SERVICES OR GIVE MEGILLA THEIR EMAIL ADDRESS OR ANY OTHER PERSONALLY IDENTIFIABLE INFORMATION. </h2>
                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>22.	PRIVACY POLICY.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>Use of the websites and/or the services is also governed by our Privacy Policy, located here.</h2>
                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>23.	JURISDICTION AND CHOICE OF LAW; DISPUTE RESOLUTION.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>If there is any dispute arising out of the Website and/or the Service, by using the Website and/or Service, you expressly agree that any such dispute shall be governed by the laws of the State of California, without regard to its conflict of law provisions, and you expressly agree and consent to the exclusive jurisdiction and venue of the state and federal courts of the State of California, for the resolution of any such dispute. Acceptance of the terms and conditions of this Agreement constitutes your consent to be sued in such courts and to accept service of process outside the State of California with the same force and effect as if such service had been made within the State of California. You hereby agree to accept service of process for any action hereunder by certified mail return receipt requested which service shall have the same force and effect as though service had been effected by personal service in the applicable jurisdiction. If any part of these terms is unlawful, void, or unenforceable, that part will be deemed severable and will not affect the validity and enforceability of the remaining provisions.</h2>

                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>24.	ELECTRONIC COMMUNICATIONS. </h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>You consent to receive communications from us by email in accordance with this Agreement and applicable law. You acknowledge and agree that all agreements, notices, disclosures and other communications that we provide to you electronically will satisfy any legal requirement that such communications be in writing.</h2>
                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>25.	NOTICES.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>All notices permitted or required under this Agreement, unless specified otherwise in this Agreement, must be sent in writing as follows in order to be valid: (i) if to you, by us via email to the address associated with your account, and (ii) if to us, by you via help@megilla.com. Notices will be deemed given (a) if to you, when emailed, and (b) if to us, on receipt by us.</h2>
                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>26.	NOTICE FOR CALIFORNIA USERS. </h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>Under California Civil Code Section 1789.3, California web users are entitled to the following specific consumer rights notice: the Megilla Service is provided by Megilla Co., a Delaware corporation, located in Los Angeles, California. If you have a question or complaint regarding the Service, please contact Megilla at help@megilla.com. California residents may reach the Department of Consumer Affairs Consumer Information Division at 1625 N. Market Blvd., Suite S-202, Sacramento, California 95834, or by telephone at (916) 445-1254 or (800) 952-5210 or Hearing Impaired at TDD (800) 326-2297 or TDD (916) 322-1700.</h2>
                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>27.	ARBITRATION.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, marginTop: 18 }}><span style={{ fontWeight: "normal" }}>Except where prohibited by law, as a condition of using the Websites and/or Service, you agree that any and all disputes, claims and causes of action (collectively, </span><span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>“Claim”</span><span style={{ fontWeight: "normal" }}>) arising out of or connected with the Websites and/or Service, shall be resolved individually, without resort to any form of class action, exclusively by binding arbitration for full and final settlement of such Claim, and judgment on the award rendered in the arbitration may be entered in any court having jurisdiction thereof. The parties shall split the arbitration and/or mediator costs. An award rendered by the arbitrator(s) may be entered and confirmed by the courts of the State of California, County of Los Angeles, or the United States District Court for the Central District of California. The parties agree that any post-arbitration action seeking to enforce an arbitration award or action seeking equitable or injunctive relief shall be brought exclusively in the courts of the State of California, County of Los Angeles, or the United States District Court for the Central District of California.</span></h2>
                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>28.	NO CLASS ACTIONS.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>You agree to only resolve disputes with us on an individual basis, and may not bring a claim as a plaintiff or a class member in a class, consolidated, or representative actions.</h2>
                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>29.	NO THIRD-PARTY BENEFICIARIES. </h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>You agree that, except as otherwise expressly provided in this Terms of Use, there shall be no third-party beneficiaries to this Terms of Use.</h2>

                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>30.	AVAILABILITY OUTSIDE THE U.S.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>If you access Megilla from outside of the United States, you do so at your own risk. If you access the Websites or Service from outside of the United States, you acknowledge that any personal information you provide will be processed in the United States and other geographies as selected by us in our sole discretion, and you hereby consent to the collection and processing of your personal information in a manner consistent with this Agreement and the Privacy Policy. In addition, if you access the Website or Service from outside of the United States, you agree to comply with the laws of your country, state or province, and any other applicable law, as well as these Terms of Use, the License Agreement and other agreements applicable to your use of the Websites and/or Services.</h2>
                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>31.	ENTIRE AGREEMENT.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>This Terms of Use, along with the License Agreement, and Privacy Policy, contains the entire agreement between you and Megilla regarding the use of the Websites and/or the Service.</h2>
                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>32.	ASSIGNMENT.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>This Agreement may not be assigned without Megilla 's prior written consent. Megilla may assign this agreement, without notice or consent, to any corporate affiliate or to any successor-in-interest to the business or assets of Megilla.</h2>
                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>33.	SEVERABILITY; WAIVER.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>If any provision of this Terms of Use is found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of this Terms of Use, which shall remain in full force and effect. No waiver of any term of this Terms of Use shall be deemed a further or continuing waiver of such term or any other term. In addition, Megilla’s failure to enforce any term of this Terms of Use shall not be deemed as a waiver of such term or otherwise affect Megilla’s ability to enforce such term at any point in the future.</h2>
                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>34.	NO AGENCY.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>The relationship between you and us under this Agreement is that of independent contractors. For clarification purposes, the parties are not joint ventures, partners, principal and agent, or employer and employee. Neither party shall have the power to bind or obligate the other in any manner.</h2>
                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>35.	MODIFICATION.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>Megilla may modify this Agreement at any time by posting such modifications on the Websites. We will post notice of modifications to these terms on this page. By continuing to use or access the Website, you agree to be bound by the revised terms. Megilla reserves all rights not expressly granted in these Terms of Use.</h2>
                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>36.	CONTACT.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>Feel free to contact us at help@megilla.com with any questions about these terms. Megilla is a Delaware corporation, located at 16192 Coastal Highway Lewes, DE 19958.</h2>



              </h2>

            </div>


          </div>
        </div>
      </Fragment>
    )
  }
}


export default WithHeader(TermsAndConditions);
