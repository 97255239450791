import React from 'react';
import { func, string, bool } from 'prop-types';
import classNames from 'classnames';
import arrowDown from 'assets/icons/arrow-down.svg';
import arrowUp from 'assets/icons/arrow-up.svg';
import { FIRST_LEVEL_CATEGORY, SECOND_LEVEL_CATEGORY } from 'constants/constants';

const CategoryItem = ({
  categoryName,
  categoryLevel,
  onSelectLevel,
  isSelectedCategory,
  icon = null,
  selectedIcon = null,
  hasSubcategories = false,
  className = ''
}) => {
  const stylingHelper = () => {
    const css = !hasSubcategories ? `${className} no-subcategories` : className;
    switch (categoryLevel) {
      case FIRST_LEVEL_CATEGORY: {
        return `${css} first-level`;
      }
      case SECOND_LEVEL_CATEGORY: {
        return `${css} second-level`;
      }
      default: {
        return `${css} third-level`;
      }
    }
  };

  return (
    <div className="category-item-container" onClick={onSelectLevel}>
      <div className={`categories-title ${stylingHelper()}`}>
        {hasSubcategories &&
          <img
            className="arrow-icon"
            src={isSelectedCategory ? arrowDown : arrowUp}
            alt="arrow"
          />
        }
        {categoryLevel === FIRST_LEVEL_CATEGORY &&
          <img
            className={`category-icon ${classNames({ 'no-subcategories': !hasSubcategories })}`}
            src={isSelectedCategory ? selectedIcon : icon}
            alt="category"
          />
        }
        {categoryName}
      </div>
    </div>
  );
};

CategoryItem.propTypes = {
  categoryName: string.isRequired,
  categoryLevel: string.isRequired,
  onSelectLevel: func.isRequired,
  isSelectedCategory: bool.isRequired,
  hasSubcategories: bool,
  icon: string,
  selectedIcon: string,
  className: string
};

export default CategoryItem;
