import React, { Component, Fragment } from 'react';
import WithHeader from "../components/common/WithHeader";

class TermsAndConditions extends Component {
  componentDidMount = () => window.scrollTo(0, 0);

  render() {
    return (
      <Fragment>
        <div className='question-menu-cards-container faqs-page'>
          <h3 className="faq-title">Frequently Asked Questions</h3>
          <span className="center-blue-line"></span>
          <div style={{ marginTop: 36, marginLeft: '5%', marginRight: '8%', backgroundColor: '#FFFFFF' }}>
            <h2 style={{ fontSize: 17, marginTop: 20, color: '' }}>
              {"What is Megilla?"}
            </h2><br></br>
            <h2 style={{ fontSize: 15, fontWeight: "normal", marginTop: -10 }}>
              Megilla is an online video-storytelling platform where you can tell your life story on video - one question at a time. If you have a story to tell, Megilla is the place.</h2>

            <h2 style={{ fontSize: 17, marginTop: 20, color: '' }}>
              How does it work?
            </h2><br></br>
            <h2 style={{ fontSize: 15, fontWeight: "normal", marginTop: -10 }}>
              You’re already here so that’s a great start. Basically, you need a computer or device with camera / audio capability -- then you can record videos from your devices by answering questions and talking into your camera. To start, create an account by providing your email and private password, then allow access to your device’s camera & audio. Explore the <a href="/question-menu" target="_blank" style={{ fontSize: '15px', color: 'rgb(1, 171, 223)', textDecoration: 'underline' }}>Question Menu</a>  comprised of multiple categories (My Life, Family, Education, Travel, Religion, etc.) and choose questions you want to answer. Click “record now” and tell your story. If you want to create a new question for someone, click on “send a question”, type in your question and send it to anyone you want by typing in their email. If you want to share any videos with someone, you can email those videos directly from the site without creating an additional email account. Easy as that.
            </h2>
            <h2 style={{ fontSize: 17, marginTop: 20, color: '' }}>
              How many questions can I answer?
            </h2><br></br>
            <h2 style={{ fontSize: 15, marginTop: -10, fontWeight: "normal" }}>
              Your account provides access to 750+ Megilla questions in the <a href="/question-menu" target="_blank" style={{ fontSize: '15px', color: 'rgb(1, 171, 223)', textDecoration: 'underline' }}>Question Menu</a>. You can answer all of those if you’re down for the challenge, but not all questions are relevant to all people. If someone sends you an existing question, or a new question they create, then you can answer those questions as well.
            </h2>
            <h2 style={{ fontSize: 17, marginTop: 20, color: '' }}>
              How long is each video?
            </h2><br></br>
            <h2 style={{ fontSize: 15, marginTop: -10, fontWeight: "normal" }}>
              Each question allows you to record your answer up to 3 minutes. Why? Great question. Because we think most stories can be told in under 3 minutes. Sure, our friend Steve can talk for 19 hours about the tuna fish sandwich he had for lunch, but come on Steve get it together man. Sadly, we know the world has a shrinking attention span, but time limits actually help storytellers internally-structure their content so each story is better told. Also, studies show people are more willing to watch short videos rather than long videos so this greatly increases the reality that people will watch the videos you email them. If you are reading this sentence then congratulations, bravo to your attention span.
            </h2>
            <h2 style={{ fontSize: 17, marginTop: 20, color: '' }}>
              Can I answer the same question more than once?
            </h2><br></br>
            <h2 style={{ fontSize: 15, marginTop: -10, fontWeight: "normal" }}>
              At the moment, no. But don’t worry, we’re soon adding that option so you can answer Part 2, Part 3, Part 77 of the same question if you have more to say.
            </h2>
            <h2 style={{ fontSize: 17, marginTop: 20, color: '' }}>
              If I mess up, can I re-record my answer?
            </h2><br></br>
            <h2 style={{ fontSize: 15, marginTop: -10, fontWeight: "normal" }}>
              Yes. Press the “start-over” button.
            </h2>
            <h2 style={{ fontSize: 17, marginTop: 20, color: '' }}>
              How much does it cost?
            </h2><br></br>
            <h2 style={{ fontSize: 15, marginTop: -10, fontWeight: "normal" }}>
              For now, Megilla is free. Regularly, Megilla is a paid subscription model. Subscription memberships will include monthly and/or annual membership plans, individual and/or family plans, gift membership options & more. We are passionately committed to a BOGO model (buy one, give one) so certain memberships will include a free membership to give away to anyone you want. Our goal is to make Megilla affordable for EVERYONE. We’re working on achieving the business model equivalent of the price of 2 cups of coffee per month and you can tell your life story. We believe everyone has a story to tell, and money should not be a brick wall stopping you from telling yours.
            </h2>
            <h2 style={{ fontSize: 17, marginTop: 20, color: '' }}>
              Can I email my videos?
            </h2><br></br>
            <h2 style={{ fontSize: 15, marginTop: -10, fontWeight: "normal" }}>
              Yes. You can select any videos you recorded and email them directly from the site. You do not need an additional email account to email videos, you simply need to have a Megilla account. Emailing is free, optional, unlimited.
            </h2>

            <h2 style={{ fontSize: 17, marginTop: 20, color: '' }}>
              Can everyone see the “created” questions I sent to someone?
            </h2><br></br>
            <h2 style={{ fontSize: 15, marginTop: -10, fontWeight: "normal" }}>
              Every new “created” question receives its own unique identifier so only you and the person you’re sending it to know about it, unless you email those recorded videos to someone.
            </h2>

            <h2 style={{ fontSize: 17, marginTop: 20, color: '' }}>
              Are my videos private?
            </h2><br></br>
            <h2 style={{ fontSize: 15, marginTop: -10, fontWeight: "normal" }}>
              Yes. Privacy is paramount. Consider Megilla your private video-diary. It’s your private, personal account so your videos are there for you to do whatever you wish. You can keep your videos private as long as you want. If you feel like sharing, you can email videos directly from our site.
            </h2>

            <h2 style={{ fontSize: 17, marginTop: 20, color: '' }}>
              Who else can watch my videos?
            </h2><br></br>
            <h2 style={{ fontSize: 15, marginTop: -10, fontWeight: "normal" }}>
              Only you. Remember, it’s your private account and your videos remain private unless you choose to email them to your intended recipients. If you never email your videos to anyone, they remain private in your own personal account. We’re here to create a safe, private platform for you tell your story. Your story is yours.
            </h2>

            <h2 style={{ fontSize: 17, marginTop: 20, color: '' }}>
              Is my data and/or videos shared with anyone?
            </h2><br></br>
            <h2 style={{ fontSize: 15, marginTop: -10, fontWeight: "normal" }}>
              No. At Megilla, we passionately share your desire to be completely safe on the internet. Our goal is to create a safe place for you and your legacy. We’re doing our best to gain your trust and stay out of the news. Stories are deeply personal and so is your data (debit/credit card info, email addresses, passwords, etc.). We respect your privacy and your lives. We do not sell anything about your identity to third parties. For complete answers, please review our Terms & Services and Privacy Policy.
            </h2>

            <h2 style={{ fontSize: 17, marginTop: 20, color: '' }}>
              Can I compile my videos into one, long video?
            </h2><br></br>
            <h2 style={{ fontSize: 15, marginTop: -10, fontWeight: "normal" }}>
              Not yet but soon. We know the value of having your own longer, personal bio-documentary, so we’re busy developing an easy-to-use tool enabling you to create a longer video by selecting specific videos you recorded and adjoining them together. Stay tuned.
            </h2>

            <h2 style={{ fontSize: 17, marginTop: 20, color: '' }}>
              Who owns my video content?
            </h2><br></br>
            <h2 style={{ fontSize: 15, marginTop: -10, fontWeight: "normal" }}>
              You. You will always be the sole owner of your videos. We will soon be adding the option to download your videos directly from the site, but for now they will live on Megilla.com and you are the sole owner of your video content. If you decide to cancel your subscription, you still own your videos.
            </h2>

            <h2 style={{ fontSize: 17, marginTop: 20, color: '' }}>
              Can I share my account password with people?
            </h2><br></br>
            <h2 style={{ fontSize: 15, marginTop: -10, fontWeight: "normal" }}>
              Yes. You can share your password with whoever you choose. If you choose to share your password, that person will have access to your private account and your videos. We can only hope you share your password with people you trust, because we believe the nature of your stories are personal, and once your passwords are shared that access is beyond the scope of our control.
            </h2>

            <h2 style={{ fontSize: 17, marginTop: 20, color: '' }}>
              Where are the videos stored?
            </h2><br></br>
            <h2 style={{ fontSize: 15, marginTop: -10, fontWeight: "normal" }}>
              We store all videos in safe, secure servers on Amazon Web Services, a subsidiary of Amazon.
            </h2>

            <h2 style={{ fontSize: 17, marginTop: 20, color: '' }}>
              Can I share my videos to social media?
            </h2><br></br>
            <h2 style={{ fontSize: 15, marginTop: -10, fontWeight: "normal" }}>
              Not yet but soon. We are soon including social media integration so if you want to share any of your videos to your social media channels you will have that option. Totally up to you.
            </h2>

            <h2 style={{ fontSize: 17, marginTop: 20, color: '' }}>
              Are there advertisements on Megilla?
            </h2><br></br>
            <h2 style={{ fontSize: 15, marginTop: -10, fontWeight: "normal" }}>
              No. We created Megilla so you can focus on what’s important, YOU. The experience is ad-free so you can get right to the most fun part of using Megilla, the storytelling.
            </h2>

            <h2 style={{ fontSize: 17, marginTop: 20, color: '' }}>
              Does Megilla offer different languages?
            </h2><br></br>
            <h2 style={{ fontSize: 15, marginTop: -10, fontWeight: "normal" }}>
              Not yet but great question. 好問題 Buena pregunta!  שאלה גדולה Tolle frage! 素晴らしい質問 большой вопрос! Ottima domanda!سؤال رائع! 좋은 질문 คำถามที่ดี. We are developing a translation-option that will eventually offer Megilla in your language of choice.
            </h2>

            <h2 style={{ fontSize: 17, marginTop: 20, color: '' }}>
              Who do I contact if I have questions or need help?
            </h2><br></br>
            <h2 style={{ fontSize: 15, marginTop: -10, fontWeight: "normal" }}>
              Email us at <a href="mailto:help@megilla.com" style={{ fontSize: '15px', color: 'rgb(1, 171, 223)' }} target="_top">help@megilla.com</a>. We would love to hear from you. We’re big believers in “2 ears 1 mouth” so we’re here (hear) to listen to you. What? That’s right. We listen. If you have any questions, concerns, complaints, insights, suggestions, brilliant ideas or feel compelled to share your favorite joke, please reach out. We’re hyper-caffeinated and promise to get back to you soon as we can.
            </h2>

            <h2 style={{ fontSize: 17, marginTop: 20, color: '' }}>
              Is Megilla recording me when I am not on the website?
            </h2><br></br>
            <h2 style={{ fontSize: 15, marginTop: -10, fontWeight: "normal" }}>
              No. When you are not on the Megilla website, we are not recording or listening to you.
            </h2>

            <h2 style={{ fontSize: 17, marginTop: 20, color: '' }}>
              How can I refer friends or people I think would enjoy Megilla?
            </h2><br></br>
            <h2 style={{ fontSize: 15, marginTop: -10, fontWeight: "normal" }}>
              We live for referrals! Literally. Thank you! If you have a friend, family member, neighbor, stranger or you think the person next to you in line at the grocery store should know about Megilla -- simply tell them to log onto Megilla.com and create an account. Eventually, we will be creating a referral system where you can receive free credits and additional perks -- stay tuned.
            </h2>

            <h2 style={{ fontSize: 17, marginTop: 20, color: '' }}>
              What does the name Megilla mean?
            </h2><br></br>
            <h2 style={{ fontSize: 15, marginTop: -10, fontWeight: "normal" }}>
              The word is typically spelled “megillah” but we dropped the ‘h” to keep it casual. Hebrew origins translate the word to “scroll” or “volume”. A very common, modern usage customarily translates the word to “the whole story” or “a long story”. We view Megilla as a platform for you to create your digital life story.
            </h2>

            <h2 style={{ fontSize: 17, marginTop: 20, color: '' }}>
              When and how can I cancel my subscription?
            </h2><br></br>
            <h2 style={{ fontSize: 15, marginTop: -10, fontWeight: "normal" }}>
              Not to sound dramatic so early in our relationship, but where did we go wrong? We thought we had something special. Was it something we said? Didn’t say? Said too much? What can we do to stay together? (Cue Al Green “Stay Together” song!). If you’re ready to go, we totally understand. Cancel your subscription anytime by emailing <a href="mailto:help@megilla.com" style={{ fontSize: '15px', color: 'rgb(1, 171, 223)' }} target="_top">help@megilla.com</a>. We would love to know your feedback if you’re comfortable sharing, as we are always seeking to learn, grow and cultivate long healthy relationships.
            </h2>

            <h2 style={{ fontSize: 17, marginTop: 20, color: '' }}>
              Can my children under age 13 create and /or participate in videos?
            </h2><br></br>
            <h2 style={{ fontSize: 15, marginTop: -10, fontWeight: "normal" }}>
              In a nutshell, your children under age 13 cannot create their own Megilla accounts but they can participate in Megilla videos under your supervision. This is covered under our Terms of Service and Privacy Policy, and stated here as well. "Children under the age of 13 are not permitted to use the website and/or service unless a supervising parent or guardian is present. Individuals under the age of 13 are not permitted to subscribe to our fee-based services or give Megilla their email address or any other personally identifiable information.
            </h2>
          </div>
        </div>
      </Fragment >
    )
  }
}


export default WithHeader(TermsAndConditions);
