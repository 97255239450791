import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bool, func } from 'prop-types';
import { Link } from 'react-router-dom';
import { logout } from 'actions/sessionActions';
import routes from 'constants/routesPaths';
import { notAuthenticatedNavItems, authenticatedNavItems } from 'constants/constants';

const SideBar = ({ authenticated, logout }) => {
  const navItems = authenticated ? authenticatedNavItems : notAuthenticatedNavItems;

  return (
    <div className="sidebar-container">
      {navItems.map(({ name, link }, index) => (
        <div key={index} className="sidemenu-item px-4" >
          <Link to={link}>
            {name}
          </Link>
        </div>))
      }
      {authenticated && (
          <div className="sidemenu-item px-4">
            <Link to={routes.index} onClick={logout}>Sign out</Link>
          </div>
      )}
    </div>
  );
};

SideBar.propTypes = {
  authenticated: bool.isRequired,
  logout: func.isRequired,
};

const mapState = state => ({
  authenticated: state.getIn(['session', 'authenticated']),
  name: state.getIn(['session', 'user', 'firstName'])
});

const mapDispatch = ({ logout });

export default connect(mapState, mapDispatch)(SideBar);
