import { parseInputErrors } from 'utils/helpers';
import * as types from './actionTypes';

import questionsApi from '../api/questionsApi';

export const fetchQuestionsAction = () => ({
  type: types.FETCH_QUESTIONS
});

export const fetchQuestionsActionSuccess = questions => ({
  type: types.FETCH_QUESTIONS_SUCCESS,
  questions
});

export const fetchCustomQuestionsActionSuccess = questions => ({
  type: types.FETCH_CUSTOM_QUESTIONS_SUCCESS,
  questions
});

export const fetchQuestionsActionError = error => ({
  type: types.FETCH_QUESTIONS_ERROR,
  error
});

export const pickOneQuestion = (questionId, questionType) => ({
  type: types.PICK_ONE_QUESTION,
  questionId,
  questionType,
});

export const checkQuestionAnswerAction = () => ({
  type: types.CHECK_QUESTION_ANSWER
});

export const checkQuestionAnswerActionSuccess = (answered, postId) => ({
  type: types.CHECK_QUESTION_ANSWER_SUCCESS,
  answered,
  postId
});

export const checkQuestionAnswerActionError = err => ({
  type: types.CHECK_QUESTION_ANSWER_ERROR,
  err
});

export const emptyQuestionsToRecord = () => ({
  type: types.EMPTY_QUESTIONS_TO_RECORD
});

export const addUserQuestionAction = () => ({
  type: types.ADD_USER_QUESTION
});

export const addUserQuestionSuccess = (userQuestion, added) => ({
  type: types.ADD_USER_QUESTION_SUCCESS,
  userQuestion,
  added
});

export const addUserQuestionError = error => ({
  type: types.ADD_USER_QUESTION_ERROR,
  error
});

export const updateUserQuestionAction = () => ({
  type: types.UPDATE_USER_QUESTION
});

export const updateUserQuestionSuccess = userQuestion => ({
  type: types.UPDATE_USER_QUESTION_SUCCESS,
  userQuestion
});

export const updateUserQuestionError = error => ({
  type: types.UPDATE_USER_QUESTION_ERROR,
  error
});

export const getQuestionsToRecordAction = () => ({
  type: types.GET_QUESTIONS_RECORD
});

export const getQuestionsToRecordSuccess = questions => ({
  type: types.GET_QUESTIONS_RECORD_SUCCESS,
  questions
});

export const getQuestionsToRecordError = error => ({
  type: types.GET_QUESTIONS_RECORD_ERROR,
  error
});

export const increaseAnswerCounter = () => ({
  type: types.INCREASE_QUESTION_NUMBER
});

export const resetAnswerCounter = () => ({
  type: types.RESET_QUESTION_NUMBER
});

export const removeSingleQuestion = () => ({
  type: types.REMOVE_SINGLE_QUESTION
});

export const getUserQuestionsAction = () => ({
  type: types.GET_USER_QUESTIONS
});

export const getUserQuestionsSuccess = questions => ({
  type: types.GET_USER_QUESTIONS_SUCCESS,
  questions
});

export const getUserQuestionsError = error => ({
  type: types.GET_USER_QUESTIONS_ERROR,
  error
});

export const removeQuestionsError = () => ({
  type: types.REMOVE_QUESTIONS_ERROR
});

export const addRemoveToRecord = (userQuestion, added) => ({
  type: types.ADD_REMOVE_RECORD,
  userQuestion,
  added
});

export const createCustomQuestionSuccess = () => ({
  type: types.CREATE_CUSTOM_QUESTION_SUCCESS
});

export const createCustomQuestionError = () => ({
  type: types.CREATE_CUSTOM_QUESTION_ERROR
});

export const createCustomQuestion = params => 
  (dispatch) => {
    questionsApi.createCustomQuestion(params).then(() => {
      dispatch(createCustomQuestionSuccess());
    }).catch((err) => {
      dispatch(createCustomQuestionError(parseInputErrors(err)));
    })
  };

export const remindCustomQuestionSuccess = () => ({
  type: types.REMIND_CUSTOM_QUESTION_SUCCESS
});

export const remindCustomQuestionError = () => ({
  type: types.REMIND_CUSTOM_QUESTION_ERROR
});

export const remindCustomQuestion = params => 
  (dispatch) => {
    questionsApi.remindCustomQuestion(params).then(() => {
      dispatch(remindCustomQuestionSuccess());
    }).catch((err) => {
      dispatch(remindCustomQuestionError(parseInputErrors(err)));
    })
  };
  

export const getQuestionsToRecord = () =>
  (dispatch) => {
    dispatch(getQuestionsToRecordAction());
    questionsApi.getQuestionsToRecord().then((questions) => {
      dispatch(getQuestionsToRecordSuccess(questions));
    }).catch((err) => {
      dispatch(getQuestionsToRecordError(parseInputErrors(err)));
    });
  };

export const getUserQuestions = () =>
  (dispatch) => {
    dispatch(getUserQuestionsAction());
    return questionsApi.getUserQuestions().then(({ userQuestions }) => {
      dispatch(getUserQuestionsSuccess(userQuestions));
    }).catch(({ error }) => {
      dispatch(getUserQuestionsError(parseInputErrors(error)));
    });
  };

export const addUserQuestion = (userQuestion, added) =>
  (dispatch) => {
    dispatch(addUserQuestionAction());
    questionsApi[added ? 'removeUserQuestion' : 'addUserQuestion'](userQuestion).then((question) => {
      dispatch(addUserQuestionSuccess(added ? userQuestion : question.userQuestion, added));
    }).catch(({ error }) => {
      dispatch(addUserQuestionError(parseInputErrors(error)));
    });
  };

export const updateUserQuestion = userQuestion =>
  (dispatch) => {
    dispatch(updateUserQuestionAction());
    const { id } = userQuestion;
    questionsApi.updateUserQuestions(id, userQuestion).then(({ userQuestion }) => {
      dispatch(updateUserQuestionSuccess(userQuestion));
    }).catch(({ error }) => {
      dispatch(updateUserQuestionError(parseInputErrors(error)));
    });
  };

export const fetchQuestions = () =>
  (dispatch) => {
    dispatch(fetchQuestionsAction());
    return questionsApi.fetchQuestions().then(({ questions }) => {
      dispatch(fetchQuestionsActionSuccess(questions));
    }).catch(({ error }) => {
      dispatch(fetchQuestionsActionError(parseInputErrors(error)));
    });
  };

export const fetchCustomQuestions = () =>
  (dispatch) => {
    dispatch(fetchQuestionsAction());
    return questionsApi.getCustomQuestions().then(({ questions }) => {
      dispatch(fetchCustomQuestionsActionSuccess(questions));
    }).catch(({ error }) => {
      dispatch(fetchQuestionsActionError(parseInputErrors(error)));
    });
  };

export const checkIfAlreadyAnswered = questionId =>
  async (dispatch) => {
    dispatch(checkQuestionAnswerAction());
    return questionsApi.checkIfAlreadyAnswered(questionId).then(({ posts }) => {
      const answered = posts.length !== 0;
      let postId;
      answered && (postId = posts[0].id);
      dispatch(checkQuestionAnswerActionSuccess(answered, postId));
      return postId;
    }).catch((err) => {
      dispatch(checkQuestionAnswerActionError(parseInputErrors(err)));
      return false;
    });
  };
