import React from 'react';
import { connect } from 'react-redux';
import Recorder from 'react-video-recorder';
import { checkCameraMicPermission } from 'utils/helpers';

import RecordActions from './Recorder/RecordActions';

class VideoRecorder extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.inputRef = React.createRef();
    this.checkPermission();
  }

  checkPermission = () => {
    checkCameraMicPermission(
        (stream) => {
            const tracks = stream.getTracks();
            for (let i = 0; i < tracks.length; i+=1) {
                tracks[i].stop();
            }
        },
        (err) => {
          console.log('Unable to record video: ', err);
        }
    );
};

  handleRecordingComplete = (blob) => {
    const { onFinished } = this.props;

    onFinished(blob.slice());
  };

  handleRenderActions = ({ onStopRecording, onStartRecording }) => {
    const {
      isStarted,
      secondsElapsed,
      minutesElapsed
    } = this.props;

    return (
      <RecordActions
        isStarted={isStarted}
        secondsElapsed={secondsElapsed}
        minutesElapsed={minutesElapsed}
        onStartRecording={onStartRecording}
        onStopRecording={onStopRecording}
      />
    );
  };

  render() {
    return (
      <Recorder
        isOnInitially
        isFlipped={false}
        countdownTime={0}
        timeLimit={180000}
        renderActions={this.handleRenderActions}
        onRecordingComplete={this.handleRecordingComplete}
      />
    );
  }
}


const mapState = state => ({
  userReadyToBegin: state.getIn(['video', 'userReadyToBegin'])
});

export default connect(mapState)(VideoRecorder);
