import React, { Fragment } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

const CategoriesNavigation = ({ firstLevelCat, secondLevelCat, thirdLevelCat }) => (
  <div className="categories-navigation">
    <div className={`d-flex ${classNames({ 'flex-column': !thirdLevelCat, 'flex-row': thirdLevelCat })}`}>
      <span className={classNames({ 'main-title': !secondLevelCat, 'sub-title': secondLevelCat })}>{firstLevelCat}</span>
      {secondLevelCat &&
        <Fragment>
          {thirdLevelCat && <span className="pipe">&#124;</span>}
          <span className={classNames({ 'main-title': secondLevelCat && !thirdLevelCat, 'sub-title': thirdLevelCat })}>
            {secondLevelCat}
          </span>
        </Fragment>
      }
    </div>
    {thirdLevelCat &&
      <span className="main-title">{thirdLevelCat}</span>
    }
  </div>
);

CategoriesNavigation.propTypes = {
  firstLevelCat: string,
  secondLevelCat: string,
  thirdLevelCat: string,
};

export default CategoriesNavigation;
