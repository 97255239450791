import React, { createRef, Component, Fragment, useRef, useEffect, useState } from 'react';
import { string, func, bool } from 'prop-types';
import { connect } from 'react-redux';
import saveIcon from 'assets/icons/save-icon.svg';
import fullScreenIcon from 'assets/icons/fullscreen-icon.svg';
import arrowNextIcon from 'assets/icons/arrow-next.svg';
import startOverIcon from 'assets/icons/start-over.svg';
import shareIcon from 'assets/icons/active.svg';
import { SOMETHING_WENT_WRONG } from 'constants/constants';
import { removeVideoError } from 'actions/videoActions';
import cn from 'classnames';
import variables from 'styles/_settings.scss';
import SliderVideo from './SliderVideo';
import SliderVolume from './SliderVolume';
import Loading from '../common/Loading';
import Button from '../common/Button';
import Modal from '../common/Modal';

const styles = {
  videoPlayer: {
    width: 'fit-content',
    position: 'relative',
    margin: 'auto',
  },
};

const VideoPlayer = (props) => {

  const [played, setPlayed] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(0.5);
  const [volumeBeforeMute, setVolumeBeforeMute] = useState(0);
  const [loading, setLoading] = useState(true);

  const player = useRef(null);
  const videoSource = useRef(null);

  const onPlay = () => setPlaying(true);

  const onPause = () => setPlaying(false);

  const onTimeUpdate = () => {
    const { current: videoPlayer } = player;
    setPlayed(videoPlayer.currentTime);
  }

  const onPlayPause = () => {
    const { current: videoPlayer } = player;
    playing ? videoPlayer.pause() : videoPlayer.play();
    setPlaying(!playing);
  }

  const onSliderVideoChange = (e) => {
    const { current: videoPlayer } = player;
    const time = parseFloat(e.target.value);
    setPlayed(time);
    videoPlayer.currentTime = time;
  }

  const onSliderVolumeChange = (e) => {
    const { current: videoPlayer } = player;
    const volume = parseFloat(e.target.value);
    setVolume(volume);
    volume > 0 && (videoPlayer.muted = false);
    videoPlayer.volume = volume;
  }

  const onMute = () => {
    const { current: videoPlayer } = player;
    if (volume > 0) {
      videoPlayer.volume = 0;
      videoPlayer.muted = true;
      setVolumeBeforeMute(volume);
      setVolume(0)
    } else {
      videoPlayer.volume = volumeBeforeMute;
      videoPlayer.muted = false;
      setVolumeBeforeMute(0);
      setVolume(volumeBeforeMute);
    }
  }

  const onSaveVideo = (shareableVideo) => {
    const { onSaving } = props;
    const { current: videoPlayer } = player;
    videoPlayer.pause();
    onSaving(Math.round(duration), shareableVideo);
  }

  const onRecordAgain = () => {
    const { onRecordAgain } = props;
    const { current: videoPlayer } = player;
    videoPlayer.pause();
    onRecordAgain();
  }

  const requestFullScreen = () => {
    const { current: videoPlayer } = player;
    videoPlayer.webkitRequestFullscreen();
  }

  const forcePlay = (videoPlayer) => {
    const { viewOnly } = props;
    videoPlayer.muted = true;
    videoPlayer.play();
    videoPlayer.currentTime = 0;
    videoPlayer.muted = false;
    if (viewOnly) {
      videoPlayer.pause();
    }
  }

  const loadVideo = () => {
    const { current: videoPlayer } = player;
    videoPlayer.addEventListener('loadedmetadata', () => {
      videoPlayer.muted = false;
      if (videoPlayer.duration === Infinity) {
        videoPlayer.currentTime = Number.MAX_SAFE_INTEGER;
        videoPlayer.ontimeupdate = () => {
          videoPlayer.ontimeupdate = null;
          setDuration(videoPlayer.duration);
          setLoading(false);
          videoPlayer.currentTime = 0;
          forcePlay(videoPlayer);
        };
      } else {
        setDuration(videoPlayer.duration);
        setLoading(false);
        forcePlay(videoPlayer);
      }
    });
  }

  useEffect(() => {
    loadVideo();
  }, []);

  useEffect(() => {
    const isLoading = props.loading || loading;
    isLoading !== loading && setLoading(isLoading);
  });

  useEffect(() => {
    const { current: videoPlayer } = player;
    // const { current: sourceTag } = videoSource
    // console.log(videoSource)
    if (props.url) {
      videoPlayer.src = props.url;
      // sourceTag.src = props.url;
    }
  }, [props.url])

  const isSafari = () => {
    var ua = navigator.userAgent.toLowerCase();
    return (ua.indexOf('safari') != -1 && ua.indexOf('chrome') == -1);
  }

  return (
    <Fragment>
      {loading && <Loading color={variables.white} className="loader-video-box" />}
      <div className={`video-player ${cn({ 'video-blur': loading })} `} style={styles.videoPlayer}>
        {!loading &&
          <Button
            className={cn('fullscreen-icon', { 'only-playing-video': props.viewOnly })}
            icon={fullScreenIcon}
            onlyIcon
            onPress={requestFullScreen}
          />
        }{
          // eslint-disable-next-line jsx-a11y/media-has-caption
        }<video
          className={cn('video-stream', { 'only-playing-video': props.viewOnly })}
          ref={player}
          preload="auto"
          onTimeUpdate={onTimeUpdate}
          onPlay={onPlay}
          onPause={onPause}
          poster={props.thumbnail}
          type="video/mp4"
          playsInline
        >
          {
            !isSafari() &&
            <track default kind="captions" srcLang="en" />
          }
        </video>

        <div className={`col-md-12 position-absolute ${loading ? 'invisible' : 'visible'} actions`} style={{bottom: 20}}>
          <SliderVideo
            played={played}
            onPress={onPlayPause}
            isPlaying={playing}
            maxDuration={duration}
            volume={volume}
            onMute={onMute}
            onVolumeChange={onSliderVolumeChange}
            onChangeFunction={onSliderVideoChange}
          />
          {/* <SliderVolume
            volume={volume}
            onChangeFunction={onSliderVolumeChange}
            onMute={onMute}
          /> */}
          {!props.viewOnly &&
            <Button
              className={cn('video-buttons btn-start-over')}
              icon={startOverIcon}
              onPress={onRecordAgain}
              text="Start Over"
            />}
          {props.multiple ?
            <Button
              className="video-buttons btn-next"
              icon={arrowNextIcon}
              onPress={() => onSaveVideo(false)}
              iconClassName="next-question"
              text="Next Question"
              iconFirst={!props.multiple}
            />
            :
            <Fragment>
              {!props.viewOnly &&
                <Fragment>
                  {/* <Button
                    className={cn('video-buttons btn-share')}
                    icon={shareIcon}
                    onPress={() => onSaveVideo(true)}
                    text="Share"
                  /> */}
                  <Button
                    className={cn('video-buttons btn-save')}
                    icon={saveIcon}
                    onPress={() => onSaveVideo(false)}
                    text="Save"
                  />
                </Fragment>
              }
            </Fragment>
          }
          {props.error &&
            <Modal
              isError
              title={SOMETHING_WENT_WRONG}
              description={props.error}
              onDismiss={props.removeVideoError}
            />
          }
        </div>
      </div>
    </Fragment>
  )
}

VideoPlayer.propTypes = {
  url: string.isRequired,
  onSaving: func,
  removeVideoError: func.isRequired,
  loading: bool.isRequired,
  error: string,
  multiple: bool,
  onRecordAgain: func,
  viewOnly: bool,
  thumbnail: string
};

const mapState = state => ({
  error: state.getIn(['video', 'error']),
  loading: state.getIn(['video', 'loading'])
});

const mapDispatch = ({ removeVideoError });

export default connect(mapState, mapDispatch)(VideoPlayer);
