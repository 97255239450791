import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { routeWithProps, isSafari, isMobileDevice } from 'utils/helpers';
import MyPage from 'components/dashboard/MyPage';
import Loading from 'components/common/Loading';
import RemindModal from 'components/common/RemindModal';
import RemindConfirmModal from 'components/common/RemindConfirmModal';
import { fetchCustomQuestions, pickOneQuestion, remindCustomQuestion } from 'actions/questionsActions';
import routes from 'constants/routesPaths';
import { toggleUseChromeModal } from 'actions/videoActions';

const MyQuestions = ({
  history,
  loading,
  authenticated,
  questions: myQuestions,
  remindCustomQuestion,
  selectQuestionToRecord,
  toggleUseChromeModal
}) => {

  if (!authenticated) {
    history.push(`${routes.login}?redirect=myquestions`);
  }

  const [selectedTab, setSelectedTab] = useState(1);
  const [questions, setQuestions] = useState((myQuestions && myQuestions.sent) || []);
  const [openRemind, setOpenRemind] = useState(false);
  const [openRemindConfirm, setOpenRemindConfirm] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const noVideoSentQuestions = (
      myQuestions && myQuestions.sent && myQuestions.sent.filter(({ video }) => !video).length
    ) || 0;
  const noVideoReceivedQuestions = (
      myQuestions && myQuestions.received && myQuestions.received.filter(({ video }) => !video).length
    ) || 0;

  useEffect(() => {
    window.fbq('track', 'ViewContent', { content_name: 'My Questions Page' });
  }, []);

  useEffect(() => {
    setQuestions(selectedTab === 1 ? myQuestions.received : myQuestions.sent);
  }, [selectedTab, myQuestions]);

  const toggleRemindModal = status => () => {
    setOpenRemind(status);
  };

  const toggleRemindConfirmModal = status => () => {
    setOpenRemindConfirm(status);
  };

  const handleSelectQuestion = (question, actionType) => () => {
    if (!question.video) {
      if (actionType === 'remind') {
        setSelectedQuestion(question);
        setOpenRemind(true);
      } else if (!isMobileDevice() && isSafari()) {
          toggleUseChromeModal();
        } else {
          selectQuestionToRecord(question.id, 'custom');
          history.push(routes.answerQuestions);
        }
    } else {
      history.push(selectedTab === 2
        ? `${routeWithProps(routes.playCustomVideo, { postId: question.videoId })}?token=${question.videoToken}`
        : routeWithProps(routes.playCustomVideo, { postId: question.videoId }));
    }
  };

  const handleConfirmAction = () => {
    const { receiverEmail: receiver, sender, question } = selectedQuestion;

    ReactGA.event({
      category: 'Custom Quetion',
      action: 'Reminded a Custom Question to answer',
      value: 'question'
    });

    remindCustomQuestion({ receiver, sender, question });
    setOpenRemind(false);
    setOpenRemindConfirm(true);
  };

  const renderTabContent = () => {
    if (loading) {
      return <Loading />;
    }

    return (
      <div className="question-list">
        {questions &&
          questions.map(({question, receiverEmail, sender, receiver, video, videoId, id, videoToken}) => (
            <div key={id} className={`question-item ${video && 'hasVideo'}`}>
              <div className="title">
                <div className="caption">Question:</div>
                {question}
              </div>
              <div className="name">
                <div className="caption">{selectedTab === 1 ? 'Asked by:' : 'Sent to:'}</div>
                {selectedTab === 1 ? sender : receiver}
              </div>
              {selectedTab === 1 && (
                <button
                  className={`btn-video ${video ? 'view' : 'record'}`}
                  onClick={handleSelectQuestion({question, receiver, video, id, videoId, videoToken}, 'record')}
                >
                  {video ? 'View Video' :'Record Video'}
                </button>
              )}
              {selectedTab === 2 && (
                <div>
                  {!video && <div className="small-text">Waiting for their video...</div>}
                  <button
                    className={`btn-video ${video ? 'view' : 'record'}`}
                    onClick={handleSelectQuestion({question, receiver, videoId, video, id, receiverEmail, sender, videoToken}, 'remind')}
                  >
                    {video ? 'View Video' :'Remind them'}
                  </button>
                </div>
              )}
            </div>
          ))
        }
      </div>
    )
  };

  return (
    <MyPage title="My Questions">
      <div className="tab-container">
        <div className="tab-header">
          <div
            className={`tab-header-item ${selectedTab === 1 ? 'selected' : ''}`}
            onClick={() => setSelectedTab(1)}
          >
            Questions to Answer
            {noVideoReceivedQuestions > 0 && <div className="badge">{noVideoReceivedQuestions}</div>}
          </div>
          <div
            className={`tab-header-item ${selectedTab === 2 ? 'selected' : ''}`}
            onClick={() => setSelectedTab(2)}
          >
            Questions I've Sent
            {noVideoSentQuestions > 0 && <div className="badge">{noVideoSentQuestions}</div>}
          </div>
        </div>
        <div className="separator" />
        <div className="tab-content">
          {renderTabContent()}
        </div>
      </div>
      {selectedQuestion && 
        <RemindModal
          isOpen={openRemind}
          question={selectedQuestion.question}
          name={selectedQuestion.receiver}
          onConfirm={handleConfirmAction}
          onClose={toggleRemindModal(false)}
        />
      }
      <RemindConfirmModal
        isOpen={openRemindConfirm}
        onClose={toggleRemindConfirmModal(false)}
      />
    </MyPage>
  );
}

const mapStateToProps = state => ({
  loading: state.getIn(['questions', 'loading']),
  questions: state.getIn(['questions', 'customQuestionsList']).toJS(),
  authenticated: state.getIn(['session', 'authenticated']),
});

const mapDispatchToProps = dispatch => ({
  fetchCustomQuestions: () => dispatch(fetchCustomQuestions()),
  selectQuestionToRecord: (question, type) => dispatch(pickOneQuestion(question, type)),
  remindCustomQuestion: params => dispatch(remindCustomQuestion(params)),
  toggleUseChromeModal: () => dispatch(toggleUseChromeModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(MyQuestions);
