import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import facebook from "assets/images/facebook.png";
import twitter from "assets/images/twitter.png";
import instagram from "assets/images/instagram.png";
import logo from "assets/images/logo.png";

const Footer = ({ authenticated }) => (
  <div className="footer">
    <div className="footer-flex">
      <div>
        <div>
          <Link to="/">
            <img src={logo}></img>
          </Link>
          <p>Tell your story the easy way.</p>
          <div className="footer-social">
            <a
              rel="noopener noreferrer"
              href="https://www.facebook.com/megillaco/"
              target="_blank"
            >
              <img src={facebook}></img>
            </a>
            <a
              rel="noopener noreferrer"
              href="https://twitter.com/megilla_co"
              target="_blank"
            >
              <img src={twitter}></img>
            </a>
            <a
              rel="noopener noreferrer"
              href="https://www.instagram.com/megilla_co/"
              target="_blank"
            >
              <img src={instagram}></img>
            </a>
          </div>
        </div>
      </div>
      <div className="footer-navigation">
        <h3>Menu</h3>
        {!authenticated && <Link to="/sign-up">Start your story</Link>}
        <Link to="/question-menu">Question Menu</Link>
        <Link to="/pricing">Pricing</Link>
        <Link to="/our-story">Our story</Link>
        <Link to="/how-to-video">How-To Videos</Link>
        {!authenticated && <Link to="/login">Login</Link>}
      </div>
      <div className="footer-navigation">
        <h3>Information</h3>
        <Link to="/faqs">FAQ</Link>
        <Link to="/press">Press</Link>
        {/* <Link to="/partners">Partners</Link> */}
        <Link to="/termsandcondition">Terms & Conditions</Link>
        <Link to="/privacypolicy">Privacy Policy</Link>
      </div>
      <div className="footer-navigation">
        <h3>Get in Touch</h3>
        <p>help@megilla.com</p>
        <p>(424) 337-1006</p>
      </div>
    </div>
    <p className="footer-copyright">© 2020 Megilla Co. All Rights Reserved</p>
  </div>
);

const mapState = state => ({
  authenticated: state.getIn(["session", "authenticated"]),
});

export default connect(mapState)(Footer);
