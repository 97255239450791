import React, { Component, Fragment } from "react";
import ReactPlayer from "react-player";
import Modal from "react-responsive-modal";
import WithHeader from "../components/common/WithHeader";
import playButton from "../assets/landingPage/playButton.png";

class HowToVideo extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = { openModal: false, url: null };
    }
  
    componentDidMount() {
      window.scrollTo(0, 0);
      window.fbq('track', 'ViewContent', { content_name: 'Landing Page' });
    }
  
    onOpenModal = url => this.setState({ openModal: true, url });
  
    onCloseModal = () => {
      this.setState({ openModal: false });
    };
  
    render() {
        return (
            <Fragment>
                <div className="pricing">
                    <h3>Video Tutorials</h3>
                    <span className="center-blue-line"></span>
                    <div className="mt-5">
                        <p className="text-center">
                            Meet Nancy, owner of&nbsp;
                            <a
                                href="https://www.nancys-tech-help.com/"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{color: '#63b3ed', fontSize: '1.8rem'}}
                            >Nancy's Tech Help,</a>
                        </p>
                        <p className="text-center">and star of Megilla's how-to-videos.</p>
                        <div className="row mt-5">
                            <div className="col-xs-12 col-sm-6">
                                <div
                                    className="lp-video-container how-to"
                                    onClick={() =>
                                        this.onOpenModal("https://megilla-production-backend.s3.amazonaws.com/1/How+to+Record+a+Video+.mp4")}
                                >
                                    <img className="video-thumbnail" src="https://megilla-production-backend.s3.amazonaws.com/1/How+to+Record+a+Video+.png" />
                                    <img src={playButton} />
                                    <p>How to Record a Video</p>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div
                                    className="lp-video-container how-to"
                                    onClick={() =>
                                        this.onOpenModal("https://megilla-production-backend.s3.amazonaws.com/1/How+to+Share+a+Video+.mp4")}
                                >
                                    <img className="video-thumbnail" src="https://megilla-production-backend.s3.amazonaws.com/1/How+to+Share+a+Video+.png" />
                                    <img src={playButton} />
                                    <p>How to Share a Video</p>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div
                                    className="lp-video-container how-to"
                                    onClick={() =>
                                        this.onOpenModal("https://megilla-production-backend.s3.amazonaws.com/1/How+to+Send+an+Existing+uestion+.mp4")}
                                >
                                    <img className="video-thumbnail" src="https://megilla-production-backend.s3.amazonaws.com/1/How+to+Send+a+Question+.png" />
                                    <img src={playButton} />
                                    <p>How to Send an Existing Question</p>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div
                                    className="lp-video-container how-to"
                                    onClick={() =>
                                        this.onOpenModal("https://megilla-production-backend.s3.amazonaws.com/1/How+to+Create+_+Send+a+Custom+Question.mp4")}
                                >
                                    <img className="video-thumbnail" src="https://megilla-production-backend.s3.amazonaws.com/1/How+to+Send_Create+a+Custom+Question.png" />
                                    <img src={playButton} />
                                    <p>How to Create/Send a Custom Question</p>
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-6">
                                <div
                                    className="lp-video-container how-to"
                                    onClick={() =>
                                        this.onOpenModal("https://megilla-production-backend.s3.amazonaws.com/1/NANCY+--+Record+A+Story+About+a+Photo.mp4")}
                                >
                                    <img className="video-thumbnail" src="https://megilla-production-backend.s3.amazonaws.com/1/Screen+Shot+2020-07-16+at+9.14.34+AM.png" />
                                    <img src={playButton} />
                                    <p>How to Record a Video about a Photo</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal
                        style={{ height: 800, marginTop: 150, backgroundColor: "black" }}
                        open={this.state.openModal}
                        onClose={() => this.onCloseModal()}
                        center
                    >
                        <ReactPlayer
                        playing={true}
                        url={this.state.url}
                        width="100%"
                        height="100%"
                        controls={true}
                        />
                    </Modal>
                </div>
            </Fragment>
        )
    }
}

export default WithHeader(HowToVideo);
