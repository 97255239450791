import routesPaths from 'constants/routesPaths';
import HomePage from 'containers/HomePage';
import LoginPage from 'containers/LoginPage';
import SignUpPage from 'containers/SignUpPage';
import QuestionsPage from 'containers/QuestionsPage';
import FAQsPage from 'containers/FAQsPage';
import MyVideoList from 'containers/MyVideoList';
import MyQuestions from 'containers/MyQuestions';
import DashboardPage from 'containers/DashboardPage';
import PrivacyPolicyPage from 'containers/PrivacyPolicyPage';
import TermsAndConditionsPage from 'containers/TermsAndConditionsPage';
import QuestionMenuPage from 'containers/QuestionMenuPage';
import AnswerQuestionsPage from 'containers/AnswerQuestionsPage';
import PlayVideoPage from 'containers/PlayVideoPage';
import PlayCustomVideoPage from 'containers/PlayCustomVideoPage';
import MyListPage from 'containers/MyListPage';
import VideoWebRTC from 'containers/VideoWebRtc';
import Pricing from 'containers/Pricing';
import HowToVideo from 'containers/HowToVideo'
// import Partners from 'containers/Partners'
import OurStory from 'containers/OurStory'
import Press from 'containers/Press'
import CreateQuestionPage from 'containers/CreateQuestionPage';
import SendQuestionPage from 'containers/SendQuestionPage';
import ForgotPasswordPage from 'containers/ForgotPasswordPage';
import ResetPasswordPage from 'containers/ResetPasswordPage';

const routes = [
  {
    path: routesPaths.index,
    component: HomePage,
    exact: true
  },
  {
    path: routesPaths.resetPassword,
    component: ResetPasswordPage
  },
  {
    path: routesPaths.login,
    component: LoginPage
  },
  {
    path: routesPaths.forgotPassword,
    component: ForgotPasswordPage
  },
  {
    path: routesPaths.signUp,
    component: SignUpPage
  },
  {
    path: routesPaths.stepQuestions,
    component: QuestionsPage
  },
  {
    path: routesPaths.privacypolicy,
    component: PrivacyPolicyPage
  },
  {
    path: routesPaths.termsandcondition,
    component: TermsAndConditionsPage
  },
  {
    path: routesPaths.faqs,
    component: FAQsPage
  },
  {
    path: routesPaths.answerQuestions,
    component: AnswerQuestionsPage
  },
  {
    path: routesPaths.questionMenu,
    component: QuestionMenuPage
  },
  {
    path: routesPaths.dashboard,
    component: DashboardPage,
    private: true
  },
  {
    path: routesPaths.playVideo,
    component: PlayVideoPage
  },
  {
    path: routesPaths.playCustomVideo,
    component: PlayCustomVideoPage
  },
  {
    path: routesPaths.myList,
    component: MyListPage,
    private: true
  },
  {
    path: routesPaths.webrtc,
    component: VideoWebRTC
  },
  {
    path: routesPaths.pricing,
    component: Pricing
  },
  {
    path: routesPaths.howtovideo,
    component: HowToVideo
  },
  {
    path: routesPaths.ourStory,
    component: OurStory
  },
  // {
  //   path: routesPaths.partners,
  //   component: Partners
  // },
  {
    path: routesPaths.press,
    component: Press
  },
  {
    path: routesPaths.sendQuestion,
    component: SendQuestionPage
  },
  {
    path: routesPaths.createQuestion,
    component: CreateQuestionPage
  },
  {
    path: routesPaths.myvideolist,
    component: MyVideoList
  },
  {
    path: routesPaths.MyQuestions,
    component: MyQuestions
  },
  {
    component: HomePage
  }
];

export default routes;
