import React from 'react';
import { string, func, object } from 'prop-types';

import Button from '../common/Button';

const ReadyToBegin = ({ questionTitle, isIos, startCountdown, startRecording, onChange }) => (
  <div className={`question-video ${isIos && 'is-ios'}`}>
    {/* {questionNumber.number > -1 && <h3>{questionNumber.text} Question</h3>} */}
    <h1 className="question">{questionTitle}</h1>
    {!isIos &&
      <Button
        className="btn-start btn-white"
        onPress={startCountdown}
        text="Start!"
      />
    }
    {isIos && (
      <React.Fragment>
        <div id="ios-record-button" className="button btn-start btn-white" onClick={startRecording}>
          <input
            id="video-recorder"
            type="file"
            accept="video/*"
            capture="capture"
            onChange={onChange}
          />
          Start!
        </div>
      </React.Fragment>
    )}
  </div>
);

ReadyToBegin.propTypes = {
  questionTitle: string.isRequired,
  startCountdown: func.isRequired
};

export default ReadyToBegin;
