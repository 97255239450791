import React from 'react';
import ReactModal from 'react-modal';
import Button from './Button';

const RemindModal = ({ name, question, isOpen, onConfirm, onClose }) => (
    <ReactModal isOpen={isOpen} ariaHideApp={false} className="modal-opened remind-modal">
      <div className="content">
        <h1 className="modal-title">Remind {name} to answer:</h1>
        <p className="question">{question}</p>
        <span className="text">This will send them an email reminder to make their video.</span>
        <div className="actions">
          <Button
            text="Remind them"
            className="btn-green"
            onPress={onConfirm}
          />
          <Button
            text="< Go back"
            className="link"
            onPress={onClose}
          />          
        </div>
      </div>
    </ReactModal>
  );

export default RemindModal;
