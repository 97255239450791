import { combineReducers } from 'redux-immutable';
import { reducer as form } from 'redux-form/immutable';
import { sessionImmutableReducer as session } from 'redux-react-session';

import router from './routerReducer';
import questions from './questionsReducer';
import video from './videoReducer';
import categories from './categoriesReducer';

const rootReducer = combineReducers({
  form,
  session,
  router,
  questions,
  video,
  categories
});

export default rootReducer;
