import React, { createRef, Component } from 'react';
import { array, bool, func, number, object } from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { Redirect } from 'react-router-dom';
import routes from 'constants/routesPaths';
import withHeader from 'components/common/WithHeader';
import finishIcon from 'assets/icons/finish-icon.svg';
import Button from 'components/common/Button';
import Loading from 'components/common/Loading';
import ReadyToBegin from 'components/video/ReadyToBegin';
import VideoPlayer from 'components/video/VideoPlayer';
import VideoTitle from 'components/video/VideoTitle';
import VideoRTC from 'containers/VideoWebRtc';
import VideoRecorder from 'containers/VideoRecorder';
import ElapsedTimeBar from 'components/video/ElapsedTimeBar';
import { createAndSaveGuestToken, isIos, isMobileDevice, checkCameraMicPermission } from 'utils/helpers';
import { userReady,
  streamPermission,
  startCountdown,
  isRecordingVideo,
  loadingVideo,
  saveVideo,
  matchVideoToUser,
  toggleShareModal,
  uploadVideo
} from 'actions/videoActions';
import { increaseAnswerCounter, addUserQuestion, resetAnswerCounter, pickOneQuestion, addRemoveToRecord } from 'actions/questionsActions';
import {
  MAX_MIN_DURATION_VIDEO,
  BEGIN_VIDEO_TITLE,
  CHECK_IT_OUT,
  VIDEOS_QUEUE_POSITIONS,
  VIDEO_STATES
} from 'constants/constants';
import variables from 'styles/_settings.scss';

class AnswerQuestionsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.getInitialState(),
      questionTitle: '',
      isStart: false,
      isFinish: false,
    };

    this.videoTagRef = createRef();
    this.elapsedTimeInterval = null;
  }

  getInitialState = () => ({
    secondsElapsed: 0,
    minutesElapsed: 0,
    url: '',
    mediaStream: null,
    stop: null,
    start: null,
    isFinish: false,
  });

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { singleSelectedQuestion } = this.props;
    this.inputRef = React.createRef();
    if (!isEmpty(singleSelectedQuestion)) {
      this.setQuestionTitle();
    }
    this.permissionCheckTimeout = setTimeout(this.checkPermission, 500);
  }

  componentDidUpdate(prevProps) {
    const { countdownBegin, userReadyToBegin } = this.props;

    this.setQuestionTitle();

    countdownBegin && this.prepareUser();
  }

  componentWillUnmount() {
    const { resetAnswerCounter, history: { location: { pathname } }, history } = this.props;

    if (this.permissionCheckTimeout) {
      clearTimeout(this.permissionCheckTimeout);
    }

    this.resetRecord();
    resetAnswerCounter();
    if (pathname === '') {
      history.push(routes.myvideolist);
    }
  }
    
    checkPermission = () => {
      checkCameraMicPermission(
          (stream) => {
              const tracks = stream.getTracks();
              for (let i = 0; i < tracks.length; i+=1) {
                  tracks[i].stop();
              }
          },
          (err) => {
              console.log('Permission Error>>>', err)
          }
      );
  }

  setQuestionTitle = () => {
    const { singleSelectedQuestion } = this.props;
    const { questionTitle } = this.state;
    let newQuestionTitle = '';

    if (!isEmpty(singleSelectedQuestion)) {
      newQuestionTitle = singleSelectedQuestion.name || singleSelectedQuestion.question;
    }

    newQuestionTitle && newQuestionTitle !== questionTitle && this.setState({ questionTitle: newQuestionTitle });
  }

  setStreamToVideo = (stream) => {
    const { current: video } = this.videoTagRef;
    video.src = stream;
  }

  setTimeDurationVideo = () => {
    this.elapsedTimeInterval = setInterval(() => {
      const { stop: stopRecording, minutesElapsed } = this.state;

      this.setState((prevState) => {
        if (prevState.secondsElapsed < 59) {
          return { secondsElapsed: prevState.secondsElapsed + 1 };
        }
        return { secondsElapsed: 0, minutesElapsed: prevState.minutesElapsed + 1 };
      });

      minutesElapsed === MAX_MIN_DURATION_VIDEO && stopRecording();
    }, 1000);
  }

  getQuestionNumber = () => {
    const { questionToAnswerNumber, questionsToRecordList } = this.props;
    if (questionsToRecordList.length) {
      const numb = VIDEOS_QUEUE_POSITIONS.find(constant => constant.number === questionToAnswerNumber);
      return numb;
    }

    return { number: -1, text: '' };
  }

  getRemainingQuestions = () => {
    const { questionsToRecordList } = this.props;
    if (questionsToRecordList.length) {
      const remaining = questionsToRecordList.length - 1;
      return remaining > 0 ? remaining : 0;
    }

    return -1;
  }

  clearTimeDurationVideo = () => {
    this.setState({ secondsElapsed: 0, minutesElapsed: 0 });
    clearInterval(this.elapsedTimeInterval);
  }

  handleStart = (stream) => {
    const { isRecordingVideo } = this.props;
    this.setTimeDurationVideo();
    this.setStreamToVideo(stream);
    isRecordingVideo(true);
  }

  handleStop = (blob, stream) => {
    const { isRecordingVideo } = this.props;
    // const { current: video } = this.videoTagRef;
    this.clearTimeDurationVideo();
    this.handleStreamClose();
    isRecordingVideo(false);
    // video.src = '';
    this.setState({ blob, url: URL.createObjectURL(blob), isStart: false });
  }

  handleStreamClose = () => {
    this.props.streamPermission(false);
  }

  prepareUser = () => {
    const { isRecordingVideo } = this.props;
    setTimeout(()=> {
      this.props.startCountdown(false);
      this.props.userReady(true);
      this.setState({ isStart: true });
      isRecordingVideo(true);
      this.setTimeDurationVideo();
    }, 2888)
  }

  getStartedRecording = () => {
    const { isRecordingVideo } = this.props;
    this.props.startCountdown(false);
    this.props.userReady(true);
    this.setState({ isStart: true });
    isRecordingVideo(true);
    this.setTimeDurationVideo();
  }

  handleSaveVideo = (duration, shareableVideo) => {
    const {
      saveVideo,
      history,
      authenticated,
      matchVideoToUser,
      questionAlreadyAnswered,
      questionAnsweredInPostId,
      singleSelectedQuestion,
      toggleShareModal,
      uploadVideo
    } = this.props;
    const { blob } = this.state;
    const isIOS = isIos() && isMobileDevice();

    !isIOS && (blob.name = Date.now());

    const videoData = {
      post: {
        duration,
        state: VIDEO_STATES.uploading
      }
    };
    const awsKey = `${blob.name}`;

    if (!isEmpty(singleSelectedQuestion)) {
      const guestToken = createAndSaveGuestToken();
      let route;
      
      singleSelectedQuestion.name
        ? videoData.post.questionId = singleSelectedQuestion.id
        : videoData.post.customQuestionId = singleSelectedQuestion.id;
      videoData.post.guestToken = guestToken;
      saveVideo(videoData, questionAlreadyAnswered, questionAnsweredInPostId, singleSelectedQuestion.name).then((post) => {
        const { id: postId } = post;

        uploadVideo(blob, awsKey, postId, singleSelectedQuestion.name);
        if (authenticated) {
          route = routes.myvideolist;
          matchVideoToUser();
          shareableVideo && toggleShareModal();
        } else {
          route = routes.signUp;
          matchVideoToUser(false);
        }
        history.push(route);
      });
    }
  }

  resetRecord = () => {
    const { userReady } = this.props;
    this.setState(this.getInitialState());

    userReady(false, false);
    streamPermission(false);
    this.handleStreamClose();
    clearInterval(this.elapsedTimeInterval);
  }

  reRecord = () => {
    this.resetRecord();
  }

  changeTitle = () => {
    const { userReadyToBegin, recordingVideo } = this.props;
    const { url, questionTitle } = this.state;

    if ((!userReadyToBegin || isIos()) && !url) {
      return '';
    } else if (url && !recordingVideo) {
      return CHECK_IT_OUT;
    }
    return questionTitle;
  }

  onStop = () => this.setState({ isFinish: true });

  onFinish = (blob) => {
    this.handleStop(blob);
  }

  handleChange = (e) => {
    this.onFinish(e.target.files[0]);
  }

  renderActions = ({ onStopRecording }) => {
    const { secondsElapsed, minutesElapsed } = this.state;

    return (
      <div className="controls-wrapper">
        <ElapsedTimeBar seconds={secondsElapsed} minutes={minutesElapsed} />
        <Button
          className="video-buttons finish"
          icon={finishIcon}
          onPress={onStopRecording}
          text="Finish"
        />
      </div>
    );
  };

  renderRecorder = () => {
    const {
      isStart,
      isFinish,
      secondsElapsed,
      minutesElapsed
    } = this.state;

    // if (isIos()) {
      return (
        <VideoRecorder
          isStarted={isStart}
          secondsElapsed={secondsElapsed}
          minutesElapsed={minutesElapsed}
          renderActions={this.renderActions}
          onFinished={this.onFinish}
        />
      );
    // }

    // return (
    //   <VideoRTC
    //     isStarted={isStart}
    //     isFinished={isFinish}
    //     onFinished={this.onFinish}
    //     >
    //     <div className="controls-wrapper">
    //       <ElapsedTimeBar seconds={secondsElapsed} minutes={minutesElapsed} />
    //       <Button
    //         className="video-buttons finish"
    //         icon={finishIcon}
    //         onPress={this.onStop}
    //         text="Finish"
    //       />
    //     </div>
    //   </VideoRTC>
    // );
  };

  render() {
    const {
      questionsToRecordList,
      userReadyToBegin,
      countdownBegin,
      startCountdown,
      recordingVideo,
      loading
    } = this.props;
    const {
      url,
      questionTitle
    } = this.state;
    const hasMoreQuestions = questionsToRecordList.length > 1;
    const isIOS = isIos() && isMobileDevice();

    if (!questionTitle) {
      return <Redirect to={routes.questionMenu} />;
    }

    return (
      <div className="container video-page-box">
        <VideoTitle
          questionTitle={this.changeTitle()}
          userReady={userReadyToBegin}
          isRecording={recordingVideo}
        />
        {loading && !userReadyToBegin &&
          <div className="loading-center">
            <Loading color={variables.white} />
          </div>
        }
        {!loading && ((!isIOS && !userReadyToBegin) || (isIOS && !url)) && !countdownBegin && !url &&
          <ReadyToBegin
            questionTitle={questionTitle}
            isIos={isIOS}
            startCountdown={() => startCountdown(true)}
            startRecording={this.getStartedRecording}
            onChange={this.handleChange}
          />
        }
        {countdownBegin &&
          <div className="row">
            <div className="col-lg-12">
              <div className="box">
                <div className="circle circle1"></div>
                <div className="circle circle2"></div>
                <div className="niddle"></div>
                <div className="number">
                  <div>3</div>
                  <div>2</div>
                  <div>1</div>
                </div>
              </div>
            </div>
          </div>
        }

        {!url ?
          <div className={classNames({ 'video-blur': !userReadyToBegin}, 'record-wrapper')}>
            {!isIOS && this.renderRecorder()}
          </div>
          :
          <VideoPlayer url={url} onSaving={this.handleSaveVideo} onRecordAgain={this.reRecord} multiple={hasMoreQuestions} />
        }
      </div>
    );
  }
}

AnswerQuestionsPage.propTypes = {
  singleSelectedQuestion: object,
  userReadyToBegin: bool.isRequired,
  countdownBegin: bool.isRequired,
  userReady: func.isRequired,
  startCountdown: func.isRequired,
  streamPermission: func.isRequired,
  isRecordingVideo: func.isRequired,
  recordingVideo: bool.isRequired,
  loading: bool.isRequired,
  questionsToRecordList: array,
  questionToAnswerNumber: number,
  resetAnswerCounter: func,
  saveVideo: func,
  authenticated: bool.isRequired,
  matchVideoToUser: func.isRequired,
  questionAlreadyAnswered: bool,
  questionAnsweredInPostId: number,
  history: object.isRequired,
  toggleShareModal: func.isRequired,
  uploadVideo: func
};

const mapState = state => ({
  userReadyToBegin: state.getIn(['video', 'userReadyToBegin']),
  countdownBegin: state.getIn(['video', 'countdownBegin']),
  permissionGranted: state.getIn(['video', 'permissionGranted']),
  recordingVideo: state.getIn(['video', 'recordingVideo']),
  loading: state.getIn(['video', 'loading']),
  error: state.getIn(['video', 'error']),
  authenticated: state.getIn(['session', 'authenticated']),
  questionsToRecordList: state.getIn(['questions', 'questionsToRecordList']).toJS(),
  questionToAnswerNumber: state.getIn(['questions', 'questionToAnswerNumber']),
  singleSelectedQuestion: state.getIn(['questions', 'singleSelectedQuestion']).toJS(),
  questionAlreadyAnswered: state.getIn(['questions', 'questionAlreadyAnswered']),
  questionAnsweredInPostId: state.getIn(['questions', 'questionAnsweredInPostId'])
});

const mapDispatch = dispatch => ({
  userReady: isReady => dispatch(userReady(isReady)),
  startCountdown: countdown => dispatch(startCountdown(countdown)),
  streamPermission: permission => dispatch(streamPermission(permission)),
  isRecordingVideo: recording => dispatch(isRecordingVideo(recording)),
  loadingVideo: loading => dispatch(loadingVideo(loading)),
  increaseAnswerCounter: () => dispatch(increaseAnswerCounter()),
  saveVideo: (videoData, update, postId, isOrigin) => dispatch(saveVideo(videoData, update, postId, isOrigin)),
  addUserQuestion: (question, added) => dispatch(addUserQuestion(question, added)),
  addRemoveToRecord: (question, added) => dispatch(addRemoveToRecord(question, added)),
  resetAnswerCounter: () => dispatch(resetAnswerCounter()),
  matchVideoToUser: match => dispatch(matchVideoToUser(match)),
  pickOneQuestion: id => dispatch(pickOneQuestion(id)),
  toggleShareModal: () => dispatch(toggleShareModal()),
  uploadVideo: (blob, key, postId, isOrigin) => dispatch(uploadVideo(blob, key, postId, isOrigin))
});

export default connect(mapState, mapDispatch)(withHeader(AnswerQuestionsPage));
