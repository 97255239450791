import React, { Component, Fragment } from "react";
import WithHeader from "../components/common/WithHeader";

class OurStory extends Component {
    componentDidMount = () => window.scrollTo(0, 0);

    render() {
        return (
            <Fragment>
                <div className="ourStory">
                    <h3 className="ourStory-title">Our Story</h3>
                    <span className="center-blue-line"></span>
                    <div className="ourStory-content">
                        <p>Storytelling has been part of the human experience for tens of thousands of years, since we sat in caves uttering monosyllabic grunts as bedtime stories. It is the backbone of community, the sharing of the human experience.<br /><br />

                    Fast forward to recent times, a life-changing event occurred, keeping the storytelling tradition alive with a modern twist.<br /><br />

                    My dad died before my kids were born. They will never have a chance to know their grandfather in any meaningful way. There are photographs and diluted stories, a handful of clumsy smartphone videos that were saved. But there is nothing organized, nothing substantial, nothing about who my amazing dad was or how he lived in the world. Nothing close to a real glimpse of someone telling their own story about their life, with their voice, their facial expressions. Consequently, I became obsessed creating a way to help families share life stories and gather oral histories. Connecting generations, for generations.<br /><br />
                        </p>

                        <p className="elie-quote">“Without memory, there is no culture. Without memory, there would be no civilization, no society, no future.”<br />
                    -Elie Wiesel</p><br /><br />


                        <p>
                            Megilla was born as an affordable, easy, fun way to help families learn about each other in real time, and the future. We built Megilla to create an everlasting intergenerational bridge, and because video is the next best thing to being with someone in real life.<br /><br />

                        Honestly, most of us don’t really take the time to ask questions to those we love most. We’re busy, life happens. This very normal inertia speeds us forward. Often, we don’t think to sit down and ask “what did you and mom do on your first date?” or “tell me about that time you drove solo from Milwaukee to Seattle in the heart of winter.” Questions are the gateway to the stories of our lives. They’re the catalyst, the conversation starter, the vital bridge to learning about each other and connecting.<br /><br />

                        Megilla is here to help you and your families hear stories straight from the source, so you don’t have to go searching for them. It’s 2020, and fortunately we can sit at home in air-conditioned caves and comfortably share our lives with the click of a few buttons.<br /><br />

                        At Megilla, we believe the infinitely precious link between generations should never be lost.

                    </p>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default WithHeader(OurStory);
