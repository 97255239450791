import React, { Fragment } from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import WithHeader from "../components/common/WithHeader";

class Pricing extends React.Component {
    componentDidMount = () => window.scrollTo(0, 0);

    render() {
        const { authenticated } = this.props;
        return (
            <Fragment>
                <div className="pricing">
                    <h3>Pricing</h3>
                    <span className="center-blue-line"></span>
                    <div className="mt-5">
                        <div className="col-lg-9 col-md-9 mh-auto mt-5">
                            {/* <p className="text-center">For a limited time, we are offering a FREE 6-month membership to Megilla ($36 value) – <strong>No credit card required.</strong> We want everyone to experience the power of sharing life’s stories and connecting with friends and family. Complete the form below to join our growing community of storytellers! </p> */}
                            <p className="text-center">Free memberships</p>

                        </div>
                        {!authenticated && (
                            <Fragment>
                                <Link to='/sign-up'><p className="text-center font-weight-normal" style={{color: '#63b3ed'}}>SIGN UP TODAY</p></Link>
                            </Fragment>
                        )}
                        <div className="row plans">
                            {/* <div className="plan col-sm-5 col-xs-12 text-center d-flex align-items-center">
                                <p>$36 Annual Membership</p>
                            </div>
                            <div className="col-sm-2 col-xs-12 d-flex align-items-center justify-content-center">OR</div>
                            <div className="plan col-sm-5 col-xs-12 text-center d-flex align-items-center flex-column">
                                <p>Join Our Referral Program</p>
                                <p>Refer 3 people and receive a complimentary membership</p>
                            </div> */}
                            <div className="col-12 services mt-3">
                                What's included:
                                <ul>
                                    <li>Unlimited access to the Megilla platform for 6 months</li>
                                    <li>Record and share videos</li>
                                    <li>Send and create questions</li>
                                    <li>Download videos</li>
                                    <li>Unlimited storage</li>
                                    <li>No hidden fees</li>
                                    <li>Free customer support</li>
                                    <li>Free shipping (Kidding, we're an internet company)</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-9 col-md-6 text-center mh-auto mt-5">
                            <p>
                            Your videos are always private (until you choose to share them) and your data is secure on our servers. You have the option to download your videos at any time.
                            </p>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapState = state => ({
    authenticated: state.getIn(['session', 'authenticated']),
});

export default connect(mapState)(WithHeader(Pricing));
