import React, { Component } from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";

import user1 from "assets/firstImage.png";
import user2 from "assets/secondimage.png";
import user3 from "assets/thirdimage.png";
import testimonial1 from "assets/landingPage/testimonial1.png";
import laptop from "assets/landingPage/laptop.png";
import threeMinutes from "assets/landingPage/threeMinutes.png";
import testimonial2 from "assets/landingPage/testimonial2.png";

import WithHeader from "../components/common/WithHeader";

import heroImage from "../assets/landingPage/heroImage.png";
import heroImageResponsive from "../assets/landingPage/heroImageResponsive.png";
import playButton from "../assets/landingPage/playButton.png";
import downArrow from "../assets/landingPage/downArrow.png";
import how1 from "../assets/landingPage/how1.png";
import how2 from "../assets/landingPage/how2.png";
import how3 from "../assets/landingPage/how3.png";

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = { openModal: false, url: null };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.fbq('track', 'ViewContent', { content_name: 'Landing Page' });
  }

  onOpenModal = url => this.setState({ openModal: true, url });

  onCloseModal = () => {
    this.setState({ openModal: false });
  };

  render() {
    return (
      <div className="landing-page">
        {/* Hero */}
        <div className="lp-hero">
          <div className="lp-hero-left">
            <h3>
              The easiest <span className="blue-span-accent">video storytelling platform </span>for your family and community
            </h3>
            <p>
              We make it easy for you to tell & share life's stories through short videos -- one question at a time.
            </p>
            <Link to="/sign-up" className="lp-hero-button">
             Sign up for your FREE membership!
            </Link>
          </div>
          <div
            onClick={() =>
              this.onOpenModal("https://megilla-production-backend.s3.amazonaws.com/1/Megilla+--+FINAL+EXPLAINER+VIDEO+2020.mp4")}
          >
            <div className="modal-image">
              <img
                className="heroImage"
                onClick={() =>
                  this.openModal("https://megilla-production-backend.s3.amazonaws.com/1/Megilla+--+FINAL+EXPLAINER+VIDEO+2020.mp4")}
                src={heroImage}
              />
              <img
                className="heroImageResponsive"
                onClick={() =>
                  this.openModal("https://megilla-production-backend.s3.amazonaws.com/1/Megilla+--+FINAL+EXPLAINER+VIDEO+2020.mp4")}
                src={heroImageResponsive}
              />
              <img src={playButton} />
            </div>
          </div>
          <Modal
            style={{ height: 800, marginTop: 150, backgroundColor: "black" }}
            open={this.state.openModal}
            onClose={() => this.onCloseModal()}
            center
          >
            <ReactPlayer
              playing={true}
              url={this.state.url}
              width="100%"
              height="100%"
              controls={true}
            />
          </Modal>
        </div>

        {/* Down Arrow */}
        <div>
          <img className="down-arrow" src={downArrow}></img>
        </div>

        {/* How it works */}
        <div className="lp-how">
          <h3 className="lp-how-title">
            How <span className="blue-span-accent">megilla</span> works
          </h3>
          <span className="center-blue-line"></span>
          <div className="lp-how-container">
            <div>
              <img src={how1} />
              <h3>Pick a question to answer</h3>
              <p>
                Choose questions from a library of 750+ questions OR create your
                own.
              </p>
            </div>
            <div>
              <img src={how2} />
              <h3>Hit record and create your video</h3>
              <p>
                Use your laptop, desktop, smartphone or tablet to create videos
                with a few easy clicks.
              </p>
            </div>
            <div>
              <img src={how3} />
              <h3>Share with friends and family</h3>
              <p>
                Easily share your videos directly from the site or keep private
                in your account.
              </p>
            </div>
          </div>
        </div>

        {/* A great story */}
        <div className="lp-story">
          <span className="blue-line"></span>
          <h3 className="lp-story-title">
            A great story starts from a{" "}
            <span className="blue-span-accent">single question.</span>
          </h3>
          <p className="lp-story-description">
            By providing questions to answer, we make the process of telling
            your story easy, approachable and memorable.{" "}
          </p>
          <Link to="/question-menu" className="lp-story-button">
            View our Question Menu
          </Link>
          <div className="lp-story-videos-container">
            <div
              className="lp-video-container"
              onClick={() => this.onOpenModal("https://megilla-web-assets.s3.us-east-2.amazonaws.com/Megilla_Carol.mp4")}
            >
              <img className="video-thumbnail" src={user1} />
              <img src={playButton} />
              <p>Carol • 91</p>
              <p>Are there any aspects of aging that you didn't expect?</p>
            </div>

            <div
              className="lp-video-container"
              onClick={() =>
                this.onOpenModal("https://megilla-web-assets.s3.us-east-2.amazonaws.com/Megilla_Michael.mp4")}
            >
              <img className="video-thumbnail" src={user2} />
              <img src={playButton} />
              <p>Michael • 44</p>
              <p>What were you like as a child?</p>
            </div>

            <div
              className="lp-video-container"
              onClick={() =>
                this.onOpenModal("https://megilla-web-assets.s3.us-east-2.amazonaws.com/Megilla_Mom.mp4")}
            >
              <img className="video-thumbnail" src={user3} />
              <img src={playButton} />
              <p>Barbara • 71</p>
              <p>How did you meet your spouse?</p>
            </div>
          </div>
        </div>

        {/* Testimonial 1 */}
        <div className="testimonial">
          <div className="testimonial-left">
            <img src={testimonial1}></img>
          </div>
          <div className="testimonial-right">
            <div>
              <blockquote>
                I was nervous at first, then I got hooked. Time-traveling
                through my life was so much fun. I shared my videos with my
                daughter and grandkids and they loved my stories. Who knew I was
                such a storyteller?
              </blockquote>
              <div className="testimonial-name">
                <p>Kathy, 68,</p>
                <p>Los Angeles</p>
              </div>
            </div>
          </div>
        </div>

        {/* Create your own Question */}
        <div className="lp-create">
          <span className="center-blue-line"></span>
          <h3>
            <span className="blue-span-accent">Create your own questions</span>{" "}
            and send them to anyone you want.
          </h3>
          <p>
            Questions are the bridge that keep us connected. Ask more to know
            more.{" "}
          </p>
          <div>
            <img src={laptop}></img>
          </div>
        </div>

        {/* Every Video is 3 minutes or less */}
        <div className="lp-threeMinutes">
          <div className="lp-threeMinutes-container">
            <div>
              <span className="blue-line"></span>
              <h3>
                Every video is{" "}
                <span className="blue-span-accent">3 minutes</span> or less.
              </h3>
              <p>
                Short videos are easy to record, watch & share. 3 minutes may seem small but it lasts forever.
              </p>
              <Link to="/sign-up" className="lp-hero-button">
                Make your first video!
              </Link>
            </div>
            <div>
              <img src={threeMinutes}></img>
            </div>
          </div>
        </div>

        {/* Testimonial 2 */}
        <div className="testimonial">
          <div className="testimonial-left">
            <img src={testimonial2}></img>
          </div>
          <div className="testimonial-right">
            <div>
              <blockquote>
                Awesome bonding experience with my Grandma. Writing my own
                questions and sending videos back and forth made us so much
                closer. I also sent my parents tons of questions so now they
                have homework to do for once.
              </blockquote>
              <div className="testimonial-name">
                <p>Zoe, 16,</p>
                <p>Chicago</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default WithHeader(HomePage);
