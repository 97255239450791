import { List, Map, fromJS } from 'immutable';

import * as types from 'actions/actionTypes';
import { filterSelectedItems } from 'utils/helpers';
import { USER_QUESTION_STATES } from 'constants/constants';

export const initialState = Map({
  loading: false,
  questionsList: List([]),
  customQuestionsList: Map({}),
  selectedQuestions: List([]),
  singleSelectedQuestion: Map({}),
  questionAlreadyAnswered: false,
  questionAnsweredInPostId: null,
  questionsToRecordList: List([]),
  questionToAnswerNumber: 1,
  userQuestionsList: List([]),
});

const questionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USER_QUESTIONS:
    case types.FETCH_QUESTIONS: {
      return state.set('loading', true);
    }
    case types.UPDATE_USER_QUESTION_ERROR:
    case types.GET_QUESTIONS_RECORD_ERROR:
    case types.GET_USER_QUESTIONS_ERROR:
    case types.FETCH_QUESTIONS_ERROR: {
      const { error } = action;
      const newState = state.set('error', error);
      return newState.set('loading', false);
    }
    case types.REMOVE_QUESTIONS_ERROR: {
      return state.set('error', null);
    }
    case types.FETCH_QUESTIONS_SUCCESS: {
      const { questions } = action;
      const newState = state.set('loading', false);
      return newState.set('questionsList', fromJS(questions));
    }
    case types.FETCH_CATEGORY_SUCCESS: {
      const { category: { questions } } = action;
      return state.set('questionsList', fromJS(questions));
    }
    case types.FETCH_CUSTOM_QUESTIONS_SUCCESS: {
      const { questions } = action;
      const newState = state.set('loading', false);
      return newState.set('customQuestionsList', fromJS(questions));
    }
    case types.CHECK_QUESTION_ANSWER_SUCCESS: {
      const { answered, postId } = action;
      let newState = state.set('loading', false);
      newState = newState.set('questionAnsweredInPostId', postId);
      return newState.set('questionAlreadyAnswered', answered);
    }
    case types.PICK_ONE_QUESTION: {
      const { questionId, questionType } = action;
      const questions = questionType === 'origin'
        ? state.get('questionsList').toJS()
        : state.get('customQuestionsList').toJS().received;
      const selectedQuestion = state.get('singleSelectedQuestion');
      const question = (selectedQuestion.get('id') !== questionId) ?
        Map(questions.find(q => q.id === questionId)) :
        Map({});
      const newState = state.set('loading', false);
      return newState.set('singleSelectedQuestion', question);
    }
    case types.GET_QUESTIONS_RECORD_SUCCESS: {
      const { questions: { userQuestions } } = action;
      return state.set('questionsToRecordList', fromJS(userQuestions));
    }
    case types.GET_USER_QUESTIONS_SUCCESS: {
      const { questions } = action;
      let newState = state.set('loading', false);
      newState = newState.set('userQuestionsList', fromJS(questions));
      const newList = filterSelectedItems(newState.get('userQuestionsList'));
      return newState.set('questionsToRecordList', newList);
    }
    case types.ADD_REMOVE_RECORD: {
      const { added, userQuestion: { questionId: id }, userQuestion } = action;
      return added ?
        state.update('questionsToRecordList', list => list.filterNot(questionItem => questionItem.get('questionId') === id))
        :
        state.update('questionsToRecordList', list => list.push(fromJS(userQuestion)));
    }
    case types.ADD_USER_QUESTION_SUCCESS: {
      const { added, userQuestion: { questionId: id }, userQuestion } = action;
      let newState = state.set('loading', false);
      newState = added ?
        newState.update('userQuestionsList', list => list.filterNot(questionItem => questionItem.get('questionId') === id))
        :
        newState.update('userQuestionsList', list => list.push(fromJS(userQuestion)));
      const recordList = newState.get('questionsToRecordList');
      const hasPendingObjects = recordList.find(item => item.get('state') === USER_QUESTION_STATES.pending);
      if (!hasPendingObjects) {
        const newList = filterSelectedItems(newState.get('userQuestionsList'));
        return newState.set('questionsToRecordList', newList);
      }
      return newState;
    }
    case types.UPDATE_USER_QUESTION_SUCCESS: {
      const { userQuestion: { questionId: id, state: questionState } } = action;
      const newState = state.update('userQuestionsList', list => list.update(
        list.findIndex(item => item.get('questionId') === id),
        item => item.set('state', questionState)
      ));
      const newList = filterSelectedItems(newState.get('userQuestionsList'));
      return newState.set('questionsToRecordList', newList);
    }
    case types.INCREASE_QUESTION_NUMBER: {
      return state.update('questionToAnswerNumber', number => number + 1);
    }
    case types.RESET_QUESTION_NUMBER: {
      return state.set('questionToAnswerNumber', 1);
    }
    case types.EMPTY_QUESTIONS_TO_RECORD: {
      return state.set('questionsToRecordList', List([]));
    }
    case types.REMOVE_SINGLE_QUESTION: {
      return state.set('singleSelectedQuestion', Map({}));
    }
    case types.LOGOUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default questionsReducer;
