import React from 'react';
import ReactModal from 'react-modal';
import Button from './Button';

const UseChromeModal = ({ isOpen, onClose }) => (
    <ReactModal isOpen={isOpen} ariaHideApp={false} className="modal-opened position-relative remind-confirm-modal d-flex p-5">
      <div className="content text-left align-items-start flex-grow-1 justify-content-around">
        <h1 className="text-left">Thanks for joining!</h1>
        <p style={{fontSize: '2em'}}>
          {isOpen === 1 ?
          "If you wish to record videos on your laptop/desktop, please use a Chrome browser. You can use Safari or Chrome on your phone or tablet."
          : "If you wish to download videos, please use a Chrome browser."}
          <br />
          <a
            href="https://www.google.com/chrome/browser/"
            rel="noopener noreferrer"
            target="_blank"
            style={{fontSize: '1em', color: 'blue'}}
          >Download Google Chrome
          </a> for free to start telling your stories today.
        </p>
        <div className="actions" style={{marginLeft: 'auto'}}>
          <Button
            text="Close"
            className="btn-blue"
            onPress={onClose}
          />
        </div>
      </div>
    </ReactModal>
  );

export default UseChromeModal;
