import React, { Fragment } from 'react';
import { object, string, bool, number } from 'prop-types';
import cn from 'classnames';

const VideoTitle = ({ questionTitle, questionNumber, userReady, remainingQuestions, isRecording }) => (
  <div className="video-title-wrapper">
    {remainingQuestions >= 0 && userReady && isRecording ?
      <Fragment>
        {/* <div className="blue-circle">
          <span className="number">{questionNumber.number}</span>
        </div> */}
        <h1 className={cn('begin-title mb-0', { 'with-question': isRecording })}>{questionTitle}</h1>
        {/* <div className="remaining-container">
          <span className="remaining-number">{remainingQuestions}</span>
          <span>question(s) left</span>
        </div> */}
      </Fragment>
      :
      <h1 className={cn('begin-title mb-0', { 'with-question': isRecording })}>{questionTitle}</h1>
    }
  </div>
);

VideoTitle.propTypes = {
  questionTitle: string,
  questionNumber: object,
  userReady: bool,
  remainingQuestions: number,
  isRecording: bool
};

export default VideoTitle;
