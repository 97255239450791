import routes from './routesPaths';
import chooseImg from '../assets/icons/choosequestion-icon.svg';
import recordImg from '../assets/icons/recordvideos-icon.svg';
import shareImg from '../assets/icons/message-icon.svg';

// App common constants

export const DEFAULT_LANGUAGE = 'en';

// Navbar items

export const notAuthenticatedNavItems = [
  {
    name: "Start Your Story",
    link: routes.signUp
  },
  {
    name: "Question Menu",
    link: routes.questionMenu
  },
  {
    name: "Pricing",
    link: routes.pricing
  },
  {
    name: "Our Story",
    link: routes.ourStory
  },
  {
    name: 'How-To Videos',
    link: routes.howtovideo
  },
  {
    name: "Login",
    link: routes.login
  }

];

export const authenticatedNavItems = [
  {
    name: 'Question Menu',
    link: routes.questionMenu
  },
  {
    name: 'My Questions',
    link: routes.myQuestions
  },
  {
    name: 'My Videos',
    link: routes.myvideolist
  },
  {
    name: 'How-To Videos',
    link: routes.howtovideo
  }
];

export const megillaSteps = [
  {
    title: 'Choose Questions',
    description: 'to answer from a library of 500+ questions',
    className: 'step record',
    image: chooseImg
  },
  {
    title: 'Record Videos',
    description: 'by answering questions on your device with a few easy clicks',
    className: 'step choose',
    image: recordImg
  },
  {
    title: 'Email Videos',
    description: 'directly from the site or keep private in your account',
    className: 'step share',
    image: shareImg
  }
];

// Pick question constants

export const REPLACE_QUESTION_TEXT = 'This question is already answered. Are you sure you want to replace it?';

// Dashboard constants

export const NO_VIDEO_TEXT = 'Looks like you haven’t recorded any videos yet!';
export const CONFIRM_DELETE_TEXT = 'Are you sure you want to delete the selected video(s)?';

// Question menu constants

export const FIRST_LEVEL_CATEGORY = 'FIRST';
export const SECOND_LEVEL_CATEGORY = 'SECOND';
export const THIRD_LEVEL_CATEGORY = 'THIRD';
export const QUESTION_MENU = 'Question Menu';

// Modal constants

export const SOMETHING_WENT_WRONG = 'Something went wrong!';
export const ERROR_SAVING_VIDEO = 'We couldn’t save the video. Please try again in a few seconds.';
export const ERROR_DELETING_VIDEO = 'We couldn’t delete the video(s). Please try again in a few seconds.';
export const SUCCESS_SHARED_VIDEO = 'Your video has been shared!';
export const DESCRIPTION_SUCCESS_SHARED_VIDEO = 'Thanks for sharing.';

// Toaster constants

export const VIDEO_UPLOADING_TEXT = "Your video is uploading -- it may take a minute. Please do not leave the page. Once uploaded, you can see your videos in 'My Videos'. Thanks!";
export const VIDEO_UPLOADING_ERROR_TEXT = 'An error ocurred uploading the video. Try again.';
export const VIDEO_UPLOADING_SUCCESS_TEXT = 'Video(s) uploaded successfully!';

// Sign up constants

export const REGISTER_TEXT = 'Register';
export const SIGN_UP_TEXT = 'Sign up';

// Video constants

export const BEGIN_VIDEO_TITLE = 'Ready to begin?';
export const CHECK_IT_OUT = 'Check it out!';
export const VIDEO_WIDTH = 1280;
export const VIDEO_HEIGHT = 720;
export const MAX_MIN_DURATION_VIDEO = 3;
export const NO_RECORD_FOUND = "Couldn't find the record";

export const VIDEO = {
  video: {
    width: VIDEO_WIDTH,
    height: VIDEO_HEIGHT
  }
};

export const VIDEO_SETTINGS = {
  audio: false,
  ...VIDEO
};

export const RECORDING_SETTINGS = {
  audio: true,
  ...VIDEO
};

export const VIDEO_STATES = {
  uploading: 'uploading',
  saved: 'saved'
};

export const USER_QUESTION_STATES = {
  selected: 'selected',
  pending: 'pending'
};

export const VIDEOS_QUEUE_POSITIONS = [
  { text: 'First', number: 1 },
  { text: 'Second', number: 2 },
  { text: 'Third', number: 3 },
  { text: 'Fourth', number: 4 },
  { text: 'Fifth', number: 5 },
  { text: 'Sixth', number: 6 },
  { text: 'Seventh', number: 7 },
  { text: 'Eigth', number: 8 },
  { text: 'Nineth', number: 9 },
  { text: 'Tenth', number: 10 },
];

export const S3_CONFIG = {
  bucketName: process.env.AWS_BUCKET_BACKEND,
  region: process.env.AWS_BUCKET_REGION,
  accessKeyId: process.env.AWS_ACCESS_KEY_ID_BACKEND,
  secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY_BACKEND
};

export const TOAST_CONFIG = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true
};

export const TOAST_CONFIG_INFO = {
  position: 'top-right',
  autoClose: 10000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true
};

export const WEBRTC_CAMERA_OPTIONS = {
  audio: true,
  video: {
    width: {
      ideal: VIDEO_WIDTH,
    },
    height: {
      ideal: VIDEO_HEIGHT,
    },
    frameRate: {
      ideal: 30,
    }
  }
};
