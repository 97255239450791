import React, { Component, Fragment } from 'react';
import { array, func, number, bool } from 'prop-types';
import classNames from 'classnames';
import { scroller, Element } from 'react-scroll';
import { FIRST_LEVEL_CATEGORY, SECOND_LEVEL_CATEGORY, THIRD_LEVEL_CATEGORY } from 'constants/constants';
import Loading from 'components/common/Loading';
import history from 'utils/history';
import routes from 'constants/routesPaths';
import CategoryItem from './CategoryItem';

class QuestionMenuSidebar extends Component {
  constructor(props) {
    super(props);

    const { selectedSecondLevelCategory, selectedFirstLevelCategory, selectedThirdLevelCategory } = this.props;

    this.state = {
      lastCategorySelected: selectedThirdLevelCategory || selectedSecondLevelCategory || selectedFirstLevelCategory
    };
  }

  componentDidUpdate() {
    const { lastCategorySelected } = this.state;
    const { selectedSecondLevelCategory, selectedFirstLevelCategory, selectedThirdLevelCategory } = this.props;
    const newSelected = selectedThirdLevelCategory || selectedSecondLevelCategory || selectedFirstLevelCategory;
    if (lastCategorySelected !== newSelected) {
      this.scroll();
      this.setState({ lastCategorySelected: newSelected });
    }
  }

  componentWillUnmount() {
    const { onRemoveSelectedCategories } = this.props;
    const { location: { pathname } } = history;
    ![routes.login, routes.answerQuestions].includes(pathname) && onRemoveSelectedCategories();
  }

  scroll = () => {
    scroller.scrollTo('section', {
      duration: 1000,
      delay: 50,
      smooth: false,
      offset: -65
    });
  }

  render() {
    const {
      loading,
      categoriesList,
      selectedFirstLevelCategory,
      selectedSecondLevelCategory,
      selectedThirdLevelCategory,
      onSelectedLevelCategory
    } = this.props;
    const { lastCategorySelected } = this.state;

    return (
      <Fragment>
        <Element name="section" />
        <nav className="question-menu-sidebar-container question-menu-sidebar-hidden">
          <h2 className="title">Question Menu</h2>
          {loading ?
            <Loading className="mx-auto my-4" />
            :
            <div className="nav flex-column">
              {categoriesList.map(({ id: firstCategoryId, name: firstCategoryName, icon, bwIcon, subcategories: secondLevelCategories }) => {
                const isSelectedCategory = selectedFirstLevelCategory === firstCategoryId;
                const hasSubcategories = !!secondLevelCategories.length;
                return (
                  <div key={firstCategoryId}>
                    <CategoryItem
                      categoryName={firstCategoryName}
                      categoryLevel={FIRST_LEVEL_CATEGORY}
                      hasSubcategories={hasSubcategories}
                      isSelectedCategory={isSelectedCategory}
                      icon={bwIcon}
                      selectedIcon={icon}
                      className={classNames({ selected: firstCategoryId === lastCategorySelected })}
                      onSelectLevel={(event) => {
                        event.stopPropagation();
                        onSelectedLevelCategory(
                          firstCategoryId,
                          firstCategoryName,
                          FIRST_LEVEL_CATEGORY,
                          hasSubcategories,
                          event
                        );
                      }
                      }
                    />
                    {isSelectedCategory &&
                      secondLevelCategories.map(({ id: secondCategoryId, name: secondCategoryName, subcategories: thirdLevelCategories }) => {
                        const isSelectedCategory = selectedSecondLevelCategory === secondCategoryId;
                        const hasSubcategories = !!thirdLevelCategories.length;
                        return (
                          <div key={secondCategoryId}>
                            <CategoryItem
                              categoryName={secondCategoryName}
                              categoryLevel={SECOND_LEVEL_CATEGORY}
                              hasSubcategories={hasSubcategories}
                              isSelectedCategory={isSelectedCategory}
                              className={classNames({ selected: secondCategoryId === lastCategorySelected })}
                              onSelectLevel={(event) => {
                                event.stopPropagation();
                                onSelectedLevelCategory(
                                  secondCategoryId,
                                  secondCategoryName,
                                  SECOND_LEVEL_CATEGORY,
                                  hasSubcategories,
                                  event
                                );
                              }
                              }
                            />
                            {isSelectedCategory &&
                              thirdLevelCategories.map(({ id: thirdCategoryId, name: thirdCategoryName }) => {
                                const isSelectedCategory = selectedThirdLevelCategory === thirdCategoryId;
                                return (
                                  <CategoryItem
                                    key={thirdCategoryId}
                                    categoryName={thirdCategoryName}
                                    categoryLevel={THIRD_LEVEL_CATEGORY}
                                    isSelectedCategory={isSelectedCategory}
                                    className={classNames({ selected: thirdCategoryId === lastCategorySelected })}
                                    onSelectLevel={() =>
                                      onSelectedLevelCategory(
                                        thirdCategoryId,
                                        thirdCategoryName,
                                        THIRD_LEVEL_CATEGORY
                                      )
                                    }
                                  />
                                );
                              })
                            }
                          </div>
                        );
                      })
                    }
                  </div>
                );
              })}
            </div>
          }
        </nav>
      </Fragment>
    );
  }
}

QuestionMenuSidebar.propTypes = {
  onSelectedLevelCategory: func.isRequired,
  onRemoveSelectedCategories: func.isRequired,
  loading: bool,
  categoriesList: array,
  selectedFirstLevelCategory: number,
  selectedSecondLevelCategory: number,
  selectedThirdLevelCategory: number
};

export default QuestionMenuSidebar;
