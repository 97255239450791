import React, { Component } from 'react';
import { string, func, bool } from 'prop-types';
import cn from 'classnames';
import ReactModal from 'react-modal';
import errorImg from 'assets/images/error-img.svg';
import successImg from 'assets/images/confirmation-img.svg';
import Button from './Button';

class Modal extends Component {
  constructor(props) {
    super(props);

    this.state = { showModal: true };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    const { onDismiss } = this.props;
    this.setState({ showModal: false });
    onDismiss();
  }

  render() {
    const { title, description, isError } = this.props;
    const { showModal } = this.state;

    return (
      <ReactModal isOpen={showModal} ariaHideApp={false} className="modal-opened">
        <div className="m-auto">
          <img src={isError ? errorImg : successImg} alt="error" />
          <h1 className={cn('title', { error: isError })}>{title}</h1>
          <p className="description">{description}</p>
          <Button
            text="Close"
            className={cn('btn-modal', { 'btn-error': isError, 'btn-blue': !isError })}
            onPress={this.handleCloseModal}
          />
        </div>
      </ReactModal>
    );
  }
}

Modal.propTypes = {
  title: string.isRequired,
  onDismiss: func.isRequired,
  description: string,
  isError: bool
};

export default Modal;
