import React, { PureComponent } from 'react';
import { func, string, bool } from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import { Link } from 'react-router-dom';
import {
  injectIntl,
  intlShape,
  defineMessages,
  FormattedMessage
} from 'react-intl';

import Loading from 'components/common/Loading';
import Input from 'components/common/Input';
import Button from 'components/common/Button';
import { validations, forgotPassword } from 'utils/constraints';

const messages = defineMessages({
  email: { id: 'login.form.email' },
});

export class ForgotPasswordForm extends PureComponent {
  render() {
    const { handleSubmit, error, submitting, onClose } = this.props;

    return (
      <div className="login-form-container">
        <div className="">
          <div className="login-card">
            <FormattedMessage id="forgotPassword.title">
              {txt => (<h2>{txt}</h2>)}
            </FormattedMessage>
            <hr />
            <form onSubmit={handleSubmit} className="form-wrapper">
              {error && <span className="error-message">{error}</span>}
              <div className="input-container w-75">
                <p className="form-field-name">Email</p>
                <Field
                  name="email"
                  placeholder="Enter your email address"
                  component={Input}
                  type="email"
                  autoComplete="email"
                />
              </div>
              <div className="d-flex justify-content-between w-75">
                <Button
                  text="< Back to login"
                  className="link"
                  onPress={onClose}
                />
                <Button text="Send reset link" className="btn-blue w-50" type="submit" />
              </div>
              {submitting && <Loading />}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

ForgotPasswordForm.propTypes = {
  handleSubmit: func.isRequired,
  // intl: intlShape.isRequired,
  submitting: bool.isRequired,
  error: string
};

export default reduxForm({
  form: 'forgotPassword',
  validate: validations(forgotPassword, { fullMessages: false })
})(injectIntl(ForgotPasswordForm));
