import api from './apiService';

class Videos {
  static saveVideo(video) {
    return api.post('/posts', video);
  }

  static fetchVideo(postId) {
    return api.get(`/posts/${postId}`);
  }

  static fetchVideos() {
    return api.get('/posts');
  }

  static updateVideo(video, postId) {
    return api.put(`/posts/${postId}`, video);
  }

  static deleteVideo(videos) {
    return api.delete('/posts', videos);
  }

  static videosState(videos) {
    return api.get(`/posts?ids=${videos}`);
  }

  static shareVideos(videos) {
    return api.put('/share_videos', videos);
  }

  static saveCustomVideo(video) {
    return api.post('/custom_videos', video);
  }

  static fetchCustomVideo(postId) {
    return api.get(`/custom_videos/${postId}`);
  }

  static fetchCustomVideos() {
    return api.get('/custom_videos');
  }

  static updateCustomVideo(video, postId) {
    return api.put(`/custom_videos/${postId}`, video);
  }

  static deleteCustomVideo(videos) {
    return api.delete('/custom_videos', videos);
  }

  static customVideosState(videos) {
    return api.get(`/custom_videos?ids=${videos}`);
  }

  static shareCustomVideos(videos) {
    return api.put('/share_custom_videos', videos);
  }


  static uploadBlob(blob) {
    return api.uploadBlob('/upload_video', blob)
  }
}

export default Videos;
