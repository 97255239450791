import { List, Map, fromJS } from 'immutable';
import * as types from 'actions/actionTypes';
import { VIDEO_STATES, VIDEO_UPLOADING_SUCCESS_TEXT, TOAST_CONFIG } from 'constants/constants';
import { toast } from 'react-toastify';

export const initialState = Map({
  loading: true,
  permissionGranted: false,
  userReadyToBegin: false,
  countdownBegin: false,
  recordingVideo: false,
  isMatchedVideo: true,
  error: null,
  video: Map({}),
  videosList: List([]),
  customVideosList: List([]),
  selectedVideos: List([]),
  shareModal: false,
  uploadingVideos: false,
  uploadingCustomVideos: false,
  openUseChromeModal: 0,
});

const videoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_USE_CHROME_MODAL: {
      const currentStatus = action.payload;
      return state.set('openUseChromeModal', currentStatus);
    }
    case types.READY_TO_BEGIN_VIDEO: {
      const { isReady } = action;
      const newState = state.set('error', null);
      return newState.set('userReadyToBegin', isReady);
    }
    case types.START_COUNTDOWN: {
      const { countdown } = action;
      const newState = state.set('error', null);
      return newState.set('countdownBegin', countdown);
    }
    case types.PERMISSION_VIDEO: {
      const { permission } = action;
      const newState = state.set('loading', false);
      return newState.set('permissionGranted', permission);
    }
    case types.RECORDING_VIDEO: {
      const { recording } = action;
      return state.set('recordingVideo', recording);
    }
    case types.LOADING_VIDEO: {
      const { loading } = action;
      return state.set('loading', loading);
    }
    case types.SHARE_VIDEOS:
    case types.SAVE_VIDEO: {
      return state.set('loading', true);
    }
    case types.SHARE_VIDEOS_ERROR:
    case types.SAVE_VIDEO_ERROR: {
      const { error } = action;
      const newState = state.set('loading', false);
      return newState.set('error', error);
    }
    case types.SHARE_VIDEOS_SUCCESS: {
      let newState = state.set('loading', false);
      newState = newState.set('error', null);
      newState = newState.set('selectedVideos', List([]));
      return newState.set('shareModal', false);
    }
    case types.SAVE_VIDEO_SUCCESS: {
      return state.set('loading', false);
    }
    case types.MATCH_VIDEO_TO_USER: {
      const { match } = action;
      return state.set('isMatchedVideo', match);
    }
    case types.REMOVE_VIDEO_ERROR: {
      return state.set('error', null);
    }
    case types.FETCH_VIDEO:
    case types.FETCH_VIDEOS:
    case types.DELETE_VIDEOS: {
      return state.set('loading', true);
    }
    case types.FETCH_VIDEO_ERROR:
    case types.FETCH_VIDEOS_ERROR:
    case types.FETCH_CUSTOM_VIDEOS_ERROR:
    case types.FETCH_CUSTOM_VIDEO_ERROR: {
      const { error } = action;
      const newState = state.set('error', error);
      return newState.set('loading', false);
    }
    case types.DELETE_VIDEOS_SUCCESS: {
      const { deletedVideos: { postIds: videos } } = action;
      const videosList = state.get('videosList').toJS();
      const newVideosList = videosList.filter(({ id }) => !videos.includes(id));
      let newState = state.set('loading', false);
      newState = newState.set('videosList', List(fromJS(newVideosList)));
      return newState.set('selectedVideos', List([]));
    }
    case types.DELETE_VIDEOS_ERROR: {
      const { error } = action;
      const newState = state.set('loading', false);
      return newState.set('error', error);
    }
    case types.FETCH_VIDEOS_SUCCESS: {
      const { videos: { posts } } = action;
      let newState = state.set('loading', false);
      const uploadingList = posts.filter(({ state }) => state === VIDEO_STATES.uploading);
      newState = newState.set('uploadingVideos', uploadingList.length > 0);
      return newState.set('videosList', fromJS(posts));
    }
    case types.FETCH_CUSTOM_VIDEOS_SUCCESS: {
      const { videos: { customVideos } } = action;
      let newState = state.set('loading', false);
      const uploadingList = customVideos.filter(({ state }) => state === VIDEO_STATES.uploading);
      newState = newState.set('uploadingCustomVideos', uploadingList.length > 0);
      return newState.set('customVideosList', fromJS(customVideos));
    }
    case types.FETCH_VIDEO_SUCCESS: {
      const { video } = action;
      const newState = state.set('loading', false);
      return newState.set('video', fromJS(video));
    }
    case types.SELECT_OR_UNSELECT_VIDEO: {
      const { video, video: { id: videoId } } = action;
      const isSelected = !!state.get('selectedVideos').find(({ id }) => id === videoId);
      return isSelected ?
        state.update('selectedVideos', list => list.filter(({ id }) => id !== videoId)) :
        state.update('selectedVideos', list => list.push(video));
    }
    case types.UNSELECT_ALL_VIDEOS: {
      return state.set('selectedVideos', List([]));
    }
    case types.TOGGLE_SHARE_MODAL: {
      const shareModal = state.get('shareModal');
      return state.set('shareModal', !shareModal);
    }
    case types.CHECK_UPLOADING_VIDEOS_SUCCESS: {
      const { videos: { posts } } = action;
      let uploadingVideo = false;

      const newState = state.update('videosList', list => list.map((video) => {
        const newVideo = posts.find(({ state: postState, id: postId }) => {
          postState === VIDEO_STATES.uploading && (uploadingVideo = true);
          try {
            if (video.get('id')) {
              return postState === VIDEO_STATES.saved && postId === video.get('id');
            }
          } catch (err) {
            return postState === VIDEO_STATES.saved && postId === video.id;
          }
        });
        if (newVideo && !uploadingVideo) {
          toast.dismiss();
          toast.success(VIDEO_UPLOADING_SUCCESS_TEXT, TOAST_CONFIG);
          return newVideo;
        }
        return video;
      }));

      return newState.set('uploadingVideos', uploadingVideo);
    }
    case types.CHECK_UPLOADING_CUSTOM_VIDEOS_SUCCESS: {
      const { videos: { customVideos } } = action;
      let uploadingVideo = false;

      const newState = state.update('customVideosList', list => list.map((video) => {
        const newVideo = customVideos.find(({ state: postState, id: postId }) => {
          postState === VIDEO_STATES.uploading && (uploadingVideo = true);
          try {
            if (video.get('id')) {
              return postState === VIDEO_STATES.saved && postId === video.get('id');
            }
          } catch (err) {
            return postState === VIDEO_STATES.saved && postId === video.id;
          }
        });
        if (newVideo && !uploadingVideo) {
          toast.dismiss();
          toast.success(VIDEO_UPLOADING_SUCCESS_TEXT, TOAST_CONFIG);
          return newVideo;
        }
        return video;
      }));

      return newState.set('uploadingCustomVideos', uploadingVideo);
    }
    case types.PICK_ONE_QUESTION: {
      return state.set('loading', false);
    }
    case types.LOGOUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default videoReducer;
