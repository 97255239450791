import React, { Component } from 'react';
import { object, bool, func, string } from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import isEmpty from 'lodash/isEmpty';
import routes from 'constants/routesPaths';
import withHeader from 'components/common/WithHeader';
import Loading from 'components/common/Loading';
import Button from 'components/common/Button';
import VideoPlayer from 'components/video/VideoPlayer';
import ShareModal from 'components/common/ShareModal';
import DeleteVideoModal from 'components/common/DeleteVideoModal';
import {
  fetchVideo,
  toggleShareModal,
  selectOrUnselectVideo,
  unselectVideos,
  shareVideos,
  deleteVideos,
  toggleUseChromeModal
} from 'actions/videoActions';
import shareVideoIcon from 'assets/icons/share-video.svg';
import downloadVideoIcon from 'assets/icons/download-video.png';
import { formatDate, downloadVideo, isSafari } from 'utils/helpers';
import { NO_RECORD_FOUND } from 'constants/constants';
import { removeVideoError } from '../actions/videoActions';

class PlayVideoPage extends Component {
  state = {
    openDeleteModal: false,
  };

  componentDidMount() {
    const { match: { params: { postId } }, location: { search }, fetchVideo } = this.props;
    const path = search ? postId.concat(search) : postId;
    fetchVideo(path);
  }

  componentWillUnmount() {
    const { removeVideoError } = this.props;
    removeVideoError();
  }

  onShareVideos = (videos = null) => {
    const { toggleShareModal, shareVideos } = this.props;
    if (videos) {
      videos.forEach(video => 
        ReactGA.event({
          category: 'Video',
          action: 'Shared a Video',
          value: video.question
        }));
      return shareVideos(videos);
    }
    return toggleShareModal();
  };

  handleShareVideo = () => {
    const { video, unselectVideos, selectOrUnselectVideo, toggleShareModal } = this.props;
    if (!isEmpty(video)) {
      unselectVideos();
      selectOrUnselectVideo(video);
      toggleShareModal();
    }
  }

  handleDownload = () => {
    if (isSafari()) {
      this.props.toggleUseChromeModal(2);
    } else {
      const { video } = this.props;
      if (!isEmpty(video)) {
        const { videoMp4, video: orgVideo, question, userName } = video;
        downloadVideo(videoMp4 || orgVideo, `${question} (${userName}).mp4`);
      }
    }
  }

  handleDeleteVideo = () => {
    const {
      match: { params: { postId } },
      history,
      deleteVideos
    } = this.props;

    if (postId) {
      deleteVideos({postIds: [parseInt(postId, 10)]}, true);
      history.push(routes.myvideolist);
    }
    this.setState({ openDeleteModal: false });
  };

  toggleDeleteModal = status => () => {
    this.setState({ openDeleteModal: status });
  };

  render() {
    const {
      match: { params: { postId } },
      video: { videoMp4, video: videoWebm, question, color, updatedAt, thumbnail, rootCategory },
      loading,
      history,
      authenticated,
      error,
      selectedVideos,
      selectOrUnselectVideo,
      shareModal,
      toggleShareModal
    } = this.props;
    const { openDeleteModal } = this.state;
    const formattedDate = updatedAt ? formatDate(updatedAt) : '';

    if (!postId || error === NO_RECORD_FOUND) {
      const route = !postId || !authenticated ? routes.index : routes.myvideolist;
      return <Redirect to={route} />;
    }

    return (
      <div className="play-video-container">
        {loading ?
          <Loading />
          :
          <div className="container" style={{width: 'fit-content'}}>
            {authenticated &&
              <div className="row btn-return justify-content-start">
                <Link to={routes.myvideolist}>
                  {`< Return to My Videos`}
                </Link>
              </div>
            }
            <VideoPlayer url={videoMp4 || videoWebm} thumbnail={thumbnail} viewOnly />
            <div className="row justify-content-between flex-wrap m-0">
              <div className="col-lg-6 col-md-8 col-sm-12 p-0 mb-3">
                <h2 className="m-0">{question}</h2>
                <Button
                  className="btn-share-video d-flex align-items-center justify-content-center"
                  iconClassName="video-icon mx-3"
                  text="Share Video"
                  icon={shareVideoIcon}
                  onPress={this.handleShareVideo}
                />
                <Button
                  className="btn-share-video d-flex align-items-center justify-content-center"
                  iconClassName="video-icon mx-3"
                  text="Download Video"
                  iconStyle={{ width: 25, height: 19 }}
                  icon={downloadVideoIcon}
                  onPress={this.handleDownload}
                />
              </div>
              <div className="d-flex flex-column">
                <p className="text-right">{formattedDate}</p>                
                <span className="category" style={{ background: color || '#2D3748' }}>
                  {(rootCategory !== 'custom_video' && rootCategory) || 'Custom Question' }
                </span>
                <Button className="btn-delete-video" text="Delete" onPress={this.toggleDeleteModal(true)} />
              </div>
            </div>
            {shareModal &&
              <ShareModal
                loading={loading}
                shareModal={shareModal}
                handleShare={this.onShareVideos}
                toggleShareModal={toggleShareModal}
                shareableVideos={selectedVideos}
              />
            }
            {openDeleteModal &&
              <DeleteVideoModal
                question={question}
                isOpen={openDeleteModal}
                onConfirm={this.handleDeleteVideo}
                onClose={this.toggleDeleteModal(false)}
              />
            }
          </div>
        }
      </div>
    );
  }
}

PlayVideoPage.propTypes = {
  fetchVideo: func.isRequired,
  match: object.isRequired,
  history: object.isRequired,
  authenticated: bool.isRequired,
  location: object.isRequired,
  removeVideoError: func.isRequired,
  video: object,
  loading: bool,
  error: string
};

const mapState = state => ({
  video: state.getIn(['video', 'video']).toJS(),
  loading: state.getIn(['video', 'loading']),
  authenticated: state.getIn(['session', 'authenticated']),
  error: state.getIn(['video', 'error']),
  shareModal: state.getIn(['video', 'shareModal']),
  selectedVideos: state.getIn(['video', 'selectedVideos']).toJS(),
});

const mapDispatch = ({
  fetchVideo,
  removeVideoError,
  unselectVideos,
  selectOrUnselectVideo,
  toggleShareModal,
  shareVideos,
  deleteVideos,
  toggleUseChromeModal
});

export default connect(mapState, mapDispatch)(withHeader(PlayVideoPage));
