import React from 'react';
import { number, func, bool } from 'prop-types';
import cn from 'classnames';
import volumeIcon from 'assets/icons/volumeIcon.svg';
import muteIcon from 'assets/icons/mute.svg';
import Button from '../common/Button';

const SliderVolume = ({
  volume,
  onChangeFunction,
  onMute,
  lastQuestion
}) => {
  const isMuted = volume === 0;
  return (
    <div className="button-icons">
      <div className={cn('volume volume-slider', { 'last-question': lastQuestion })}>
        <input
          className={cn('volume', { muted: isMuted })}
          type="range"
          min="0"
          max="1"
          step="any"
          value={volume}
          onChange={onChangeFunction}
        />
      </div>
      <Button
        className={cn('pl-0 volume-icon', { 'mute-icon': isMuted, 'last-question': lastQuestion })}
        onPress={onMute}
        icon={isMuted ? muteIcon : volumeIcon}
        onlyIcon
      />
    </div>
  );
};

SliderVolume.propTypes = {
  volume: number,
  onChangeFunction: func.isRequired,
  onMute: func.isRequired,
  lastQuestion: bool
};

export default SliderVolume;
