import React from 'react';
import ReactModal from 'react-modal';
import Button from './Button';

const DeleteVideoModal = ({ question, isOpen, onConfirm, onClose }) => (
    <ReactModal isOpen={isOpen} ariaHideApp={false} className="modal-opened remind-modal">
      <div className="content">
        <h1 className="modal-title mx-auto">Are you sure you want to delete this video?</h1>
        <p className="question">{question}</p>
        <div className="actions">
          <Button
            text="Yes, delete this video"
            className="btn-green"
            onPress={onConfirm}
          />
          <Button
            text="Cancel"
            className="link"
            onPress={onClose}
          />          
        </div>
      </div>
    </ReactModal>
  );

export default DeleteVideoModal;
