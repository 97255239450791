import React from 'react';
import searchIcon from 'assets/icons/search-icon.svg';

const SearchBar = () => (
  <form className="input form-inline ml-4">
    <div className="input-group">
      <div className="input-group-prepend">
        <img className="icon" src={searchIcon} alt="Search" />
      </div>
      <input type="text" className="form-control" placeholder="Search" />
    </div>
  </form>
);

export default SearchBar;
