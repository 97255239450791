import React from 'react';

import withHeader from 'components/common/WithHeader';
import PickQuestions from 'components/questions/PickQuestions';

const QuestionsPage = props => (
  <div className="container-fluid row justify-content-center">
    <PickQuestions numberOfQuestions={7} {...props} />
  </div>
);

export default withHeader(QuestionsPage);
