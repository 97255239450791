import { createStore, compose, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import ReactGA from 'react-ga';

import rootReducer from 'reducers';
import history from 'utils/history';

history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

export default function configureStore(initialState) {
  const middewares = [
    thunkMiddleware,
    routerMiddleware(history)
  ];

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middewares))
  );
}
