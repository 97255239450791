import React from 'react';
import Modal from 'react-modal';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const modalStyles = {
    content: {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        maxWidth             : 600
    }
};
  
const styles = {
    okButton: {
        backgroundColor: 'blue',
        width: 75,
        height: 30,
        color: '#FFF',
        fontSize: 14,
        borderRadius: 10,
        float: 'right'
    },
    downloadLink: {
        fontSize: 16,
        color: 'blue',
    }
};

Modal.setAppElement('#app');

const isChromium = window.chrome;
const winNav = window.navigator;
const vendorName = winNav.vendor;
const isOpera = typeof window.opr !== "undefined";
const isIEedge = winNav.userAgent.indexOf("Edge") > -1;
const isIOSChrome = winNav.userAgent.match("CriOS");
cookies.set('shownModal', false, { path: '/' });

const isChrome = () => (
    isIOSChrome || (isChromium !== null &&
        typeof isChromium !== "undefined" &&
        vendorName === "Google Inc." &&
        isOpera === false &&
        isIEedge === false
    )
);
  
const InfoModal = () => {
    const hasShownInfoModal = cookies.get('hasShownInfoModal')
    const [modalOpen, setModalOpen] = React.useState(isChrome && !hasShownInfoModal);
  
    const closeModal = () => {
      setModalOpen(false);
      cookies.set('hasShownInfoModal', true, { path: '/' });
    }
  
    return (
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        style={modalStyles}
      >
        <h2>Thanks for joining!</h2>
        <p>Megilla operates on Google Chrome browser. Please use the link below to download Google Chrome for free to start telling your stories today.</p>
        <p><a href='https://cloud.google.com/chrome-enterprise/browser/' target='blank' style={styles.downloadLink}>Download Google Chrome Browser</a></p>
        <button style={styles.okButton} onClick={closeModal}>OK</button>
      </Modal>
    );
}

export default InfoModal;