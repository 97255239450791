// ACTION TYPES

export const LOGOUT = 'LOGOUT';

// QUESTIONS
export const FETCH_QUESTIONS = 'FETCH_QUESTIONS';
export const FETCH_QUESTIONS_SUCCESS = 'FETCH_QUESTIONS_SUCCESS';
export const FETCH_CUSTOM_QUESTIONS_SUCCESS = 'FETCH_CUSTOM_QUESTIONS_SUCCESS';
export const FETCH_QUESTIONS_ERROR = 'FETCH_QUESTIONS_ERROR';
export const CHECK_QUESTION_ANSWER = 'CHECK_QUESTION_ANSWER';
export const CHECK_QUESTION_ANSWER_SUCCESS = 'CHECK_QUESTION_ANSWER_SUCCESS';
export const CHECK_QUESTION_ANSWER_ERROR = 'CHECK_QUESTION_ANSWER_ERROR';
export const REMOVE_RECORD_QUESTION = 'REMOVE_RECORD_QUESTION';
export const SAVED_LATER_QUESTIONS = 'SAVED_LATER_QUESTIONS';
export const GET_QUESTIONS_RECORD = 'GET_QUESTIONS_RECORD';
export const GET_QUESTIONS_RECORD_SUCCESS = 'GET_QUESTIONS_RECORD_SUCCESS';
export const GET_QUESTIONS_RECORD_ERROR = 'GET_QUESTIONS_RECORD_ERROR';
export const INCREASE_QUESTION_NUMBER = 'INCREASE_QUESTION_NUMBER';
export const RESET_QUESTION_NUMBER = 'RESET_QUESTION_NUMBER';
export const EMPTY_QUESTIONS_TO_RECORD = 'EMPTY_QUESTIONS_TO_RECORD';
export const GET_USER_QUESTIONS = 'GET_USER_QUESTIONS';
export const GET_USER_QUESTIONS_SUCCESS = 'GET_USER_QUESTIONS_SUCCESS';
export const GET_USER_QUESTIONS_ERROR = 'GET_USER_QUESTIONS_ERROR';
export const UPDATE_USER_QUESTION = 'UPDATE_USER_QUESTION';
export const UPDATE_USER_QUESTION_SUCCESS = 'UPDATE_USER_QUESTION_SUCCESS';
export const UPDATE_USER_QUESTION_ERROR = 'UPDATE_USER_QUESTION_ERROR';
export const ADD_USER_QUESTION = 'ADD_USER_QUESTION';
export const ADD_USER_QUESTION_ERROR = 'ADD_USER_QUESTION_ERROR';
export const ADD_USER_QUESTION_SUCCESS = 'ADD_USER_QUESTION_SUCCESS';
export const CREATE_CUSTOM_QUESTION = 'CREATE_CUSTOM_QUESTION';
export const CREATE_CUSTOM_QUESTION_ERROR = 'CREATE_CUSTOM_QUESTION_ERROR';
export const CREATE_CUSTOM_QUESTION_SUCCESS = 'CREATE_CUSTOM_QUESTION_SUCCESS';
export const REMIND_CUSTOM_QUESTION = 'REMIND_CUSTOM_QUESTION';
export const REMIND_CUSTOM_QUESTION_SUCCESS = 'REMIND_CUSTOM_QUESTION_SUCCESS';
export const REMIND_CUSTOM_QUESTION_ERROR = 'REMIND_CUSTOM_QUESTION_ERROR';
export const REMOVE_QUESTIONS_ERROR = 'REMOVE_QUESTIONS_ERROR';
export const ADD_REMOVE_RECORD = 'ADD_REMOVE_RECORD';

// PICK QUESTIONS
export const PICK_ONE_QUESTION = 'PICK_ONE_QUESTION';
export const REMOVE_SINGLE_QUESTION = 'REMOVE_SINGLE_QUESTION';

// VIDEO
export const READY_TO_BEGIN_VIDEO = 'READY_TO_BEGIN_VIDEO';
export const START_COUNTDOWN = 'START_COUNTDOWN';
export const PERMISSION_VIDEO = 'PERMISSION_VIDEO';
export const RECORDING_VIDEO = 'RECORDING_VIDEO';
export const LOADING_VIDEO = 'LOADING_VIDEO';
export const SAVE_VIDEO = 'SAVE_VIDEO';
export const SAVE_VIDEO_SUCCESS = 'SAVE_VIDEO_SUCCESS';
export const SAVE_VIDEO_ERROR = 'SAVE_VIDEO_ERROR';
export const MATCH_VIDEO_TO_USER = 'MATCH_VIDEO_TO_USER';
export const REMOVE_VIDEO_ERROR = 'REMOVE_VIDEO_ERROR';
export const FETCH_VIDEOS = 'FETCH_VIDEOS';
export const FETCH_VIDEOS_SUCCESS = 'FETCH_VIDEOS_SUCCESS';
export const FETCH_VIDEOS_ERROR = 'FETCH_VIDEOS_ERROR';
export const FETCH_VIDEO = 'FETCH_VIDEO';
export const FETCH_VIDEO_SUCCESS = 'FETCH_VIDEO_SUCCESS';
export const FETCH_VIDEO_ERROR = 'FETCH_VIDEO_ERROR';
export const FETCH_CUSTOM_VIDEOS_SUCCESS = 'FETCH_CUSTOMVIDEOS_SUCCESS';
export const FETCH_CUSTOM_VIDEOS_ERROR = 'FETCH_CUSTOMVIDEOS_ERROR';
export const FETCH_CUSTOM_VIDEO_SUCCESS = 'FETCH_CUSTOMVIDEO_SUCCESS';
export const FETCH_CUSTOM_VIDEO_ERROR = 'FETCH_CUSTOMVIDEO_ERROR';
export const SELECT_OR_UNSELECT_VIDEO = 'SELECT_OR_UNSELECT_VIDEO';
export const DELETE_VIDEOS = 'DELETE_VIDEOS';
export const DELETE_VIDEOS_SUCCESS = 'DELETE_VIDEO_SUCCESS';
export const DELETE_VIDEOS_ERROR = 'DELETE_VIDEOS_ERROR';
export const TOGGLE_SHARE_MODAL = 'TOGGLE_SHARE_MODAL';
export const CHECK_UPLOADING_VIDEOS_SUCCESS = 'CHECK_UPLOADING_VIDEOS_SUCCESS';
export const CHECK_UPLOADING_VIDEOS_ERROR = 'CHECK_UPLOADING_VIDEOS_ERROR';
export const CHECK_UPLOADING_CUSTOM_VIDEOS_SUCCESS = 'CHECK_UPLOADING_CUSTOM_VIDEOS_SUCCESS';
export const CHECK_UPLOADING_CUSTOM_VIDEOS_ERROR = 'CHECK_UPLOADING_CUSTOM_VIDEOS_ERROR';
export const UNSELECT_ALL_VIDEOS = 'UNSELECT_ALL_VIDEOS';
export const SHARE_VIDEOS = 'SHARE_VIDEOS';
export const SHARE_VIDEOS_SUCCESS = 'SHARE_VIDEOS_SUCCESS';
export const SHARE_VIDEOS_ERROR = 'SHARE_VIDEOS_ERROR';
export const UPLOAD_STREAM_VIDEOS = 'UPLOAD_STREAM_VIDEOS';
export const UPLOAD_STREAM_SUCCESS = 'UPLOAD_STREAM_SUCCESS';
export const UPLOAD_STREAM_ERROR = 'UPLOAD_STREAM_ERROR';
export const TOGGLE_USE_CHROME_MODAL = 'TOGGLE_USER_CHROME_MODAL';

// CATEGORIES

export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_ERROR = 'FETCH_CATEGORIES_ERROR';
export const SELECT_FIRST_CATEGORY = 'SELECT_FIRST_CATEGORY';
export const SELECT_SECOND_CATEGORY = 'SELECT_SECOND_CATEGORY';
export const SELECT_THIRD_CATEGORY = 'SELECT_THIRD_CATEGORY';
export const FETCH_CATEGORY = 'FETCH_CATEGORY';
export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS';
export const FETCH_CATEGORY_ERROR = 'FETCH_CATEGORY_ERROR';
export const REMOVE_SELECTED_CATEGORIES = 'REMOVE_SELECTED_CATEGORIES';
