const routes = {
  index: "/",
  login: "/login",
  signUp: "/sign-up",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  stepQuestions: "/questions",
  faqs: "/faqs",
  dashboard: "/dashboard",
  questionMenu: "/question-menu",
  privacypolicy: "/privacypolicy",
  termsandcondition: "/termsandcondition",
  answerQuestions: "/answer-recording",
  playVideo: "/video/:postId",
  playCustomVideo: "/custom-video/:postId",
  myList: "/my-list",
  webrtc: "/webrtc",
  pricing: "/pricing",
  howtovideo: '/how-to-video',
  ourStory: "/our-story",
  partners: "/partners",
  press: "/press",
  myQuestions: '/myquestions',
  myvideolist: "/myvideolist",
  createQuestion: '/create-question',
  sendQuestion: '/send-question'
};

export default routes;
