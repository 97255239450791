import React, { PureComponent } from 'react';
import { func, string, bool } from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import { Link } from 'react-router-dom';
import {
  injectIntl,
  intlShape,
  defineMessages,
  FormattedMessage
} from 'react-intl';

import Loading from 'components/common/Loading';
import Input from 'components/common/Input';
import Button from 'components/common/Button';
import { validations, login } from 'utils/constraints';
import routes from 'constants/routesPaths';

const messages = defineMessages({
  email: { id: 'login.form.email' },
  password: { id: 'login.form.password' },
  signUp: { id: 'login.form.signup' }
});

export class LoginForm extends PureComponent {
  render() {
    const { handleSubmit, error, submitting, intl } = this.props;
    const { email, password, signUp } = messages;
    return (
      <div className="login-form-container">
        <div className="">
          <div className="login-card">
            <FormattedMessage id="login.title">
              {txt => (<h2>{txt}</h2>)}
            </FormattedMessage>
            <hr />
            <form onSubmit={handleSubmit} className="form-wrapper">
              {error && <span className="error-message">{error}</span>}
              <div className="input-container">
                <p className="form-field-name">Email</p>
                <Field
                  name="email"
                  placeholder="Enter your email address"
                  component={Input}
                  type="email"
                  autoComplete="email"
                />
              </div>
              <div className="input-container">
                <p className="form-field-name">Password</p>
                <Field
                  name="password"
                  placeholder="Enter in your password"
                  component={Input}
                  type="password"
                  autoComplete="current-password"
                />
              </div>
              <Button text="Sign in" className="btn-blue" type="submit" />
              <div className="already-account-text mb-1">
                {intl.formatMessage(signUp)}
                <Link className="sign-in-text" to={routes.signUp}> Sign up here</Link>
              </div>
              <div className="already-account-text">
                <Link className="sign-in-text" to={routes.forgotPassword}>Forgot password?</Link>
              </div>
              {submitting && <Loading />}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

LoginForm.propTypes = {
  handleSubmit: func.isRequired,
  intl: intlShape.isRequired,
  submitting: bool.isRequired,
  error: string
};

export default reduxForm({
  form: 'login',
  validate: validations(login, { fullMessages: false })
})(injectIntl(LoginForm));
