import api from 'api/apiService';

class Categories {
  static fetchCategories() {
    return api.get('/categories');
  }

  static fetchCategory(categoryId, page = 1) {
    return api.get(`/categories/${categoryId}?page=${page}`);
  }
}

export default Categories;
