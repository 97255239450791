import React from 'react';
import { number, func, bool } from 'prop-types';
import playIcon from 'assets/icons/play-icon.svg';
import pauseIcon from 'assets/icons/pause-icon.svg';
import volumeIcon from 'assets/icons/volumeIcon.svg';
import muteIcon from 'assets/icons/mute.svg';
import cn from 'classnames';
import Button from '../common/Button';

const SliderVideo = ({
  played,
  onPress,
  isPlaying,
  volume,
  onMute,
  onVolumeChange,
  onChangeFunction,
  maxDuration,
  lastQuestion,
}) => {
  const isMuted = volume === 0;
  return (
    <div className={cn('range-video-player range-box-shadow', { 'last-question': lastQuestion })}>
      <div className="button-icons position-absolute w-100">
        <div className={cn('volume volume-slider', { 'last-question': lastQuestion })}>
          <input
            className={cn('volume', { muted: isMuted })}
            type="range"
            min="0"
            max="1"
            step="any"
            value={volume}
            onChange={onVolumeChange}
          />
        </div>
        <Button
          className={cn('pl-0 volume-icon', { 'mute-icon': isMuted, 'last-question': lastQuestion })}
          onPress={onMute}
          icon={isMuted ? muteIcon : volumeIcon}
          onlyIcon
        />
      </div>
      <div className="btn-play">
        <Button onPress={onPress} icon={isPlaying ? pauseIcon : playIcon} onlyIcon />
      </div>
      <input
        type="range"
        min="0"
        max={maxDuration}
        step="any"
        value={played}
        onChange={onChangeFunction}
      />
    </div>
  );
}

SliderVideo.propTypes = {
  isPlaying: bool,
  onPress: func.isRequired,
  played: number,
  maxDuration: number.isRequired,
  onChangeFunction: func.isRequired,
  lastQuestion: bool
};

export default SliderVideo;
