import React, { PureComponent } from 'react';
import { string, object } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { parseInputErrors } from 'utils/helpers';

export default class Input extends PureComponent {
  render() {
    const {
      input,
      label,
      type,
      placeholder,
      autoComplete,
      meta: { touched, error },
      text = ""
    } = this.props;

    return (
      <div>
        {label && <label>{label}</label>}
        <div className="d-flex align-items-center">
          <input {...input} {...{ placeholder, type, autoComplete }} className={(error && touched) ? 'error' : ''} />
          {text !== '' && <div className="ml-3">{text}</div>}
        </div>
        {touched && error &&
          <div className="error-msg-container">
            <span className="error-message">
              <FormattedMessage
                id={parseInputErrors(error)}
                defaultMessage={parseInputErrors(error)}
              />
            </span>
          </div>
        }
      </div>
    );
  }
}

Input.propTypes = {
  input: object.isRequired,
  label: string,
  type: string.isRequired,
  placeholder: string,
  meta: object,
  autoComplete: string
};
