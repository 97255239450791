import React, { Component, Fragment } from "react";
import WithHeader from "../components/common/WithHeader";

import beacon from "../assets/pressLogos/beaconLogo.png"
import fastCompany from "../assets/pressLogos/fastCompanyLogo.png"
import longevity from "../assets/pressLogos/longevityTechnologyLogo.png"
import losAngelesDailyNews from "../assets/pressLogos/losAngelesDailyNews.png"
import jewishLearning from "../assets/pressLogos/jewishLearning.png"

class Press extends Component {
    componentDidMount = () => window.scrollTo(0, 0);

    renderItem = (image, url) => (
        <div className="press-container">
            <img src={image}></img>
            <a href={url}>View</a>
        </div>
    )

    render() {
        return (
            <Fragment>
                <div className="press">
                    <h3>Press</h3>
                    <span className="center-blue-line"></span>
                    <div className="company-flex">
                        {this.renderItem(beacon, "https://www.youtube.com/watch?v=7KwzdQi2FAg")}
                        {this.renderItem(fastCompany, "https://www.fastcompany.com/90337388/i-refuse-to-have-a-terrible-death-the-rise-of-the-death-wellness-movement")}
                        {this.renderItem(longevity, "https://www.longevity.technology/meet-the-startup-megilla-online-storytelling/")}
                        {this.renderItem(losAngelesDailyNews, "https://www.dailynews.com/2019/04/17/successful-aging-technology-is-looking-at-ways-to-improve-the-process-of-getting-older/")}
                        {this.renderItem(jewishLearning, "https://www.myjewishlearning.com/article/how-to-interview-your-elders/")}
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default WithHeader(Press);
