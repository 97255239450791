import React, { Component } from 'react';

import { connect } from 'react-redux';
import { array, func } from 'prop-types';
import cn from 'classnames';
import tickImg from 'assets/icons/added.svg';
import { getUserQuestions, emptyQuestionsToRecord, addRemoveToRecord, checkIfAlreadyAnswered } from 'actions/questionsActions';
import { USER_QUESTION_STATES, REPLACE_QUESTION_TEXT } from 'constants/constants';
import ConfirmModal from '../common/ConfirmModal';

class SavedQuestions extends Component {
  state = {
    openModal: false
  };

  componentWillMount() {
    const { getUserQuestions, emptyQuestionsToRecord } = this.props;
    getUserQuestions().then(() => {
      emptyQuestionsToRecord();
    });
  }

  isSelected = (id) => {
    const { questionsToRecordList } = this.props;
    return questionsToRecordList.some(item => item.id === id);
  }

  handleToggle = (question) => {
    const { id, questionId } = question;
    const { addRemoveToRecord, checkIfAlreadyAnswered } = this.props;
    const questionSelected = this.isSelected(id);
    if (!questionSelected) {
      if (checkIfAlreadyAnswered(questionId).then((postId) => {
        if (postId) {
          question.postId = postId;
          this.setState({ openModal: true, question, added: questionSelected });
        } else {
          addRemoveToRecord(question, questionSelected);
        }
      }));
    } else {
      addRemoveToRecord(question, questionSelected);
    }
  }

  replaceQuestion = () => {
    const { addRemoveToRecord } = this.props;
    const { openModal, question, added } = this.state;
    openModal && this.setState({ openModal: false });
    addRemoveToRecord(question, added);
  }

  closeModal = () => {
    this.setState({ openModal: false });
  }

  render() {
    const { userQuestionsList } = this.props;
    const { openModal } = this.state;
    const filtered = userQuestionsList.filter(question => question.state === USER_QUESTION_STATES.pending);
    return (
      <div className="row col-lg-10 offset-lg-1 my-list-container">
        <div className="no-padding col-lg-12 questions-answer-container">
          <h1>My List</h1>
          {filtered.length ?
            filtered.map((item, index) => {
              const isQuestionSelect = this.isSelected(item.id);
              return (
                <div className={cn({ 'selected-question': isQuestionSelect })} key={index}>
                  <div className="questions" onClick={() => this.handleToggle(item)}>
                    <div className="full-width">
                      <a className={cn({ added: isQuestionSelect })}>
                        <span className="question-name">{item.questionName}</span>
                      </a>
                      <span className="category" style={{ background: item.color }}>
                        {item.rootCategory}
                      </span>
                    </div>
                    <div className="icon-wrapper">
                      {isQuestionSelect && <img src={tickImg} alt="status" />}
                    </div>
                  </div>
                </div>
              );
            })
            :
            <h3>No questions selected</h3>
          }
        </div>
        <ConfirmModal
          isOpen={openModal}
          title={REPLACE_QUESTION_TEXT}
          onConfirm={this.replaceQuestion}
          onReject={this.closeModal}
        />
      </div>
    );
  }
}

SavedQuestions.propTypes = {
  questionsToRecordList: array,
  userQuestionsList: array,
  getUserQuestions: func,
  emptyQuestionsToRecord: func,
  addRemoveToRecord: func,
  checkIfAlreadyAnswered: func
};

const mapState = state => ({
  error: state.getIn(['questions', 'error']),
  loading: state.getIn(['questions', 'loading']),
  userQuestionsList: state.getIn(['questions', 'userQuestionsList']).toJS(),
  questionsToRecordList: state.getIn(['questions', 'questionsToRecordList']).toJS(),
});

const mapDispatch = dispatch => ({
  checkIfAlreadyAnswered: questionId => dispatch(checkIfAlreadyAnswered(questionId)),
  getUserQuestions: () => dispatch(getUserQuestions()),
  emptyQuestionsToRecord: () => dispatch(emptyQuestionsToRecord()),
  addRemoveToRecord: (userQuestion, added) => dispatch(addRemoveToRecord(userQuestion, added))
});

export default connect(mapState, mapDispatch)(SavedQuestions);
