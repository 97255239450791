import api from 'api/apiService';

class Session {
  static login(user) {
    return api.post('/users/sign_in', user);
  }

  static logout() {
    return api.delete('/users/sign_out');
  }

  static signUp(user) {
    return api.post('/users', user);
  }

  static forgotPassword(email) {
    return api.post('/forgot_password', email);
  }

  static resetPassword(data) {
    return api.post('/reset_password', data);
  }
}

export default Session;
