import React, { Component, Fragment } from 'react';
import { func, array, object, bool } from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import isEmpty from 'lodash/isEmpty';
import routes from 'constants/routesPaths';
import { FIRST_LEVEL_CATEGORY, SECOND_LEVEL_CATEGORY, THIRD_LEVEL_CATEGORY, USER_QUESTION_STATES } from 'constants/constants';
import withHeader from 'components/common/WithHeader';
import QuestionMenuSidebar from 'components/questionMenu/QuestionMenuSidebar';
import CategoriesNavigation from 'components/questionMenu/CategoriesNavigation';
import QuestionsToAnswer from 'components/questionMenu/QuestionsToAnswer';
import QuestionsFooter from 'components/questionMenu/QuestionsFooter';
import QuestionMenuCards from 'components/questionMenu/QuestionMenuCards';
import { fetchCategories, selectLevelCategory, removeSelectedCategories } from 'actions/categoriesActions';
import { getUserQuestions, removeSingleQuestion } from 'actions/questionsActions';

class QuestionMenuPage extends Component {
  constructor(props) {
    super(props);
    const { selectedFirstLevelCategory } = this.props;

    this.state = {
      selectedSubcategories: [],
      setFirstCategoryByDefault: false,
      oneCategorySelected: !isEmpty(selectedFirstLevelCategory)
    };
  }

  componentDidMount() {
    const { fetchCategories, getUserQuestions, authenticated } = this.props;

    window.fbq('track', 'ViewContent', { content_name: 'Question Menu Page' });

    authenticated && getUserQuestions();
    fetchCategories();
  }

  componentDidUpdate() {
    const {
      categoriesList,
      location: { state: { setCategoryByDefault } = {} },
    } = this.props;
    const { setFirstCategoryByDefault } = this.state;

    if (setCategoryByDefault && !setFirstCategoryByDefault && categoriesList.length) {
      const [{ id, name, subcategories }] = categoriesList;
      this.setSelectedCategory(id, name, FIRST_LEVEL_CATEGORY, !!subcategories.length);
      this.setState({ setFirstCategoryByDefault: true });
    }
  }

  setSelectedCategory = (categoryId, categoryName, level, hasSubcategories) => {
    const { selectedFirstLevelCategory, selectedSecondLevelCategory, selectLevelCategory } = this.props;
    let categoriesSelectedList;

    ReactGA.event({
      category: 'Category',
      action: 'Selected Category',
      label: categoryName
    });

    switch (level) {
      case THIRD_LEVEL_CATEGORY: {
        categoriesSelectedList = [selectedFirstLevelCategory.categoryId, selectedSecondLevelCategory.categoryId, categoryId];
        break;
      }
      case SECOND_LEVEL_CATEGORY: {
        categoriesSelectedList = [selectedFirstLevelCategory.categoryId, categoryId];
        break;
      }
      default: {
        categoriesSelectedList = [categoryId];
      }
    }

    this.setState({
      selectedSubcategories: this.getSubcategoriesList(categoriesSelectedList),
      oneCategorySelected: true
    });
    selectLevelCategory(categoryId, categoryName, level, hasSubcategories);
  }

  getSubcategoriesList = (selectedCategoriesList) => {
    let listToFilter = this.props.categoriesList;

    selectedCategoriesList.map((categoryId) => {
      listToFilter = listToFilter.filter(({ id }) => (id === categoryId));
      ([{ subcategories: listToFilter }] = listToFilter);
    });
    return listToFilter;
  }

  getSelectedUserQuestions = () => {
    const { userQuestionsList } = this.props;
    return userQuestionsList.filter(item => item.state === USER_QUESTION_STATES.selected);
  }

  canSeeQuestions = () => {
    const { selectedFirstLevelCategory, selectedSecondLevelCategory, selectedThirdLevelCategory } = this.props;

    if (!selectedFirstLevelCategory.hasSubcategories) {
      return selectedFirstLevelCategory.categoryId;
    } else if (!selectedSecondLevelCategory.hasSubcategories) {
      return selectedSecondLevelCategory.categoryId;
    } else if (!selectedThirdLevelCategory.hasSubcategories) {
      return selectedThirdLevelCategory.categoryId;
    }
  }

  answerQuestions = () => {
    const { history } = this.props;
    // removeSingleQuestion();
    history.push(routes.answerQuestions);
  }

  render() {
    const {
      categoriesList,
      selectedFirstLevelCategory,
      selectedSecondLevelCategory,
      selectedThirdLevelCategory,
      removeSelectedCategories,
      selectedQuestion,
      loading
    } = this.props;
    const { selectedSubcategories, oneCategorySelected } = this.state;
    const cat = this.canSeeQuestions();
    const toRecord = !isEmpty(selectedQuestion);

    return (
      <div className="question-menu-page">
        {oneCategorySelected ?
          <div className="container-fluid">
            <div className="row">
              <QuestionMenuSidebar
                loading={loading}
                categoriesList={categoriesList}
                selectedFirstLevelCategory={selectedFirstLevelCategory.categoryId}
                selectedSecondLevelCategory={selectedSecondLevelCategory.categoryId}
                selectedThirdLevelCategory={selectedThirdLevelCategory.categoryId}
                onSelectedLevelCategory={this.setSelectedCategory}
                onRemoveSelectedCategories={removeSelectedCategories}
              />
              <div className="main">
                {!cat ?
                  <QuestionMenuCards
                    categoriesList={selectedSubcategories}
                    level={!isEmpty(selectedFirstLevelCategory) && !isEmpty(selectedSecondLevelCategory) ?
                      THIRD_LEVEL_CATEGORY : SECOND_LEVEL_CATEGORY
                    }
                    onSelectedLevelCategory={this.setSelectedCategory}
                    secondLevelName={selectedFirstLevelCategory.categoryName}
                    loading={loading}
                  />
                  :
                  <Fragment>
                    <CategoriesNavigation
                      firstLevelCat={selectedFirstLevelCategory.categoryName}
                      secondLevelCat={selectedSecondLevelCategory.categoryName}
                      thirdLevelCat={selectedThirdLevelCategory.categoryName}
                    />
                    <QuestionsToAnswer category={cat} />
                  </Fragment>
                }
              </div>
            </div>
          </div>
          :
          <QuestionMenuCards
            categoriesList={categoriesList}
            level={FIRST_LEVEL_CATEGORY}
            onSelectedLevelCategory={this.setSelectedCategory}
            loading={loading}
          />
        }
        {/* {oneCategorySelected &&
          <div className="footer-row">
            <QuestionsFooter quantity={toRecord} startRecording={this.answerQuestions} />
          </div>
        } */}
      </div>
    );
  }
}

QuestionMenuPage.propTypes = {
  fetchCategories: func.isRequired,
  selectLevelCategory: func.isRequired,
  removeSelectedCategories: func.isRequired,
  loading: bool,
  location: object,
  categoriesList: array,
  selectedFirstLevelCategory: object,
  selectedSecondLevelCategory: object,
  selectedThirdLevelCategory: object,
  userQuestionsList: array,
  getUserQuestions: func,
  history: object.isRequired,
  authenticated: bool.isRequired,
};

const mapState = state => ({
  categoriesList: state.getIn(['categories', 'categoriesList']).toJS(),
  selectedFirstLevelCategory: state.getIn(['categories', 'selectedFirstLevelCategory']).toJS(),
  selectedSecondLevelCategory: state.getIn(['categories', 'selectedSecondLevelCategory']).toJS(),
  selectedThirdLevelCategory: state.getIn(['categories', 'selectedThirdLevelCategory']).toJS(),
  loading: state.getIn(['categories', 'loading']),
  userQuestionsList: state.getIn(['questions', 'userQuestionsList']).toJS(),
  selectedQuestion: state.getIn(['questions', 'singleSelectedQuestion']).toJS(),
  authenticated: state.getIn(['session', 'authenticated']),
});

const mapDispatch = ({
  fetchCategories,
  selectLevelCategory,
  removeSelectedCategories,
  getUserQuestions,
  removeSingleQuestion
});

export default connect(mapState, mapDispatch)(withHeader(QuestionMenuPage));
