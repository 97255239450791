export default {
  'login.title': 'Sign into your account',
  'forgotPassword.title': 'Reset Password',

  'login.form.email': 'Email',
  'login.form.password': 'Password',
  'login.form.submit': 'Sign in',
  'login.form.signup': "Don’t have an account yet? ",

  'login.forgot_password': 'Forgot your password?',
  
  'forgotPassword.backToLogin': 'Back to login',
  'forgotPassword.submit': 'Send reset link',

  'signup.title': 'Create an Account',
  'signup.title.continue': 'Sign up to continue',
  'signup.signin': 'Sign in',
  'signup.form.name': 'Name',
  'signup.form.passconfirmation': 'Confirm Password',

  'logout.button': 'LOGOUT',

  'home.welcome': 'Welcome to React Redux Base',
  'share.email.input': 'Share Email',
  'share.email.input.placeholder': 'Add another email...',

  // errors
  'email.presence': 'You must enter an email to continue',
  'email.invalid': 'You must enter a valid email',
  'email.duplicate': 'You have entered a duplicate email',
  'email.empty': 'Please add at least one email',
  'name.presence': 'You must enter your name to continue',
  'password.presence': 'You must enter a password to continue',
  'password.minLength': 'Minimum 8 characters long',
  'passwordConfirmation.presence': 'You must enter a password confirmation to continue',
  'passwordConfirmation.equality': 'Your password confirmation must be equal to the password',
};
