import React, { Fragment, PureComponent } from 'react';
import { bool } from 'prop-types';
import { ConnectedRouter } from 'react-router-redux';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';

import history from 'utils/history';
import RouteFromPath from 'components/routes/RouteFromPath';
import theme from 'constants/theme';
import routes from '../routes';

class App extends PureComponent {
  static propTypes = {
    authenticated: bool.isRequired,
    checked: bool.isRequired,
    uploadingVideos: bool
  }

  state = {
    askBeforeLeave: false
  }

  componentDidUpdate() {
    const { uploadingVideos } = this.props;
    const { askBeforeLeave } = this.state;

    if (!askBeforeLeave && uploadingVideos) {
      window.addEventListener('beforeunload', this.onBeforeUnload);
      this.setState({ askBeforeLeave: true });
    } else if (askBeforeLeave && !uploadingVideos) {
      window.removeEventListener('beforeunload', this.onBeforeUnload);
      this.setState({ askBeforeLeave: false });
    }
  }

  onBeforeUnload = (event) => {
    event.returnValue = 'Ask before unload site';
  }

  render() {
    const { authenticated, checked } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <Helmet>
            <title>Megilla</title>
          </Helmet>
          <ConnectedRouter history={history}>
            {checked &&
              <Switch>
                {routes.map((route, index) =>
                  <RouteFromPath
                    key={`route${index}`}
                    {...route}
                    authenticated={authenticated}
                  />)
                }
              </Switch>
            }
          </ConnectedRouter>
        </Fragment>
      </ThemeProvider>
    );
  }
}

const mapState = state => ({
  checked: state.getIn(['session', 'checked']),
  authenticated: state.getIn(['session', 'authenticated']),
  uploadingVideos: state.getIn(['video', 'uploadingVideos'])
});

export default connect(mapState)(App);
