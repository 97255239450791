import { SubmissionError } from 'redux-form/immutable';
import { sessionService } from 'redux-react-session';
import ReactGA from 'react-ga';

import sessionApi from 'api/sessionApi';
import { getGuestToken, removeGuestToken, getResetPasswordToken, removeResetPasswordToken } from 'utils/helpers';
import { matchVideoToUser } from './videoActions';

export const signUp = user =>
  async (dispatch) => {
    try {
      const guestToken = await getGuestToken();
      const userData = guestToken ? { guestToken, user } : { user };
      const response = await sessionApi.signUp(userData);
      sessionService.saveUser(response.user);
      guestToken && removeGuestToken();
      ReactGA.set({ userId: user.email });
      window.fbq('track', 'CompleteRegistration', { status: `${user.email} Registered.`})
      dispatch(matchVideoToUser());
    } catch (err) {
      throw new SubmissionError(err.errors);
    }
  };

export const sendResetLink = email =>
  async () => {
    try {
      await sessionApi.forgotPassword(email);
    } catch (err) {
      throw new SubmissionError({
        _error: err.error
      });
    }
  }

export const resetPassword = user =>
  async () => {
    try {
      const token = await getResetPasswordToken();
      await sessionApi.resetPassword({ ...user, token });
      token && removeResetPasswordToken();
    } catch (err) {
      throw new SubmissionError(err.errors);
    }
  };
