import React, { Component, Fragment } from 'react';
import { bool, func, string, object } from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import cn from 'classnames';
import { logout } from 'actions/sessionActions';
import routes from 'constants/routesPaths';
import { notAuthenticatedNavItems, authenticatedNavItems } from 'constants/constants';
import logoImg from 'assets/images/logo.svg';
import onlyLogoImg from 'assets/icons/only-logo.svg';
import hamburgerImg from 'assets/icons/hamburguer.svg';
import crossImg from 'assets/icons/cross.svg';
import Button from './Button';
import SearchBar from './SearchBar';
import NavbarMenu from './NavbarMenu';

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };

    this.onClickAction = this.onClickAction.bind(this);
    this.onVisibleChange = this.onVisibleChange.bind(this);
    this.checkSelectedItem = this.checkSelectedItem.bind(this);
  }

  onClickAction({ key }) {
    const { history, logout, history: { location: { pathname } } } = this.props;
    this.setState({ isOpen: false });
    if (key === 'logout') {
      logout();
    } else if (pathname !== routes.myvideolist) {
      history.push(routes.myvideolist);
    }
  }

  onVisibleChange(visible) {
    this.setState({ isOpen: visible });
  }

  checkSelectedItem(link) {
    const { pathname } = this.props;
    return link !== '/' && [...routes, link][0] === pathname && (link === pathname);
  }

  toggleSideMenu = () => {
    const { sideOpen, openSideBar, closeSideBar } = this.props;
    sideOpen ? closeSideBar() : openSideBar();
  }

  render() {
    const {
      isSearchBarShown = false,
      authenticated,
      name,
      sideOpen,
      questions: { received }
    } = this.props;
    const { isOpen } = this.state;
    const navItems = authenticated ? authenticatedNavItems : notAuthenticatedNavItems;
    const hasNotAnsweredQuestion = received && received.find(({ video }) => !video);

    return (
      <nav className="navbar navbar-expand-md other-screen fixed-navbar">
        <h2 className="navbar-brand">
          {authenticated 
          ? <Link to={routes.myvideolist} className="title align-bottom">
              <img className="logo with-text" src={logoImg} alt="Logo" />
              <img className="logo only-logo" src={onlyLogoImg} alt="LogoM" />
            </Link>
            :
            <Link to={routes.index} className="title align-bottom">
              <img className="logo with-text" src={logoImg} alt="Logo" />
              <img className="logo only-logo" src={onlyLogoImg} alt="LogoM" />
            </Link>
          }
        </h2>
        <button className="navbar-toggler" onClick={this.toggleSideMenu}>
          <img className="logo" src={sideOpen ? crossImg : hamburgerImg} alt="hamburger" />
        </button>
        <div className="collapse navbar-collapse">
          {isSearchBarShown && <SearchBar />}
          {authenticated
            ? <ul className="navbar-nav ml-auto ml-4">
              {navItems.map(({ name, link }, index) => (
                <li className="nav-item" key={index}>
                  <Link to={link}>
                    {name}
                    {hasNotAnsweredQuestion && name === 'My Questions' && <div className="dot" />}
                    <br />
                    <div className={cn('highlight-item', { selected: this.checkSelectedItem(link) })} />
                  </Link>
                </li>
              ))
              }
            </ul>
            : <ul className="navbar-nav unauthenticated-navbar ml-auto ml-4">
              {navItems.map(({ name, link }, index) => (
                <li className="nav-item" key={index}>
                  <Link to={link}>
                    {name}
                    <br />
                    <div className={cn('highlight-item', { selected: this.checkSelectedItem(link) })} />
                  </Link>
                </li>
              ))
              }
            </ul>
          }
          {/* <span className="divider" /> */}
          {authenticated ?
            <NavbarMenu
              name={name}
              isOpen={isOpen}
              onSelect={this.onClickAction}
              onVisibleChange={this.onVisibleChange}
            />
            :
            <Fragment>
              {/* <Link to={routes.signUp}>
                <Button text="Sign up" className="mx-4 btn-aqua" />
              </Link>
              <Link to={routes.login}>
                <Button text="Sign in" className="mx-4 btn-blue" />
              </Link> */}
            </Fragment>
          }
        </div>
      </nav>
    );
  }
}

Navbar.propTypes = {
  logout: func.isRequired,
  authenticated: bool.isRequired,
  history: object.isRequired,
  isSearchBarShown: bool,
  name: string,
  pathname: string,
  sideOpen: bool,
  openSideBar: func,
  closeSideBar: func
};

const mapState = state => ({
  authenticated: state.getIn(['session', 'authenticated']),
  name: state.getIn(['session', 'user', 'firstName']),
  pathname: state.getIn(['router', 'location', 'pathname']),
  questions: state.getIn(['questions', 'customQuestionsList']).toJS()
});

const mapDispatch = ({ logout });

export default connect(mapState, mapDispatch)(Navbar);
