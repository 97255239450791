import React from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';
import { createCustomQuestion } from 'actions/questionsActions';
import routes from 'constants/routesPaths';
import Input from './Input';
import Button from './Button';

const QuestionShareModal = ({ question, isOpen, onClose, history, createQuestion }) => {
  const handleSubmit = (e) => {
    createQuestion({
      question,
      receiver: e.target.elements.email.value
    });
    onClose();
  };

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false} className="modal-opened remind-modal">
      <div className="content">
        <h1 className="modal-title">Send this question to someone:</h1>
        <p className="question text-left">{question}</p>
        <form onSubmit={handleSubmit} className="form-wrapper w-100">
          <div className="input-container w-100">
            <h2 className="text-left">Send to:</h2>
            <Field
              name="email"
              placeholder="i.e. mom@gmail.com"
              component={Input}
              type="text"
            />
          </div>
          <p>(For now, you can only send one question to one person at a time.)</p>
          <div className="actions">
            <Button
              text="Send"
              className="btn-green"
              type="submit"
            />
            <Button
              text="< Go back"
              className="link"
              onPress={onClose}
            />
          </div>
        </form>
      </div>
    </ReactModal>
  );
};
const mapDispatchToProps = dispatch => ({
  createQuestion: params => dispatch(createCustomQuestion(params))
});
export default reduxForm({
  form: 'shareQuestion',
})(connect(null, mapDispatchToProps)(QuestionShareModal));
