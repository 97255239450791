import { parseInputErrors } from 'utils/helpers';
import { uploadFile } from 'react-s3';
import { toast } from 'react-toastify';
import {
  S3_CONFIG,
  TOAST_CONFIG,
  VIDEO_UPLOADING_TEXT,
  VIDEO_UPLOADING_ERROR_TEXT,
  TOAST_CONFIG_INFO
} from 'constants/constants';
import * as types from './actionTypes';
import videosApi from '../api/videosApi';

export const userReady = isReady => ({
  type: types.READY_TO_BEGIN_VIDEO,
  isReady
});

export const toggleUseChromeModal = type => ({
  type: types.TOGGLE_USE_CHROME_MODAL,
  payload: type
});

export const startCountdown = countdown => ({
  type: types.START_COUNTDOWN,
  countdown
});

export const streamPermission = permission => ({
  type: types.PERMISSION_VIDEO,
  permission
});

export const isRecordingVideo = recording => ({
  type: types.RECORDING_VIDEO,
  recording
});

export const loadingVideo = loading => ({
  type: types.LOADING_VIDEO,
  loading
});

export const saveVideoSuccess = () => ({
  type: types.SAVE_VIDEO_SUCCESS
});

export const saveVideoError = error => ({
  type: types.SAVE_VIDEO_ERROR,
  error
});

export const saveVideoAction = () => ({
  type: types.SAVE_VIDEO
});

export const matchVideoToUser = (match = true) => ({
  type: types.MATCH_VIDEO_TO_USER,
  match
});

export const removeVideoError = () => ({
  type: types.REMOVE_VIDEO_ERROR
});

export const fetchVideosAction = () => ({
  type: types.FETCH_VIDEOS
});

export const fetchVideosActionSuccess = videos => ({
  type: types.FETCH_VIDEOS_SUCCESS,
  videos
});

export const fetchVideosActionError = error => ({
  type: types.FETCH_VIDEOS_ERROR,
  error
});

export const fetchCustomVideosActionSuccess = videos => ({
  type: types.FETCH_CUSTOM_VIDEOS_SUCCESS,
  videos
});

export const fetchCustomVideosActionError = error => ({
  type: types.FETCH_CUSTOM_VIDEOS_ERROR,
  error
});

export const fetchVideoAction = () => ({
  type: types.FETCH_VIDEO
});

export const fetchVideoActionSuccess = video => ({
  type: types.FETCH_VIDEO_SUCCESS,
  video
});

export const fetchVideoActionError = error => ({
  type: types.FETCH_VIDEO_ERROR,
  error
});

export const deleteVideosAction = () => ({
  type: types.DELETE_VIDEOS
});

export const deleteVideosActionSuccess = deletedVideos => ({
  type: types.DELETE_VIDEOS_SUCCESS,
  deletedVideos
});

export const deleteVideosActionError = error => ({
  type: types.DELETE_VIDEOS_ERROR,
  error
});

export const selectOrUnselectVideo = video => ({
  type: types.SELECT_OR_UNSELECT_VIDEO,
  video
});

export const checkUploadingVideosSuccess = videos => ({
  type: types.CHECK_UPLOADING_VIDEOS_SUCCESS,
  videos
});

export const checkUploadingCustomVideosSuccess = videos => ({
  type: types.CHECK_UPLOADING_CUSTOM_VIDEOS_SUCCESS,
  videos
});

export const checkUploadingVideosError = error => ({
  type: types.CHECK_UPLOADING_VIDEOS_ERROR,
  error
});

export const unselectVideos = () => ({
  type: types.UNSELECT_ALL_VIDEOS
});

export const toggleShareModal = () => ({
  type: types.TOGGLE_SHARE_MODAL
});

export const shareVideosAction = () => ({
  type: types.SHARE_VIDEOS
});

export const shareVideosActionSuccess = () => ({
  type: types.SHARE_VIDEOS_SUCCESS
});

export const shareVideosActionError = error => ({
  type: types.SHARE_VIDEOS_ERROR,
  error
});

export const uploadVideoStreamAction = () => ({
  type: types.UPLOAD_STREAM_VIDEOS
});

export const uploadVideoStreamActionSuccess = () => ({
  type: types.UPLOAD_STREAM_SUCCESS
});

export const uploadVideoStreamActionError = error => ({
  type: types.UPLOAD_STREAM_ERROR,
  error
});

export const deleteVideos = (videos, isOrigin) =>
  (dispatch) => {
    dispatch(deleteVideosAction());
    videosApi[isOrigin ? 'deleteVideo' : 'deleteCustomVideo'](videos).then(() => {
      dispatch(deleteVideosActionSuccess(videos));
    }).catch((error) => {
      dispatch(deleteVideosActionError(parseInputErrors(error)));
    });
  };

export const saveVideo = (video, update, postId, isOrigin) =>
  (dispatch) => {
    dispatch(saveVideoAction());
    toast.dismiss();
    toast.info(VIDEO_UPLOADING_TEXT, TOAST_CONFIG_INFO);
    const apiNamePrefix = (update && 'update') || 'save';
    const videoType = (isOrigin && 'Video') || 'CustomVideo';
    return videosApi[`${apiNamePrefix}${videoType}`](video, postId).then(({ post, customVideo }) => {
      dispatch(selectOrUnselectVideo(post || customVideo));
      dispatch(saveVideoSuccess());
      return post || customVideo;
    }).catch((err) => {
      dispatch(saveVideoError(parseInputErrors(err)));
      toast.error(VIDEO_UPLOADING_ERROR_TEXT, TOAST_CONFIG);
    });
  };

export const uploadVideo = (blob, key, postId, isOrigin) =>
  (dispatch) => {
    uploadFile(blob, S3_CONFIG).then(() => {
      const newData = { key };
      const videoType = (isOrigin && 'Video') || 'CustomVideo';
      return videosApi[`update${videoType}`](newData, postId).then(() => {
        dispatch(saveVideoSuccess());
        return true;
      });
    }).catch(() => {
      dispatch(saveVideoError(VIDEO_UPLOADING_ERROR_TEXT));
      const postIds = [postId];
      toast.error(VIDEO_UPLOADING_ERROR_TEXT, TOAST_CONFIG);
      dispatch(deleteVideos({ postIds }, isOrigin));
    });
  };

export const fetchVideos = () =>
  (dispatch) => {
    dispatch(fetchVideosAction());
    videosApi.fetchVideos().then((videos) => {
      dispatch(fetchVideosActionSuccess(videos));
    }).catch((error) => {
      dispatch(fetchVideosActionError(parseInputErrors(error)));
    });
    return videosApi.fetchCustomVideos().then((customVideos) => {
      dispatch(fetchCustomVideosActionSuccess(customVideos));
    }).catch((error) => {
      dispatch(fetchCustomVideosActionError(parseInputErrors(error)));
    });
  };

export const fetchVideo = postId =>
  (dispatch) => {
    dispatch(fetchVideoAction());
    videosApi.fetchVideo(postId).then(({ post }) => {
      dispatch(fetchVideoActionSuccess(post));
    }).catch(({ error }) => {
      dispatch(fetchVideoActionError(parseInputErrors(error)));
    });
  };

export const fetchCustomVideo = postId =>
  (dispatch) => {
    dispatch(fetchVideoAction());
    videosApi.fetchCustomVideo(postId).then(({ customVideo }) => {
      dispatch(fetchVideoActionSuccess(customVideo));
    }).catch(({ error }) => {
      dispatch(fetchVideoActionError(parseInputErrors(error)));
    });
  };

export const checkUploadingVideos = videos =>
  (dispatch) => {
    videosApi.videosState(videos).then((savedVideos) => {
      dispatch(checkUploadingVideosSuccess(savedVideos));
    }).catch((error) => {
      dispatch(checkUploadingVideosError(parseInputErrors(error)));
    });
  };

export const checkUploadingCustomVideos = videos =>
  (dispatch) => {
    videosApi.customVideosState(videos).then((savedVideos) => {
      dispatch(checkUploadingCustomVideosSuccess(savedVideos));
    }).catch((error) => {
      dispatch(checkUploadingVideosError(parseInputErrors(error)));
    });
  };

export const shareVideos = videos =>
  (dispatch) => {
    shareVideosAction();
    return videosApi.shareVideos(videos).then(() => {
      dispatch(shareVideosActionSuccess());
      return true;
    }).catch(({ error }) => {
      dispatch(shareVideosActionError(parseInputErrors(error)));
      return false;
    });
  };

export const uploadVideoStream = blob =>
  (dispatch) => {
    uploadVideoStreamAction();
    return videosApi.uploadBlob(blob).then(() => {
      dispatch(uploadVideoStreamActionSuccess());
      return true;
    }).catch(({ error }) => {
      dispatch(uploadVideoStreamActionError(parseInputErrors(error)));
      return false;
    });
  };
