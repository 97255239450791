import React, { Component } from 'react';
import { connect } from 'react-redux';
import { array, func, object, bool, number } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import routes from 'constants/routesPaths';
import { fetchQuestions, pickOneQuestion, emptyQuestionsToRecord, removeSingleQuestion } from 'actions/questionsActions';
import { REPLACE_QUESTION_TEXT } from 'constants/constants';
import tickImg from 'assets/icons/added.svg';
import Loading from '../common/Loading';
import ConfirmModal from '../common/ConfirmModal';
import Button from '../common/Button';

class PickQuestions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      openModal: false
    };

    this.handleToggle = this.handleToggle.bind(this);
    this.goToRecordVideoPage = this.goToRecordVideoPage.bind(this);
    this.checkBeforeContinue = this.checkBeforeContinue.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillMount() {
    const { fetchQuestions, removeSingleQuestion } = this.props;
    fetchQuestions().then(() => {
      const { questions, singleSelectedQuestion: { id } } = this.props;
      if (id && !questions.some(item => item.id === id)) {
        removeSingleQuestion();
      }
    });
  }

  componentWillUnmount() {
    const { removeSingleQuestion, history } = this.props;
    if (history.location.pathname !== routes.answerQuestions) {
      removeSingleQuestion();
    }
  }

  handleToggle(id) {
    const { error } = this.state;
    const { pickOneQuestion } = this.props;
    error && this.setState({ error: false });
    pickOneQuestion(id);
  }

  checkBeforeContinue() {
    const { singleSelectedQuestion } = this.props;

    if (isEmpty(singleSelectedQuestion)) {
      this.setState({ error: true });
    } else {
      this.goToRecordVideoPage();
    }
  }

  goToRecordVideoPage() {
    const { history, emptyQuestionsToRecord } = this.props;
    const { openModal } = this.state;
    openModal && this.setState({ openModal: false });
    emptyQuestionsToRecord();
    history.push(routes.answerQuestions);
  }

  closeModal() {
    this.setState({ openModal: false });
  }

  render() {
    const { loading, questions, singleSelectedQuestion, numberOfQuestions } = this.props;
    const { error, openModal } = this.state;

    return (
      <div className="col-lg-6 col-md-9 col-sm-12 pick-questions-wrapper">
        <div className="card">
          <h1>What story would you like to tell?</h1>
          <div className="card-body">
            {loading ?
              <Loading />
              :
              questions.slice(0, numberOfQuestions).map(({ name, id }, index) => {
                const isSelected = singleSelectedQuestion && singleSelectedQuestion.id === id;
                return (
                  <a key={index} onClick={() => this.handleToggle(id)} >
                    <div className="question-container">
                      <span className={classNames({ selected: isSelected })} >
                        {name}
                      </span>
                      {isSelected && <img className="selected-tick" src={tickImg} alt="status" />}
                    </div>
                    <hr />
                  </a>
                );
              })}
          </div>
          <div className="card-bottom">
            <Button
              className="button btn-blue-lighter my-4"
              onPress={this.checkBeforeContinue}
              text="Continue"
            />
          </div>
          {error && <p className="error">Need to select a question first</p>}
          <ConfirmModal
            isOpen={openModal}
            title={REPLACE_QUESTION_TEXT}
            onConfirm={this.goToRecordVideoPage}
            onReject={this.closeModal}
          />
        </div>
      </div>
    );
  }
}

PickQuestions.propTypes = {
  questions: array,
  loading: bool.isRequired,
  fetchQuestions: func.isRequired,
  pickOneQuestion: func.isRequired,
  singleSelectedQuestion: object,
  history: object.isRequired,
  emptyQuestionsToRecord: func,
  numberOfQuestions: number,
  removeSingleQuestion: func
};

const mapState = state => ({
  questions: state.getIn(['questions', 'questionsList']).toJS(),
  loading: state.getIn(['questions', 'loading']),
  singleSelectedQuestion: state.getIn(['questions', 'singleSelectedQuestion']).toJS(),
  authenticated: state.getIn(['session', 'authenticated'])
});

const mapDispatch = dispatch => ({
  fetchQuestions: () => dispatch(fetchQuestions()),
  pickOneQuestion: questionId => dispatch(pickOneQuestion(questionId)),
  emptyQuestionsToRecord: () => dispatch(emptyQuestionsToRecord()),
  removeSingleQuestion: () => dispatch(removeSingleQuestion())
});

export default connect(mapState, mapDispatch)(PickQuestions);
