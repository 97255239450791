import React from 'react';
import ReactModal from 'react-modal';
import confirmImg from 'assets/images/confirm.png';
import Button from './Button';

const RemindConfirmModal = ({ isOpen, onClose }) => (
    <ReactModal isOpen={isOpen} ariaHideApp={false} className="modal-opened remind-confirm-modal">
      <div className="content">
        <img src={confirmImg} alt="confirm_icon" />
        <p className="text">Your reminder has been sent.</p>
        <div className="actions">
          <Button
            text="Close"
            className="btn-blue"
            onPress={onClose}
          />
        </div>
      </div>
    </ReactModal>
  );

export default RemindConfirmModal;
