import React, { createRef, Component } from 'react';
import { func, bool, array } from 'prop-types';
import ReactModal from 'react-modal';
import ReactGA from 'react-ga';
import validate from 'validate.js';
import { parseInputErrors } from 'utils/helpers';
import { login } from 'utils/constraints';
import Loading from 'components/common/Loading';
import {
  injectIntl,
  intlShape,
  defineMessages,
} from 'react-intl';
import Button from './Button';
import ShareVideoForm from './../video/ShareVideoForm';

const message = defineMessages({
  duplicateEmail: 'email.duplicate',
  emptyEmail: 'email.empty'
});

class ShareModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emails: [],
      error: ''
    };

    this.shareFormRef = createRef();
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleDeleteEmail = this.handleDeleteEmail.bind(this);
  }

  onChangeForm = () => {
    const { error } = this.state;
    error && this.setState({ error: '' });
  }

  validations(email) {
    let err = parseInputErrors(validate.single(email, login.email));
    if (err) {
      this.setState({ error: err });
      return err;
    }
    err = this.isDuplicated(email);
    return err;
  }

  isDuplicated(newEmail) {
    const { emails } = this.state;
    if (emails.findIndex(email => email === newEmail) !== -1) {
      this.setState({ error: message.duplicateEmail });
      return true;
    }
    return false;
  }

  handleFormSubmit(email) {
    const { emails } = this.state;
    const error = this.validations(email);
    if (!error) {
      this.setState({
        emails: [...emails, email],
        error: ''
      });
      this.clearFormInput();
    }
  }

  handleShareVideo = () => {
    const { handleShare, shareableVideos } = this.props;
    const { emails } = this.state;

    if (emails.length) {
      const postIds = shareableVideos.map(({ id, question }) => {
        ReactGA.event({
          category: 'Video',
          action: 'Shared a Video',
          value: question
        });
        return id;
      });
      handleShare({ emails, postIds });
    } else {
      this.setState({ error: message.emptyEmail });
    }
  }

  clearFormInput = () => {
    const { current: { refs: { wrappedInstance } } } = this.shareFormRef;
    wrappedInstance.clearInput();
  };

  handleDeleteEmail(email) {
    const { emails } = this.state;
    const filteredEmails = emails.filter(currentEmail => currentEmail !== email);
    this.setState({ emails: filteredEmails });
  }

  render() {
    const { shareModal, intl, toggleShareModal, loading } = this.props;
    const { emails, error } = this.state;

    const DisplayEmails = () =>
      <div className="email-section">
        <span className="share-with">Add email(s):</span>
        {emails.map(email =>
          <span key={email} className="email-chip">
            {email}
            <Button className="btn-close" text="x" onPress={() => this.handleDeleteEmail(email)} />
          </span>)
        }
      </div>;

    return (
      <ReactModal isOpen={shareModal} ariaHideApp={false} className="modal-opened">
        <div className="share-modal-container">
          <h1>Share your video</h1>
          <div className="main-section">
            <DisplayEmails />
            <ShareVideoForm
              handleSubmitCallback={this.handleFormSubmit}
              onChangeForm={this.onChangeForm}
              error={error}
              ref={this.shareFormRef}
            />
            <p className="error-message">{error && intl.formatMessage({ id: `${error}` })}</p>
            <div className="share-group-buttons">
              {loading ?
                <Loading />
                :
                <Button
                  text="Done"
                  className="btn-blue"
                  disabled={emails.length < 1}
                  onPress={this.handleShareVideo}
                />
              }
              <Button
                text="Cancel"
                className="dashboard btn-cancel"
                onPress={toggleShareModal}
              />
            </div>
          </div>
        </div>
      </ReactModal>
    );
  }
}

ShareModal.propTypes = {
  handleShare: func.isRequired,
  intl: intlShape.isRequired,
  shareModal: bool.isRequired,
  loading: bool.isRequired,
  shareableVideos: array,
  toggleShareModal: func.isRequired
};

export default (injectIntl(ShareModal));
