import React, { PureComponent } from 'react';
import { func, bool, string } from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import { Link } from 'react-router-dom';
import {
  injectIntl,
  intlShape,
  defineMessages,
  FormattedMessage
} from 'react-intl';
import routes from 'constants/routesPaths';
import Loading from 'components/common/Loading';
import Input from 'components/common/Input';
import Button from 'components/common/Button';
import { validations, signUp } from 'utils/constraints';

const messages = defineMessages({
  firstName: { id: 'signup.form.name' },
  email: { id: 'login.form.email' },
  password: { id: 'login.form.password' },
  passConfirmation: { id: 'signup.form.passconfirmation' }
});

class SignUpForm extends PureComponent {
  render() {
    const { handleSubmit, submitting, intl, error, titleId, buttonText } = this.props;

    return (
      <div className="login-form-container">
        <div className="">
          <div className="login-card">
            <FormattedMessage id={titleId}>
              {txt => (<h2>{txt}</h2>)}
            </FormattedMessage>
            <hr />
            <form onSubmit={handleSubmit} className="form-wrapper" >
              {error && <span className="error-message">{error}</span>}
              <div className="input-container">
                <p className="form-field-name">Name</p>
                <Field
                  name="firstName"
                  placeholder={intl.formatMessage(messages.firstName)}
                  component={Input}
                  type="text"
                  autoComplete="firstName"
                />
              </div>
              <div className="input-container">
                <p className="form-field-name">Email</p>
                <Field
                  name="email"
                  placeholder={intl.formatMessage(messages.email)}
                  component={Input}
                  type="email"
                  autoComplete="email"
                />
              </div>
              <div className="input-container">
                <p className="form-field-name">Password</p>
                <Field
                  name="password"
                  placeholder={intl.formatMessage(messages.password)}
                  component={Input}
                  type="password"
                  autoComplete="new-password"
                />
              </div>
              <div className="input-container">
                <p className="form-field-name">Confirm Password</p>
                <Field
                  name="passwordConfirmation"
                  placeholder={intl.formatMessage(messages.passConfirmation)}
                  component={Input}
                  type="password"
                  autoComplete="confirm-password"
                />
              </div>
              <div className="input-container pb-1">
                <p className="already-account-text m-0">
                  <input type="checkbox" style={{ borderColor: 'black' }} /><span style={{ marginLeft: 7 }}>I agree to the </span> <Link className="sign-in-text" to={routes.termsandcondition}>Terms of Service</Link>
                </p>
              </div>
              <div className="input-container">
                <p className="already-account-text m-0">
                  <Field
                    name="subscribeStatus"
                    placeholder={intl.formatMessage(messages.passConfirmation)}
                    component={Input}
                    type="checkbox"
                    autoComplete="confirm-password"
                    text="I agree to receive Megilla updates"
                  />
                </p>
              </div>
              <Button text={buttonText} className="btn-blue" type="submit" />
              <p className="already-account-text">
                Already have an account? <Link className="sign-in-text" to={routes.login}>Sign in</Link>
              </p>
              {submitting && <Loading />}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

SignUpForm.propTypes = {
  handleSubmit: func.isRequired,
  submitting: bool.isRequired,
  intl: intlShape.isRequired,
  error: string,
  titleId: string.isRequired,
  buttonText: string.isRequired
};

export default reduxForm({
  form: 'signUp',
  validate: validations(signUp, { fullMessages: false })
})(injectIntl(SignUpForm));
