import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { Field, reduxForm } from 'redux-form/immutable';
import withHeader from 'components/common/WithHeader';
import Input from 'components/common/Input';
import Button from 'components/common/Button';
import { createCustomQuestion } from 'actions/questionsActions';
import routes from 'constants/routesPaths';

const CreateQuestionPage = ({ history, createQuestion }) => {
  const handleSubmit = (e) => {
    ReactGA.event({
      category: 'Custom Question',
      action: 'Created a Custom Question',
      value: e.target.elements.question.value
    });

    createQuestion({
      question: e.target.elements.question.value,
      receiver: e.target.elements.email.value
    });
    history.push(routes.myQuestions);
    e.preventDefault();
  };
  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-8">          
          <div className="login-form-container create-question">
            <div className="card">
              <div className="card-body">
                <h2>Create a Question</h2>

                <form onSubmit={handleSubmit} className="form-wrapper">
                  <div className="input-container">
                    <p>Type a question</p>
                    <Field
                      name="question"
                      placeholder="i.e. What did you and dad do on your first date?"
                      component={Input}
                      type="text"
                    />
                  </div>
                  <div className="input-container mb-1">
                    <p>Who do  you want to send this to?</p>
                    <Field
                      name="email"
                      placeholder="i.e. mom@gmail.com"
                      component={Input}
                      type="email"
                    />
                  </div>
                  <h3 className="text-center mb-3">(For now, you can only send one question to one person at a time.)</h3>
                  <Button text="Send" className="btn-green" type="submit" />
                  <Button text="< Go back" className="link" onPress={handleGoBack} />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  createQuestion: params => dispatch(createCustomQuestion(params))
})
export default reduxForm({
  form: 'createQuestion',
})(withHeader(connect(null, mapDispatchToProps)(CreateQuestionPage)));
