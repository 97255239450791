import React, { Component } from 'react';
import { connect } from 'react-redux';
import { func, array } from 'prop-types';
import withHeader from 'components/common/WithHeader';
import SavedQuestions from 'components/myList/SavedQuestions';
import QuestionsFooter from 'components/questionMenu/QuestionsFooter';
import routes from 'constants/routesPaths';
import history from 'utils/history';
import { removeSingleQuestion } from 'actions/questionsActions';

class MyListPage extends Component {
  answerQuestions = () => {
    const { removeSingleQuestion } = this.props;
    removeSingleQuestion();
    history.push(routes.answerQuestions);
  }

  render() {
    const { questionsToRecordList } = this.props;
    return (
      <div className="question-menu-page container full-height">
        <SavedQuestions />
        <div className="footer-row">
          <QuestionsFooter quantity={questionsToRecordList.length} startRecording={this.answerQuestions} />
        </div>
      </div>
    );
  }
}

MyListPage.propTypes = {
  removeSingleQuestion: func,
  questionsToRecordList: array
};

const mapState = state => ({
  questionsToRecordList: state.getIn(['questions', 'questionsToRecordList']).toJS()
});

const mapDispatch = ({
  removeSingleQuestion
});

export default connect(mapState, mapDispatch)(withHeader(MyListPage));
