import React, { PureComponent } from 'react';
import { WEBRTC_CAMERA_OPTIONS } from '../constants/constants';
import { isSafari } from '../utils/helpers';

const hasGetUserMedia = !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
let isComponentMounted = false;

class VideoWebRTC extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			isVideoPlaying: false,
			isVideoRecording: false,
			isVideoUploaded: false,
		};
	}

	getInitialState = () => ({
		detectedWebRtcError:        false,

		cameraStream:               null,
		mediaRecorder:              null,
		blobParts:                  [],
		blob:                       null,

		startTime:                  null,
		stopTime:                   null,
		stopTimeMax:                null,

		isVideoPlaying:             false,
		isVideoRecording:           false,
		isVideoRecorded:            false,
		isVideoSubmitted:           false,
		isVideoUploaded:            false,

		maxDurationInSeconds:       180,
		secondsRemaining:           180,
		timerIntervalId:            null,
	});

	async componentDidMount() {
		isComponentMounted = true;

		if (!hasGetUserMedia) {
			alert("Your browser cannot stream from your webcam. Please switch to Chrome or Firefox.");
			return;
		}

		const cameraStream = await this.createCameraStream();
		this.setState({ ...this.getInitialState(), cameraStream }, () => {
			this.recorder.srcObject = this.state.cameraStream;
			this.recorder.play();
		});
	}

	componentDidUpdate(prevProps, prevState) {
		const { isStarted, isFinished } = this.props;
		const { secondsRemaining } = this.state;


		if (isStarted && isStarted !== prevProps.isStarted) {
			this.startRecord();
		}

		if (isFinished && isFinished !== prevProps.isFinished) {
			this.stopRecorder();
		}

		if(prevState.secondsRemaining === 1 && secondsRemaining === 0){
			this.stopRecorder();
		}
	}

	captureUserMedia(callback) {
		var params = { audio: true, video: true };

    if (this.isSafari()) {
      navigator.mediaDevices.getUserMedia(params).then(callback).catch((error) => {
        alert(JSON.stringify(error));
      });
    } else {
      navigator.getUserMedia(params, callback, (error) => {
        alert(JSON.stringify(error));
      });
    }

	}

	async componentWillUnmount() {
		isComponentMounted = false;
		await this.pauseRecorder();
		this.releaseTracks();

		this.stopTimer();
	}

	createMediaRecorder = () => {
		const targetBitrate = 6000000;
		const codec         = this.findBestSupportedCodec();
	
		if (codec === null) {
			throw new Error('Supported codecs not found');
		}

		const options = { videoBitsPerSecond: targetBitrate, mimeType: codec };
		return new MediaRecorder(this.state.cameraStream, options);
	};

	findBestSupportedCodec = () => {
		const codecs = ['video/webm;codecs=h264', 'video/webm'];
		let bestCodec = null;

		codecs.forEach((codec) => {
			if ((bestCodec === null) && MediaRecorder.isTypeSupported(codec)) {
				bestCodec = codec;
			}
		});

		return bestCodec;
	};

	stopTimer = () => {
		clearInterval(this.state.timerIntervalId);
	};

	resetStateForNewRecording = () => {
		const initialState = this.getInitialState();

		this.setState({
			blobParts:          initialState.blobParts,
			isVideoRecording:   initialState.isVideoRecording,
			startTime:          initialState.startTime,
			secondsRemaining:   initialState.secondsRemaining,
			timerIntervalId:    initialState.timerIntervalId
		})
	};

	updateSecondsRemaining = () => {
		const now   = new Date().getTime();
		let   delta = (now - this.state.startTime);
		delta = this.state.maxDurationInSeconds - Math.floor((delta / 1000));
		if (delta !== this.state.secondsRemaining){
			this.setState({ secondsRemaining: delta });
		}
	};

	startTimer = () => {
		const isVideoRecording    = true;
		const startTime           = new Date().getTime();
		const timerIntervalId     = setInterval(this.updateSecondsRemaining, 100);
		this.setState({ startTime, isVideoRecording, timerIntervalId });
	};

	createCameraStream = async () => {
		try {
			return await window.navigator.mediaDevices.getUserMedia(WEBRTC_CAMERA_OPTIONS);
		} catch (error) {
			console.log('WebRTC Error', error);
		}
	};

	releaseTracks = () => {
		const { cameraStream } = this.state;

		if (cameraStream && cameraStream.active) {
			const tracks = cameraStream.getTracks();
			tracks.forEach(track => track.stop());
		}
	};

	stopVideoPlaying = () => {
		this.setState({ isVideoPlaying: false });
	};

	attachRef = (ref) => {
		this.recorder = ref;
	};

	handleLoadedData = () => {
		this.recorder.currentTime = 0.01;
	};

	togglePlayButton = async () => {
		if (this.state.isVideoUploaded || this.state.isVideoRecording || !this.recorder.src){
				return;
		}
		await (this.state.isVideoPlaying) ? this.pausePlayer() : this.playPlayer();
	};

	pausePlayer = async () => {
		await this.setState({ isVideoPlaying: false }, async () => {
			try {
				await this.recorder.pause();
			}
			catch(e){
				console.log('WebRTC Error', e);
			}
		});
	};

	playPlayer = async () => {
		await this.setState({ isVideoPlaying: true }, async () => {
			try {
				await this.recorder.play();
			}
			catch(e){
				console.log('WebRTC Error', e);
			}
		});
	};

	acceptVideo = () => {
		const blobParts = this.state.blobParts.slice();
		const type      = this.state.mediaRecorder.mimeType.slice();
		const blob      = new Blob(blobParts, { type });

		this.setState({ isVideoRecording: false, blob }, () => {
			try {
				this.recorder.srcObject = null;
				this.recorder.src = window.URL.createObjectURL(this.state.blob);
				this.submitVideo();
			}
			catch(e){
				console.log('WebRTC Error', e);
			}
		});
	};

	rejectVideo = () => {
		this.resetStateForNewRecording();
	};

	acceptOrRejectVideo = () => {
		const hasBlobParts      = (this.state.blobParts.length > 0);
		const shouldRejectVideo = hasBlobParts === false;

		(shouldRejectVideo) ? this.rejectVideo() : this.acceptVideo();
	};

	submitVideo = async () => {
		try {
			const { blob, isVideoPlaying } = this.state;
			const { onFinished }           = this.props;

			if (isVideoPlaying){
				await this.togglePlayButton();
			}

			onFinished(blob.slice());
		}
		catch(e){
			console.log('WebRTC Error', e);
		}
	};

	initializeRecorder = () => {
		try {
			const mediaRecorder = this.createMediaRecorder();

			mediaRecorder.ondataavailable = (event) => {
				if (event.data && event.data.size > 0 && isComponentMounted) {
					this.setState({ blobParts: [...this.state.blobParts, event.data] });
				}
			};

			mediaRecorder.onstart = () => {
				this.startTimer();
			};

			mediaRecorder.onstop = () => {
				this.stopTimer();
				this.acceptOrRejectVideo();
			};

			mediaRecorder.onerror = (e) => {
				console.log('WebRTC Error', e);
			};

			// the value in mediaRecorder.start() refers to the number of ms to record into each blob
			this.setState({ mediaRecorder }, () => this.state.mediaRecorder.start(10));
		} catch (error) {
			console.log('WebRTC Error', error);
		}
	};

	startRecord = () => {
		this.initializeRecorder();
	}

	stopRecorder = () => {
		if (this.state.mediaRecorder && this.state.mediaRecorder.state === 'recording') {
			this.state.mediaRecorder.requestData();
			this.state.mediaRecorder.stop();
		}
	};

	pauseRecorder = async () => {
		if (this.state.mediaRecorder && this.state.mediaRecorder.state === 'recording') {
			await this.state.mediaRecorder.pause();
		}
	};

	render() {
		return (
			<div className="video-web-rtc">
				<video
					playsInline muted
					style={{ height: '65vh' }}
					onClick={this.togglePlayButton}
					onLoadedData={this.handleLoadedData}
					onEnded={this.stopVideoPlaying}
					ref={this.attachRef}
				/>
				{this.props.children}
			</div>
		)
	}
}

export default VideoWebRTC;
