import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import withHeader from 'components/common/WithHeader';
import Input from 'components/common/Input';
import Button from 'components/common/Button';
import routes from 'constants/routesPaths';

const SendQuestionPage = ({ history }) => {
  const handleSendExistingQuestion = () => {
    history.push(routes.questionMenu);
  };
  const handleCreateQuestion = () => {
    history.push(routes.createQuestion);
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-8">          
          <div className="login-form-container">
            <div className="card">
              <div className="card-body">
                <h2>Send a question to someone!</h2>
                <div className="card-content">
                  <p>Would you like to send an existing question or create your own?</p>
                  <Button text="Send an existing question" className="btn-blue" onPress={handleSendExistingQuestion} />
                  <Button text="Create my own question" className="btn-green" onPress={handleCreateQuestion} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withHeader(SendQuestionPage);
