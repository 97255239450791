import { Iterable } from 'immutable';
import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
import randToken from 'rand-token';
import { USER_QUESTION_STATES } from 'constants/constants';

const localForage = require('localforage');

const storage = localForage.createInstance({ name: 'redux-react-session' });

const browserHeaders = [
    navigator.platform,
    navigator.userAgent,
    navigator.appVersion,
    navigator.vendor,
    window.opera
];
const osData = [
    { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
    { name: 'Windows', value: 'Win', version: 'NT' },
    { name: 'iPhone', value: 'iPhone', version: 'OS' },
    { name: 'iPad', value: 'iPad', version: 'OS' },
    { name: 'Kindle', value: 'Silk', version: 'Silk' },
    { name: 'Android', value: 'Android', version: 'Android' },
    { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
    { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
    { name: 'Macintosh', value: 'Mac', version: 'OS X' },
    { name: 'Linux', value: 'Linux', version: 'rv' },
    { name: 'Palm', value: 'Palm', version: 'PalmOS' }
];
const browserData = [
    { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
    { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
    { name: 'Safari', value: 'Safari', version: 'Version' },
    { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
    { name: 'Opera', value: 'Opera', version: 'Opera' },
    { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
    { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
];

const matchItem = (string, data) => {    
    for (let i = 0; i < data.length; i += 1) {
        const regex = new RegExp(data[i].value, 'i');
        const match = regex.test(string);
        if (match) {
            const regexv = new RegExp(`${data[i].version  }[- /:;]([\\d._]+)`, 'i');
            let matches = string.match(regexv);
            let version = '';
            if (matches) {
                if (matches[1]) {
                    // eslint-disable-next-line prefer-destructuring
                    matches = matches[1];
                }
            }
            if (matches) {
                matches = matches.split(/[._]+/);
                for (let j = 0; j < matches.length; j += 1) {
                    if (j === 0) {
                        version += `${matches[j]  }.`;
                    } else {
                        version += matches[j];
                    }
                }
            } else {
                version = '0';
            }
            return {
                name: data[i].name,
                version: parseFloat(version)
            };
        }
    }
    return { name: 'unknown', version: 0 };
};

export const isIos = () => {
  const agent = browserHeaders.join(' ');
  const iOSStrings = ['iPhone', 'iPad', 'Macintosh'];
  const osInfo = matchItem(agent, osData);

  return iOSStrings.includes(osInfo.name);
};

export const parseInputErrors = (error) => {
  if (!error) {
    return;
  }
  if (Iterable.isIterable(error)) {
    return error.first();
  } else if (Array.isArray(error)) {
    return error[0];
  }
  return error;
};

export const applyQueryParams = (url, params = {}) => {
  if (isEmpty(params)) {
    return url;
  }
  const queryParams = queryString.stringify(params);
  return `${url}?${queryParams}`;
};

export const routeWithProps = (route, props) => {
  let newRoute = route;
  Object.keys(props).forEach((prop) => {
    newRoute = newRoute.replace(`:${prop}`, props[prop]);
  });
  return newRoute;
};

export const getGuestToken = () => (
  storage.getItem('GUEST-TOKEN')
);

export const removeGuestToken = () => (
  storage.removeItem('GUEST-TOKEN')
);

export const createAndSaveGuestToken = () => {
  const guestToken = randToken.generate(24);
  storage.setItem('GUEST-TOKEN', guestToken);
  return guestToken;
};

export const saveResetPasswordToken = (token) => {
  storage.setItem('RESET-PASSWORD-TOKEN', token);
};

export const removeResetPasswordToken = () => (
  storage.removeItem('RESET-PASSWORD-TOKEN')
);

export const getResetPasswordToken = () => (
  storage.getItem('RESET-PASSWORD-TOKEN')
);

export const formatDate = (date) => {
  const parseDate = new Date(date);
  return (
    new Intl.DateTimeFormat('en-US', {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit'
    }).format(parseDate)
  );
};

// HOMEPAGE Scroll down icon responsive breakpoints

export const secondSectionPosition = (width) => {
  if (width >= 2000) {
    return '98rem';
  }else if (width >= 1506 && width < 2000) {
    return '101rem';
  }
   else if (width >= 1014 && width < 1506) {
    return '105rem';
  } else if (width >= 992 && width < 1014) {
    return '109rem';
  }
};

export const thirdSectionPosition = (width) => {
  if (width >= 2000) {
    return '176rem';
  } else if (width >= 1506 && width < 2000) {
    return '179rem';   
  } else if (width >= 1150 && width < 1506) {
    return '183rem';
  } else if (width >= 1020 && width < 1150) {
    return '187rem';
  } else if (width >= 992 && width < 1020) {
    return '190rem';
  }
};

export const filterSelectedItems = list => list.filter(question => question.get('state') === USER_QUESTION_STATES.selected);

export const truncateText = question => (question.length > 65 ? question.slice(0, 65).concat('...') : question);

export const isSafari = () => {
  var ua = navigator.userAgent.toLowerCase();
  return (ua.indexOf('safari') != -1 && ua.indexOf('chrome') == -1 && ua.indexOf('CriOS') === -1 && ua.indexOf('FxiOS') === -1);
}

export function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
}

export const checkCameraMicPermission = (onSuccess = () => {}, onFailure = () => {}) => {
  const constraints = {
      audio: true,
      video: true
  };

  navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;

  if (navigator.getUserMedia) {	
      navigator.getUserMedia(
          constraints,
          onSuccess,
          onFailure,	
      );
  } else if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          navigator.mediaDevices
              .getUserMedia(constraints)
              .then(onSuccess)
              .catch(onFailure);
      } else {
          console.log('Your browser does not support camera features. Please check the camera permissions in your browser settings.');
      }
};

/* download video */
export const downloadVideo = (videoLink, fileName) => {
  console.log(fileName)
  const link = document.createElement('a');
  link.href = videoLink;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  link.remove();
};
