import api from 'api/apiService';
import { USER_QUESTION_STATES } from '../constants/constants';

class Questions {
  static fetchQuestions() {
    return api.get('/questions?random=true');
  }

  static checkIfAlreadyAnswered(questionId) {
    return api.get(`/posts?question_id=${questionId}`);
  }

  static addUserQuestion(params) {
    return api.post('/user_questions', params);
  }

  static removeUserQuestion(userQuestion) {
    return api.delete('/user_questions', userQuestion);
  }

  static updateUserQuestions(id, question) {
    return api.put(`/user_questions/${id}`, question);
  }

  static getQuestionsToRecord() {
    return api.get(`/user_questions?state=${USER_QUESTION_STATES.selected}`);
  }

  static getPendingQuestions() {
    return api.get(`/user_questions?state=${USER_QUESTION_STATES.pending}`);
  }

  static getUserQuestions() {
    return api.get('/user_questions');
  }

  static getCustomQuestions() {
    return api.get('/custom_questions');
  }

  static createCustomQuestion(params) {
    return api.post('/custom_questions', params);
  }

  static remindCustomQuestion(params) {
    return api.post('/remind_question', params);
  }
}

export default Questions;
