import React, { PureComponent } from 'react';
import { func, bool, string } from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import { Link } from 'react-router-dom';
import {
  injectIntl,
  intlShape,
  defineMessages,
  FormattedMessage
} from 'react-intl';
import routes from 'constants/routesPaths';
import Loading from 'components/common/Loading';
import Input from 'components/common/Input';
import Button from 'components/common/Button';
import { validations, resetPassword } from 'utils/constraints';

const messages = defineMessages({
  password: { id: 'login.form.password' },
  passConfirmation: { id: 'signup.form.passconfirmation' }
});

class ResetPasswordForm extends PureComponent {
  render() {
    const { handleSubmit, submitting, intl, error, titleId } = this.props;

    return (
      <div className="login-form-container">
        <div className="">
          <div className="login-card">
            <FormattedMessage id="forgotPassword.title">
              {txt => (<h2>{txt}</h2>)}
            </FormattedMessage>
            <hr />
            <form onSubmit={handleSubmit} className="form-wrapper p-0" >
              {error && <span className="error-message">{error}</span>}
              <div className="form-text input-container m-0 w-75">
                Your password needs to have at least one symbol or number, and have at least 8 characters.
              </div>
              <div className="input-container w-75">
                <p className="form-field-name">Password</p>
                <Field
                  name="password"
                  placeholder={intl.formatMessage(messages.password)}
                  component={Input}
                  type="password"
                  autoComplete="new-password"
                />
              </div>
              <div className="input-container w-75">
                <p className="form-field-name">Confirm Password</p>
                <Field
                  name="passwordConfirmation"
                  placeholder={intl.formatMessage(messages.passConfirmation)}
                  component={Input}
                  type="password"
                  autoComplete="confirm-password"
                />
              </div>
              <Button text="Submit" className="btn-blue w-75" type="submit" />
              <div className="form-text input-container m-0 w-75">
                By clicking “Submit”, you confirm that you accept the <Link className="sign-in-text" to={routes.termsandcondition}>Terms of Service</Link> & <Link className="sign-in-text" to={routes.privacypolicy}>Privacy Policy</Link>.
              </div>
              {submitting && <Loading />}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

ResetPasswordForm.propTypes = {
  handleSubmit: func.isRequired,
  submitting: bool.isRequired,
  intl: intlShape.isRequired,
  error: string,
  titleId: string.isRequired,
};

export default reduxForm({
  form: 'resetPassword',
  validate: validations(resetPassword, { fullMessages: false })
})(injectIntl(ResetPasswordForm));
