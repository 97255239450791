import React from 'react';
import { string, func, bool } from 'prop-types';
import ReactModal from 'react-modal';
import Button from './Button';

const ConfirmModal = ({ isOpen, title, description, onConfirm, onReject }) => (
  <ReactModal isOpen={isOpen} ariaHideApp={false} className="modal-opened confirm-modal">
    <div className="m-auto">
      <h1 className="title confirm">{title}</h1>
      {description && <p className="description">{description}</p>}
      <div className="my-4">
        <Button
          text="No"
          className="btn-modal btn-no"
          onPress={onReject}
        />
        <Button
          text="Yes"
          className="btn-modal btn-yes"
          onPress={onConfirm}
        />
      </div>
    </div>
  </ReactModal>
);

ConfirmModal.propTypes = {
  isOpen: bool.isRequired,
  title: string.isRequired,
  onConfirm: func.isRequired,
  onReject: func.isRequired,
  description: string,
};

export default ConfirmModal;
