import React, { Component, Fragment } from 'react';
import WithHeader from "../components/common/WithHeader";

class FAQsPage extends Component {
  componentDidMount = () => window.scrollTo(0, 0);

  render() {
    return (
      <Fragment>
        <div className="question-menu-cards-container">
          <div className="privacy-container">
            <h3 className="privacy-title">PRIVACY POLICY</h3>
            <div style={{ marginTop: 20, marginLeft: '5%', marginRight: '8%' }}>
              <h2 style={{ fontSize: 15, marginTop: 0 }}>
                <span style={{ fontWeight: "normal" }}>Megilla, Co. (</span>"<span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>Megilla," "we," or "our"</span><span style={{ fontWeight: "normal" }}>) recognizes the importance of your personal privacy.</span>
              </h2><h2 style={{ fontSize: 15, marginTop: 0, marginTop: 18 }}>
                <span style={{ fontWeight: "normal" }}>Therefore, we have created this privacy policy so that you know how we use and disclose your information when you make it available to us (</span><span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>“Privacy Policy”</span><span style={{ fontWeight: "normal" }}>).  The Privacy Policy below discloses our practices regarding information collection and usage for the Megilla website and mobile website (the </span><span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>“Websites”</span><span style={{ fontWeight: "normal" }}>), and the service provided thereon (the </span><span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>“Service”</span><span style={{ fontWeight: "normal" }}>).</span></h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>By using or accessing the Websites and the Service, you signify your agreement to be bound by this Privacy Policy. IF YOU DO NOT AGREE TO THIS PRIVACY POLICY YOU MAY NOT ACCESS OR OTHERWISE USE OUR SERVICE OR PARTICIPATE IN OUR SERVICES.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>Key aspects of our privacy practices described in this Privacy Policy include the following explanations:</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>•	The information we collect and why we collect it .</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>•	How we use that information;</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>•	How we share information; and</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>•	The choices we offer.</h2>
              <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>1.	PERSONALLY IDENTIFIABLE INFORMATION THAT WE COLLECT.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>You may be required to submit certain personally identifiable information when you register on the Service. The personally identifiable information which you may provide to us could include your email, name, gender, birthday, payment information, phone number, social media handles, and other personal information.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>You may always choose not to provide personal information, but if you so choose, certain products and services may not be available to you.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>As is true of most websites, when you access our Service, we may automatically collect non-personally identifiable information from you, such as IP host address, web pages viewed, browser type, operating system, referring service, search information, device type, page views, usage and browsing habits on the Service and similar data. We may also aggregate demographic information collected from our users (such as the number of users in a particular geographical location) in a manner which does not identify any one individual. We may also aggregate information collected offline in connection with the Service, obtain non-personally identifiable information from third-party sources and develop aggregate information by anonymizing previously collected personally identifiable information.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>It is possible at times when collecting non-personally identifiable information through automatic means that we may unintentionally collect or receive personally identifiable information that is mixed in with the non-personally identifiable information.  While we will make reasonable efforts to prevent such incidental data collection, the possibility still exists.  If you believe that we have inadvertently collected your personal information, please notify us at help@megilla.com.</h2>
              <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>2.	INFORMATION USAGE.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>We do not share or sell your personal data to third parties without your explicit permission. We will only use your personally identifiable information as described below, unless you have specifically consented to another type of use, either at the time the personally identifiable information is collected from you or through some other form of consent from you or notification to you. We may use or disclose your personally identifiable information as follows:</h2>

              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>•	to respond to your inquires or requests.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>•	to send you emails from time to time about our Service, but we will not provide your personally identifiable information to third parties for them to contact you directly unless otherwise permitted by this Privacy Policy or you provide your consent.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>•	to verify users' identities for security purposes;</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>•	to provide, maintain and improve our Service;</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>•	to provide and deliver products and services you request, process transactions and send you related information, including confirmations and invoices;</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>•	to tailor the content and information that we may send or display to you, to suggest followers and content, to offer location customization, to provide personalized help and instructions and to otherwise personalize your experiences while using the Service;</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>•	to track copyright claims;</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>•	to monitor and analyze trends, usage, and activities in connection with our Service;</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>•	if you submit Content, to identify the content as your Content;</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>•	to communicate with you about products, services, offers, promotions, surveys, and events offered by Megilla and others, your transactions, and provide news and information we think will be of interest to you;</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>•	to facilitate your participation in our interactive features such as a blog or forum;</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>•	Detect, investigate, and prevent fraudulent and illegal activities and protect the rights and property of Megilla and others; and</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>•	Register your rights, if any, to technical support or other benefits that may be made available to registered users.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>•	Respond to customer service requests, questions and concerns.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>•	Carry out any other purpose described to you at the time the information was collected.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>Non-personally identifiable or aggregate information may be used by us for any purposes permitted by law and may be shared with any number of parties, provided that such information shall not specifically identify you. </h2>
              <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>3.	INFORMATION SHARING.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>•	We may share your personally identifiable information collected in connection with providing the Service in accordance with the Terms of Use.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>•	We may share your personally identifiable information with third parties to further the purpose for which you provided such information to us. For example, we may share your information with PayPal, Stripe, Square or another payment processor, as necessary for processing your purchases made online via the Service. We urge you to read the privacy practices of PayPal, Stripe, and Square before submitting any personally identifiable information through the Service.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>•	We may disclose personally identifiable information as required by law or legal process.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>•	We may disclose personally identifiable information to investigate suspected fraud, harassment or other violations of any law, rule or regulation, or the terms or policies for our Services or our sponsors.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>•	We may transfer your personally identifiable information in connection with the sale or merger or change of control of Megilla or the division responsible for the services with which your personally identifiable information is associated.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>•	We may share your personally identifiable information with an affiliate of Megilla who is in the same corporate family as us as long as their privacy practices are substantially similar to ours.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>•	Except as set out in this Privacy Policy, Megilla does not disclose or sell your personal information to any third-parties.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>•	You may share Your Content, including sharing to social media platforms and linked networks. You are responsible for the content you share. You assume all risks related to sharing Your Content, including someone else’s reliance on its accuracy, or claims relating to intellectual property or other legal rights.</h2>
              <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>4.	COOKIES AND SIMILAR TECHNOLOGIES.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}><span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>“Cookies”</span> are pieces of information that may be placed on your computer by a service for the purpose of facilitating and enhancing your communication and interaction with that service. Many services use cookies for these purposes.  We may use cookies (and similar items such as clear gifs, web beacons, tags, etc.) on our Service to customize your visit and for other purposes to make your visit more convenient or to enable us to enhance our Service.  We may also use and place cookies (and similar items) on your computer from our third-party service providers in connection with the Service, such as an analytics provider that helps us manage and analyze Service usage, as described more fully below.  In addition, our advertisers and business partners may set cookies and similar items on your computer when you use our Service.  You may stop or restrict the placement of cookies on your computer or flush them from your browser by adjusting your web browser preferences, in which case you may still use our Service, but it may interfere with some of its functionality. Cookies and similar items are not used by us to automatically retrieve personally identifiable information from your computer without your knowledge.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>If you delete your cookies, change browsers or use a different cookie, our cookie (or an opt-out cookie) may no longer work and you will have to reinput (or opt-out) again.</h2>
              <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>5.	INTEGRATED SERVICE PROVIDER AND LINKED NETWORKS. </h2>
              <h2 style={{ fontSize: 15, marginTop: 0, marginTop: 18 }}><span style={{ fontWeight: "normal" }}>You can elect to sign in or sign up to Megilla through a linked network (e.g. Facebook, or Google), and you can connect your existing Megilla account with certain third-party networks like Facebook, Twitter and LinkedIn (each a </span><span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>“Linked Network”</span><span style={{ fontWeight: "normal" }}>). If you elect to sign up through or connect a Linked Network, we receive certain profile and account information about you from the Linked Network. These Linked Networks may also appear in your profile, so that people can find you on these Linked Networks. The specific information provided to us by Linked Networks is determined by these third parties and may vary by network. In all cases, the permissions page for the Linked Network will describe the information being shared. You should consult their respective privacy policies for information about their practices. You may elect to use information from the Linked Network to populate your Megilla profile. For information on your choices, including how to disconnect a Linked Network from your Megilla profile, see the </span><span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>“Your Choices”</span><span style={{ fontWeight: "normal" }}> section below. You may also elect to connect and make and receive payments to and from use through third party networks (</span><span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>“Integrated Service Provider”</span><span style={{ fontWeight: "normal" }}>); if you do so, you will be allowing us to pass to and receive from the Integrated Service Provider your login information and other user data for payment purposes.</span></h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>You may connect or disconnect your Linked Networks, such as Facebook, through the profile settings in your account, and you may access, amend and delete much of your profile information through your profile settings. Once you disconnect a Linked Network, we will not receive information from that Linked Network going forward; unless you choose to reconnect it. You may also control whether the Linked Network is visible in your profile.</h2>
              <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>6.	AUTOMATICALLY COLLECTED INFORMATION.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, marginTop: 18 }}><span style={{ fontWeight: "normal" }}>When you access the Service or open one of our HTML emails, we may automatically record certain information from your system by using cookies and other types of tracking technologies. This </span><span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>"automatically collected"</span><span style={{ fontWeight: "normal" }}> information may include Internet Protocol address (</span><span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>"IP Address"</span><span style={{ fontWeight: "normal" }}>), a unique user ID, device type, device identifiers, browser types and language, referring and exit pages, platform type, version of software installed, system type, the content and pages that you access on the Service, the number of clicks, the amount of time spent on pages, the dates and times that you visit the Service, and other similar information. Depending on the law of your country of residence, your IP address may legally be considered personally identifiable information.</span></h2>
              <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>7.	ANALYTICS AND CONVERSION TRACKING.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>We use analytics services that use cookies, JavaScript and similar technologies to help us analyze how users use the Service. The information generated by these services about your use of the Service (including your IP address or a truncated version of your IP address) is transmitted to and stored by analytics service providers on their servers. Those service providers will use this information for the purpose of evaluating your, and other users’, use of the Service, compiling reports for us on website activity and providing other services relating to website activity and Internet usage. </h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>We may collect information about your computer, including your IP address, operating system and browser type, for system administration and in order to create reports. This is statistical data about our users’ browsing actions and patterns, and does not identify any individual. We use cookies on our site for Google Analytics. Google Analytics is a web analytics tool that helps website owners understand how visitors engage with their website. Google Analytics customers can view a variety of reports about how visitors interact with their website so that they can improve it. Like many services, Google Analytics uses first-party cookies to track visitor interactions as in our case, where they are used to collect information about how visitors use our site. We then use the information to compile reports and to help us improve our site. Cookies contain information that is transferred to your computer’s hard drive. These cookies are used to store information, such as the time that the current visit occurred, whether the visitor has been to the site before and what site referred the visitor to the web page. Google Analytics collects information anonymously. It reports website trends without identifying individual visitors. You can opt out of Google Analytics without affecting how you visit our site – for more information on opting out of being tracked by Google Analytics across all websites you use, visit https://tools.google.com/dlpage/gaoptout.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>We also use Google conversion tracking and/or similar services to help us understand your and other users’ use of the Service.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>At this time, we do not respond to “Do Not Track” browser settings or signals.</h2>
              <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>8.	SECURITY.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>The security of your personally identifiable information is very important to us. When we collect your personally identifiable information online, we use reasonable efforts to protect it from unauthorized access.  However, due to the inherent open nature of the Internet, we cannot guarantee that your personally identifiable information will be completely free from unauthorized access by third parties such as hackers and your use of our Service demonstrates your assumption of this risk.  We have put in place reasonable physical, electronic, and managerial procedures to safeguard the information we collect. We use secure socket layer technology (SSL) for specific types of information, such as your login and payment information. Only those employees who need access to your information in order to perform their duties are authorized to have access to your personally identifiable information. For more information on protecting your privacy, please visit  www.ftc.gov/privacy.</h2>
              <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>9.	YOUR DISCLOSURES IN BLOGS AND OTHER SOCIAL MEDIA.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>Please be aware that personally identifiable information which you voluntarily include and transmit online on the Service in a publicly accessible area, blog, chat room, social media platform or otherwise online, or that you share in an open forum such as an in-person panel or survey, may be viewed and used by others outside of Megilla’s control.  We are unable to control such uses of your personally identifiable information, and by using the Service or any other online services you assume the risk that the personally identifiable information provided by you may be viewed and used by third parties for any number of purposes.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>If you connect to a Linked Network such as Facebook or Twitter through an icon or link on the Service or otherwise link your Megilla account with a third-party service, we may share the contents of your post and associated information (such as your user name, the fact that your connection originated from the Service, and other relevant usage and diagnostic information) with such third party.  Once such information is shared, the use of your information will be subject to that service's Privacy Policy and this Privacy Policy will not apply.</h2>
              <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>10.	PROTECTION FOR CHILDREN.</h2>
              <h2 style={{ fontSize: 15, marginTop: 0, marginTop: 18 }}><span style={{ fontWeight: "normal" }}>We generally do not collect personally identifiable information from children under the age of 13. If at any time in the future we plan to collect personally identifiable information from children under 13, such collection and use, to the extent applicable, shall, when required, be done in compliance with the Children’s Online Privacy Protection Act (</span><span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>“COPPA”</span><span style={{ fontWeight: "normal" }}>) and appropriate consent from the child’s parent or guardian will be sought where required by COPPA.  When we become aware that personally identifiable information from a child under 13 has been collected without such child’s parent or guardian’s consent, we will use all reasonable efforts to delete such information from our database.</span>
                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>11.	OTHER SERVICES.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>As a convenience to you, we may provide links to third-party services from within our Service. We are not responsible for the privacy practices or content of these third-party sites.  When you link away from our Service, you do so at your own risk.</h2>
                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>12.	CHANGES TO THIS PRIVACY POLICY.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>We reserve the right, at our discretion, to change, modify, add, or remove portions from this Privacy Policy at any time.  However, if at any time in the future we plan to use personally identifiable information in a way that materially differs from this Privacy Policy, including sharing such information with more third parties, we will post such changes here and provide you the opportunity to opt-out of such differing uses.  Your continued use of the Service and our services following the posting of any changes to this Privacy Policy means you accept such changes.</h2>
                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>13.	OPT-OUT PROCESS. </h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>All unsubscribe or opt-out requests may be made by clicking the “unsubscribe” link at the bottom of our email. We will process your request within a reasonable time after receipt. However, we are not responsible for, and in some cases we are incapable of, removing your personally identifiable information from the lists of any third party who has previously been provided your information in accordance with this Privacy Policy or your consent.  You should contact such third parties directly. If you would like to update or correct any personally identifiable information that you have provided to us, please email us at Megilla at help@megilla.com and once we confirm your information, we will update such information within a reasonable amount of time.</h2>
                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>14.	COMMUNICATIONS WITH MEGILLA.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, marginTop: 18 }}><span style={{ fontWeight: "normal" }}>By providing your contact information through channels including but not limited to your email address and/or phone number (collectively the </span><span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>“Channels”</span><span style={{ fontWeight: "normal" }}>) to us, you expressly consent to receive communications from us.  We may use the Channels to communicate with you, to send information that you have requested or to send information about other products or services developed or provided by us or our business partners, provided that, we will not give your contact information to another party to promote their products or services directly to you without your consent or as set forth in this Privacy Policy. For clarity, the phone number we obtain from you will only be used for account security, account recovery, or as required by law.</span></h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>By providing your phone number to us, you expressly consent to receive phone calls and/or text messages from us. We will not give your phone number to another party to promote their products or services directly to you without your consent or as set forth in this Privacy Policy.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>Any phone calls and/or text messages delivered to your phone or device may cause you to incur extra data, text messaging, or other charges from your wireless carrier. MESSAGE AND DATA RATES MAY APPLY. You are solely responsible for any carrier charges incurred as a result of phone and/or text communications from Megilla.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>Any communication or material you transmit to us by email or otherwise, including any data, questions, comments, suggestions, or the like is, and will be treated as, non-confidential and nonproprietary.  Except to the extent expressly covered by this Privacy Policy, anything you transmit or post may be used by us for any purpose, including but not limited to, reproduction, disclosure, transmission, publication, broadcast and posting. Furthermore, you expressly agree that we are free to use any ideas, concepts, know-how, or techniques contained in any communication you send to us, as well as any data developed using the content of such communication, without compensation and for any purpose whatsoever, including but not limited to, developing, manufacturing and marketing products and services using such information.</h2>
                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>15.	NO RIGHTS OF THIRD PARTIES.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>This Privacy Policy does not create rights enforceable by third parties, nor does it require disclosure of any personal information relating to users of the Service.</h2>
                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>16.	WEBSITE TERMS OF USE.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>Use of this Service is governed by, and subject to, the Terms of Use located here (the <span style={{ fontSize: 15, marginTop: 0, color: 'black', fontStyle: 'italic' }}>“Terms”</span>). This Privacy Policy is incorporated into the Terms.  Your use, or access, of the Service constitutes your agreement to be bound by these provisions.  IF YOU DO NOT AGREE TO THE TERMS AND THIS PRIVACY POLICY YOU MAY NOT ACCESS OR OTHERWISE USE THE SERVICE.</h2>

                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>17.	SERVERS.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>Our servers are maintained in the United States.  By using the Service, you freely and specifically give us your consent to export your personally identifiable information to the United States and to store and use it in the United States as specified in this Privacy Policy.  We and our service providers may transfer your personal information to, or access it in, jurisdictions (including the United States) that may not provide equivalent levels of data protection as your home jurisdiction. We will take steps to ensure that your personal information receives an adequate level of protection in the jurisdictions in which we process it. You understand that data stored in the United States may be subject to lawful requests by the courts or law enforcement authorities in the United States.</h2>
                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>18.	GOVERNING LAW. </h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>This Privacy Policy and our legal obligations hereunder are subject to the laws of California, USA, regardless of your location.  You hereby consent to the exclusive jurisdiction of and venue in the courts located in Los Angeles County, California, USA, in all disputes arising out of or relating to the Service.</h2>
                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>19.	CALIFORNIA PRIVACY RIGHTS.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>California law permits users who are California residents to request and obtain from us once a year, free of charge, a list of the third parties to whom we have disclosed their personal information (if any) for their direct marketing purposes in the prior calendar year, as well as the types of personal information disclosed to those parties. If you are a California resident and would like to request this information, please submit your request in an email to help@megilla.com.</h2>
                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>20.	CONTACT.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18 }}>For questions or concerns relating to privacy, we can be contacted at help@megilla.com. </h2>
                <h2 style={{ fontSize: 18, marginTop: 0, color: 'black', marginTop: 18 }}>21.	EFFECTIVE DATE.</h2>
                <h2 style={{ fontSize: 15, marginTop: 0, fontWeight: "normal", marginTop: 18, marginBottom: 20 }}>This Privacy Policy was last updated on July 11, 2019.</h2>

              </h2>

            </div>

          </div>

        </div>
      </Fragment >
    )
  }
}

export default WithHeader(FAQsPage);
