import { parseInputErrors } from 'utils/helpers';
import * as types from './actionTypes';

import categoriesApi from '../api/categoriesApi';

export const fetchCategoriesAction = () => ({
  type: types.FETCH_CATEGORIES
});

export const fetchCategoriesActionSuccess = categories => ({
  type: types.FETCH_CATEGORIES_SUCCESS,
  categories
});

export const fetchCategoriesActionError = error => ({
  type: types.FETCH_CATEGORIES_ERROR,
  error
});

export const fetchCategoryAction = () => ({
  type: types.FETCH_CATEGORY,
});

export const fetchCategorySuccess = (category, page) => ({
  type: types.FETCH_CATEGORY_SUCCESS,
  category,
  page
});

export const fetchCategoryError = error => ({
  type: types.FETCH_CATEGORY_ERROR,
  error
});

export const selectLevelCategory = (categoryId, categoryName, level, hasSubcategories) => ({
  type: types[`SELECT_${level}_CATEGORY`],
  categoryId,
  categoryName,
  level,
  hasSubcategories
});

export const removeSelectedCategories = () => ({
  type: types.REMOVE_SELECTED_CATEGORIES
});

export const fetchCategories = () =>
  (dispatch) => {
    dispatch(fetchCategoriesAction());
    categoriesApi.fetchCategories().then(({ categories }) => {
      dispatch(fetchCategoriesActionSuccess(categories));
    }).catch((err) => {
      dispatch(fetchCategoriesActionError(parseInputErrors(err)));
    });
  };

export const fetchCategory = (categoryId, page) =>
  (dispatch) => {
    dispatch(fetchCategoryAction());
    categoriesApi.fetchCategory(categoryId, page).then(({ category }) => {
      dispatch(fetchCategorySuccess(category));
    }).catch((err) => {
      dispatch(fetchCategoryError(parseInputErrors(err)));
    });
  };
